//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.template-migration-progress {
  margin-right: -25px;

  .updating-checklists {
    font-size: 16px;
  }

  .this-update {
    color: $ps-gray-400;
  }

  .migration-progress-bar-container {
    position: relative;
  }

  .migration-progress-bar {
    height: 4px;
    width: 100%;
  }

  .progress-bar-migration {
    background-color: $ps-brand-500;
  }

  .migration-progress-info {
    color: $ps-gray-500;
    margin: -15px 0 15px;
  }
}
