//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.unavailable {
  padding-bottom: ps-size(6);
  padding-top: ps-size(3);
  text-align: center;

  img {
    margin-bottom: 15px;
  }

  .message {
    font-size: 16px;
  }

  .message-btn-container {
    margin: 40px;
  }
}

// Medium devices (desktops, 992px and up)
@media (min-width: $screen-md-min) {
  .unavailable {
    padding-top: ps-size(6);
  }
}
