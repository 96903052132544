//
// Navs
// --------------------------------------------------

// Base class
// --------------

.nav {
  font-weight: 500;
}

// Tabs
// --------------
.nav.nav-tabs {
  border-bottom: 2px solid $ps-gray-100;
  margin-bottom: 28px;
  margin-top: 20px;

  li.active > a,
  .nav-tabs > li.active > a:focus,
  .nav-tabs > li.active > a:hover {
    border: 0;
    color: $ps-brand-500 !important;
  }

  > li > a {
    border: 0;
    color: $ps-gray-500;
    margin-right: 0;
  }

  > li > a:hover {
    background: transparent;
  }

  > li > a::after {
    background: $ps-brand-500;
    bottom: -1px;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    transform: scale(0);
    transition: all 250ms ease 0s;
    width: 100%;
  }

  > li:not(.active):not(:hover) > a::before {
    background: $ps-gray-100;
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.nav-tabs-justified > .active > a,
.nav-tabs-justified > .active > a:hover,
.nav-tabs-justified > .active > a:focus {
  background-color: transparent;
  border: 0;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  background: transparent;
  border: 0;
  color: $ps-brand-500;
}

.nav-tabs > li.active > a::after,
.nav-tabs > li:hover > a::after {
  transform: scale(1);
}
