//
// Grid system
// --------------------------------------------------

.container {
  padding-left: ps-size(2);
  padding-right: ps-size(2);
}

.container-fluid {
  padding-left: ps-size(2);
  padding-right: ps-size(2);
}

// A smaller row which is tight up against it's 'primary' predecessor
.secondary-row {
  margin-top: -15px !important;

  [class*='col-'] {
    padding-top: 6px !important;
  }
}

// Responsive table header using the grid system.
.row-header {
  @extend .uppercase-capitalized;
}
