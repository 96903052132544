//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.redeem-ctrl {
  margin: 100px 0 60px;

  .redeem-icon {
    border: 1px $ps-gray-400 solid;
    border-radius: 50%;
    height: 100px;
    margin: 20px auto;
    text-align: center;
    width: 100px;

    i {
      line-height: 100px;
    }
  }

  .redeem-title {
    margin-bottom: 30px;
    text-align: center;

    h2 {
      color: $ps-gray-500;
    }

    .change-link {
      font-size: 10px;
    }
  }

  .redeem-btn {
    text-align: center;
    width: 100%;
  }
}
