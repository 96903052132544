//
// Dropdown menus
// --------------------------------------------------

.dropdown {
  &.fixed-width-menu {
    .full-width-text {
      display: block;
    }

    .dropdown-menu {
      left: auto;
      min-width: 325px !important;

      > li > a {
        padding: 8px 12px;
        white-space: normal;
      }
    }
  }
}

.permissions-selector {
  padding-right: 24px;
  text-align: right;
}

.dropdown-menu {
  z-index: 1052;
}

.dropdown-toggle {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &.no-caret {
    justify-content: flex-start;
  }

  .caret {
    border: 0;
    height: 24px;
    margin-left: ps-size(1);
    width: auto;

    &::after {
      @include fa-unicode-icon($fa-var-chevron-down);
      height: 100%;
      padding-top: 2px;
    }

    &.caret-sm {
      &::after {
        @include fa-unicode-icon($fa-var-chevron-down, 12px);
      }
    }
  }
}
