//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.task-permission-pop-up {
  .pop-up-info {
    text-align: center;
  }

  hr.delimiter {
    border-color: $ps-gray-50;
    margin: 8px 0;
  }

  .list-group {
    margin: 5px 0;
  }

  .predefined-permission-item {
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    padding: 5px 0;

    .image {
      padding-right: 5px;
    }

    .details {
      width: 200px;

      .username {
        font-size: 14px;
        line-height: 16px;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .email {
        font-size: 12px;
        line-height: 16px;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .visible-to {
    font-size: 12px;
    font-weight: bold;
    margin: 8px 0 0;
  }

  .visible-to-list-item {
    list-style: none;
  }

  .predefined-group-item {
    position: relative;
  }

  .ps-switch-container {
    height: 26px;
    position: absolute;
    right: 5px;
    top: 8px;
  }

  .list-assigned-permissions {
    max-height: 200px;
    overflow-y: auto;
  }
}
