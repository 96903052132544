//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../../styles/boulevard-theme/utilities/size';
@import '../../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.approval-subject-task-widget-multi-select-renderer {
  .items {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;

    .item {
      margin-bottom: 0;
      padding: 0 10px 0 20px;
      position: relative;

      &.completed .item-name-static {
        color: lighten($panel-default-text, 50%);
        font-style: italic;
        text-decoration: line-through;
      }
    }
  }

  .item-number-container {
    align-items: center;
    background: transparent;
    border-right: transparent;
    bottom: 0;
    color: lighten($panel-default-text, 50%);
    display: flex;
    font-size: 11px;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 5px;
  }

  .item-checkbox-container {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 5px;
    position: absolute;
    top: 0;
    width: 20px;

    &.disabled {
      opacity: 0.5;
    }
  }

  .item-name {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    min-height: 34px;
    outline: none;
    padding: 7px 5px;

    &:focus {
      @include form-control-focus();
      background-color: $ps-white;
    }

    &:hover {
      background-color: $ps-white;
    }
  }

  .item-name-static {
    display: block;
    outline: none;
    overflow: hidden;
    padding: 3px 10px;

    &:empty::before {
      color: $ps-gray-400;
      content: '(' attr(data-placeholder) ')';
    }
  }
}
