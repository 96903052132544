//
// Alerts
// --------------------------------------------------

.ps-notifier .notice .alert,
.alert {
  box-shadow: 0 0 8px 0 $ps-gray-200;
  font-weight: 500;
  padding-right: ps-size(5);
  position: relative;
  text-shadow: none !important;

  button.close {
    opacity: 1;
    position: absolute;
    right: ps-size(2);
    text-shadow: unset;
    top: calc(((100% - 26px) / 2));
  }

  a {
    color: $ps-brand-500;
    padding-right: ps-size(1);
    text-decoration: none;

    &:hover {
      color: $ps-blue-hover;
      text-decoration: underline;
    }
  }
}

.alert-success {
  @include ps-alert-variant($ps-green-100, $ps-gray-700, $ps-green-disabled, $ps-green-hover);
}

.alert-info {
  @include ps-alert-variant($ps-blue-100, $ps-gray-700, $ps-blue-disabled, $ps-blue-hover);
}

.alert-warning {
  @include ps-alert-variant($ps-yellow-100, $ps-gray-700, $ps-yellow-disabled, $ps-yellow-hover);
}

.alert-danger {
  @include ps-alert-variant($ps-red-100, $ps-gray-700, $ps-red-disabled, $ps-red-hover);
}

.alert-default {
  @include ps-alert-variant($ps-gray-50, $ps-gray-700, $ps-gray-300, $ps-gray-400);
}

.alert-dark {
  @include ps-alert-variant($ps-gray-500, $ps-white, $ps-gray-400, $ps-gray-300);
}
