//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

//This is the bar below the task heading on templates and checklists.

.task-bar-container {
  margin: 0 auto 30px;
  max-width: 700px;
  padding: 0 30px;

  .task-bar-inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 5px 0;

    .task-bar-cell {
      margin: 0 0 5px;

      &:first-child {
        margin-right: 5px;
      }
    }

    button:focus {
      outline: 0;
    }
  }
}
