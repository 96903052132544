//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.task-disabled {
  &:not(.selected) {
    .step-assignments {
      background-color: $ps-gray-50;

      .avatar {
        opacity: 0.6;
      }
    }

    .step-flares {
      background-color: $ps-gray-50;
    }
  }
}

.not-completable {
  .step-checkbox-container {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.task-list-item {
  align-items: center;
  display: flex;
  width: 100%;

  .step-checkbox-container {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: ps-size(2);
    position: absolute;
    top: 0;
    width: 20px;

    &.inactive {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .viewable-step-name--container {
    color: $ps-gray-600;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .step-checkbox {
    align-items: center;
    background-color: $ps-white;
    border: 1px solid $ps-gray-400;
    border-radius: 4px;
    display: flex;
    height: 20px;
    justify-content: center;
    padding: 0 2px;
    width: 20px;
  }

  .step-checkbox-checkmark {
    color: $ps-gray-500;
    display: none;
    font-size: 14px;
  }

  .step-progressbar {
    background: none;
    border-radius: 0;
    bottom: 0;
    box-shadow: none;
    height: 2px;
    left: 0;
    margin-bottom: 0;
    margin-left: -1px;
    margin-right: -1px;
    position: absolute;
    right: 0;

    > .progress-bar {
      background-color: $ps-brand-500;
      border-radius: 0;
      box-shadow: none;
    }
  }

  .step-flares {
    align-items: center;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;

    .step-invalid-fields {
      min-width: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .approval-subject:not(.not-completable):not(.required) & {
    &.selected .step-flares {
      background-color: $ps-brand-200;
    }
  }
}

.step {
  &.heading,
  &.approval {
    .step-checkbox-container {
      display: none;
    }
  }

  &.completed .step-checkbox-checkmark {
    display: inline;
  }

  &.completed:not(.heading) {
    .viewable-step-name--container,
    .step-name {
      color: $ps-gray-400;
      font-style: italic;
      text-decoration: line-through;
    }
    .step-due-date {
      background-color: $ps-gray-50;
      color: $ps-gray-400;
      text-decoration: line-through;
    }
  }

  &.selected {
    .step-name {
      color: $ps-white;
    }
  }
}

.task-list-item--wrapper {
  width: 100%;
}
