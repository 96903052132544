//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.task-list-item {
  .step-number-container {
    align-items: center;
    background-color: transparent;
    border-right: 0;
    bottom: 0;
    color: lighten($panel-default-text, 50%);
    display: flex;
    font-size: $ps-font-size-xs;
    font-weight: 400;
    justify-content: center;
    left: -31px;
    position: absolute;
    top: 0;
    width: 30px;
  }

  .step-name {
    border: 1px solid transparent;
    display: inline;
    outline: none;
    overflow: hidden;
    white-space: nowrap;
  }

  .step-name-editable {
    background-color: $ps-white;
    border-color: transparent;
    box-shadow: none;
    height: 36px;
    overflow: hidden;
    resize: none;
    white-space: nowrap;

    .approval-subject & {
      background-color: transparent;
    }
  }

  .step-name-editable:not(:focus) {
    cursor: pointer;
  }

  .step-grip {
    bottom: 2px;
    color: $ps-gray-400;
    display: none;
    left: 2px;
    position: absolute;
    right: 2px;
    top: 2px;
  }

  .step-flare {
    float: right;
    font-size: $ps-font-size-xs;
    font-weight: 500;
    height: ps-size(4);
    line-height: ps-size(4);
    padding: 0;
    text-align: center;
    white-space: nowrap;
  }

  .step-flares {
    align-items: center;
    display: flex;
    height: 100%;
    padding-left: ps-size(1);
    padding-right: ps-size(1);
    > * + * {
      margin-left: ps-size(0.5);
    }
    &.step-flares--universal-trigger-ux > * + * {
      margin-left: ps-size(0.5);
    }

    .step-automations {
      height: auto;
    }

    .step-invalid-fields {
      @extend .step-flare;
      color: darken($ps-red, 10%);
    }

    .step-comments-count {
      @extend .step-flare;
      color: $ps-gray-400;
    }

    .task-template-menu__button {
      height: var(--ps-sizes-8);
      justify-content: center;
      width: var(--ps-sizes-8);
    }
  }

  .step-show-hide {
    background-color: inherit;
    border-radius: 4px;
    bottom: 0;
    color: $ps-gray-300;
    font-size: 10px;
    line-height: 46px;
    position: absolute;
    right: 0;
    text-align: center;
    text-decoration: none;
    top: 0;
    width: 48px;

    .hide-icon {
      display: none;
    }

    &:hover {
      color: $link-hover-color;
    }
  }

  .task-row {
    align-items: center;
    bottom: 0;
    display: flex;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    .step-name--container {
      flex: 1;
      min-width: 0;
      width: 100%;

      span.step-name {
        display: inline;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &--view-mode {
      left: ps-size(2);

      .step-name--container {
        color: $ps-gray-600;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        span.step-name {
          display: inline;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    &--edit-mode {
      .step-name--container {
        display: flex;
        padding-left: ps-size(1);
        padding-right: ps-size(1);
      }

      .step-flares {
        margin-left: ps-size(-1);
      }

      textarea.step-name {
        height: $ps-task-item-height - ps-size(2);
        line-height: 2rem;
        padding: 8px 9px;
        transition: all 0.2s ease-out;
      }
    }
  }

  .viewable-step-name.step-name {
    color: $ps-gray-700;
  }
}

.template--edit .step.list-group-item.step {
  &.selected {
    background-color: $ps-brand-500;

    &:not(.has-error):not(.required) {
      background-color: $ps-brand-500;
    }

    ps-icon svg {
      background-color: var(--ps-colors-white);
      color: var(--ps-colors-gray-500);
    }

    .step-flares {
      background-color: $ps-brand-500;
      .task-automation-indicator-icon:hover {
        color: $ps-white;
        opacity: 1;
      }
    }
  }
}

.step.list-group-item.step {
  align-items: center;
  display: flex;
  height: $ps-task-item-height;

  .icon-process-stop {
    bottom: 1px;
    position: absolute;
    right: initial;
  }

  &:not(:last-child) {
    border-bottom: 1px solid transparent;
  }

  &.heading {
    border-bottom-width: 1px;

    .step-number-container {
      background-color: transparent;
      border-color: transparent;
    }
  }

  &.approval-rule-subject {
    .step-number-container {
      background-color: transparent;
      border-color: transparent;
    }

    .step-name-editable {
      background-color: $ps-gray-100;
    }
  }

  &.required,
  &.required .step-flares,
  &.required .step-name-editable {
    background-color: lighten($brand-danger, 25%);
  }

  &.selected {
    background-color: $ps-white;
    border-bottom: 1px solid $ps-brand-500;
    border-color: $ps-brand-500;

    + .step.list-group-item.step {
      border-top-color: transparent;
    }

    .step-flares {
      background-color: $ps-white;
      margin-right: 0;
    }

    &.required,
    &.required .step-flares,
    &.has-error,
    &.has-error .step-flares {
      background-color: $brand-danger;
      border-color: $brand-danger;
    }

    &.has-error {
      .viewable-step-name.step-name {
        color: $ps-white;
        opacity: 0.9;
      }
    }

    &:not(.has-error) {
      .viewable-step-name.step-name {
        color: $ps-brand-600;
      }
    }

    .step-number-container {
      background-color: transparent;
      border-color: transparent;
      color: $ps-brand-500;

      .icon-process-stop {
        color: $ps-brand-500;
      }
    }

    .step-name-editable {
      display: block;
    }

    .step-invalid-fields,
    .step-assignments,
    .step-show-hide {
      background-color: inherit;
      color: $ps-white;
      .fa-chevron-left {
        padding-right: 2px;
      }
    }

    .step-grip {
      transform: scaleY(-1);
    }
  }
}

.step:first-child .step-number-container {
  border-radius: 4px 0 0;
}

.step:last-child .step-number-container {
  border-radius: 0 0 0 4px;
}

.editor {
  &.editable .step:hover .step-number-container {
    cursor: grab;

    &::before {
      display: none;
    }
  }

  // Medium devices (desktops, 992px and up)
  @media (min-width: $screen-md-min) {
    &.editable .list-group:not(.sorting-disabled) .step:hover .step-number,
    &.editable .list-group:not(.sorting-disabled) .step:hover .icon-process-approval {
      display: none;
    }

    &.editable .list-group:not(.sorting-disabled) .step:hover .step-grip,
    &.editable .ui-sortable-helper .step-grip {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .step-show-hide {
      width: 18px;
    }
  }
}

.list-group-item.step {
  .step-number-container {
    color: $ps-gray-400;

    .step-number {
      z-index: 1;
    }

    &::before {
      background: $ps-brand-500;
      border-radius: 100%;
      content: '';
      display: block;
      height: 20px;
      opacity: 0;
      position: absolute;
      transform: scale(0);
      transition: 400ms ease transform;
      width: 20px;
      will-change: transform opacity;
      z-index: 0;
    }
  }

  &.selected {
    &:not(.has-error):not(.required) {
      background-color: $ps-white;
    }

    .task-list-item {
      .step-number-container {
        color: $ps-white;

        .icon-process-approval {
          color: inherit;
          z-index: 2;

          svg {
            color: inherit;
          }
        }

        &::before {
          opacity: 1;
          transform: scale(1);
        }
      }

      .icon-process-stop {
        color: $ps-brand-500;
      }

      &:before {
        background-color: $ps-brand-500;
      }

      .task-row--view-mode {
        > div:first-child {
          color: var(--ps-colors-brand-600);
        }
      }

      .task-row--edit-mode {
        span.step-name {
          color: $ps-white;
        }
        textarea.step-name {
          color: inherit;
          width: 100%;
        }
      }
    }
  }

  &.has-error.selected,
  &.required,
  &.required.selected {
    .task-list-item {
      .step-number-container {
        color: $ps-white;

        &:before {
          background-color: $brand-danger;
        }
      }

      .icon-process-stop {
        color: $brand-danger;
      }
    }
  }
}

.step {
  .task-automation-indicator-icon {
    color: $ps-gray-400;
    font-size: ps-size(2);
    opacity: 0;
  }
  &:hover {
    .task-automation-indicator-icon {
      opacity: 1;
    }
  }
  &.selected {
    .task-row--edit-mode:hover {
      .task-automation-indicator-icon {
        color: $ps-white;
      }
    }
  }
}

.step .task-template-menu {
  align-items: center;
  font-size: $ps-font-size-md;
  // Instead of using 'display: none', we are setting the position to 'absolute' and
  // hiding the menu button using the 'visibility: hidden;'. This will allow to keep
  // the React Menu on the screen when the user is not hovering the task anymore.
  position: absolute;
  visibility: hidden;

  @media (max-width: $screen-md-min) {
    position: relative;
    visibility: visible;
  }

  a,
  a:active {
    color: $ps-gray-500;
  }
}

.step:hover .task-template-menu,
.step.selected .task-template-menu,
.step.ai-generating-widgets .task-template-menu,
.task-list-item--menu-open .task-template-menu {
  position: relative;
  visibility: visible;
}

.step.selected .task-template-menu {
  a,
  a:active,
  a .far {
    color: var(--ps-colors-white);
  }

  a:focus,
  a:hover {
    background-color: transparent;
    outline: 1px solid var(--ps-colors-white);
  }
}

.task-template-menu__list {
  padding: var(--ps-sizes-2) 0;
  // overwrites bootstrap's positioning on mobile
  @media (max-width: $screen-md-min) {
    left: unset !important;
    right: var(--ps-sizes-3) !important;
  }
}

.task-template-menu__list__heading {
  padding-bottom: var(--ps-sizes-1);
  padding-left: var(--ps-sizes-4);
  padding-right: var(--ps-sizes-4);
  padding-top: var(--ps-sizes-2);
}

.task-template-menu__list__drag-and-drop {
  align-items: center;
  color: $ps-gray-400;
  display: flex;
  font-style: italic;
  padding-bottom: var(--ps-sizes-3);
  padding-left: var(--ps-sizes-4);
  padding-right: var(--ps-sizes-4);
  padding-top: var(--ps-sizes-3);

  i {
    color: $ps-gray-300;
  }

  & > span {
    margin-left: var(--ps-sizes-2);
  }
}

.dropdown-menu li.task-template-menu__list-item {
  & > a {
    align-items: center;
    color: var(--ps-colors-gray-600);
    display: flex;
    padding-bottom: var(--ps-sizes-3);
    padding-left: var(--ps-sizes-4);
    padding-right: var(--ps-sizes-4);
    padding-top: var(--ps-sizes-3);
  }
  &:hover > a {
    color: $ps-white;
  }

  &--danger > a {
    color: $ps-red-500;
  }

  &--warning > a {
    color: $ps-yellow-500;
  }

  &--active > a {
    color: $ps-purple-500;
  }

  & > a *[class*='fa-'] {
    width: var(--ps-sizes-4);
  }

  & > a *[class*='fa-'] + span,
  & > a *[class*='far'] + span {
    margin-left: var(--ps-sizes-2);
  }
}
