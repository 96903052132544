//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.typeahead-match-avatar {
  align-items: center;
  border-radius: ps-size(0.5);
  display: flex !important;
  padding: 5px 10px !important;

  .avatar img,
  .avatar .initials {
    height: ps-size(4);
    width: ps-size(4);
  }

  .match-label {
    display: inline-block;
    padding: 0 10px;
  }
}

.uib-typeahead-match {
  padding-left: $ps-base-unit;
  padding-right: $ps-base-unit;
}
