// Size Helper Function
//
// Calculate sizes based on the grid system.
// ps-size(1) = 8px
// ps-size(1, 1.5) = 8px, 12px
//

$ps-base-unit: 8px;

@function is-whole-or-half-number($number) {
  @if (floor($number) == $number) {
    @return true;
  } @else if (floor($number * 2) == $number * 2) {
    @return true;
  } @else {
    @return false;
  }
}

@function calc-size($size) {
  @if (is-whole-or-half-number($size)) {
    @return $ps-base-unit * $size;
  } @else {
    @error '`#{$size}`is not a valid size. Whole and half units must be used eg. 1, 3.5, 10 etc';
  }
}

@function ps-calc-size-as-list($sizes) {
  $new-list: ();

  @each $size in $sizes {
    $new-list: append($new-list, calc-size($size));
  }
  @return $new-list;
}

@function ps-size($sizes...) {
  @if (length($sizes) > 1) {
    @return ps-calc-size-as-list($sizes);
  } @else {
    @return calc-size(nth($sizes, 1));
  }
}

.flex-centered {
  align-items: center;
  display: flex;
  justify-content: center;
}
