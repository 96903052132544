@mixin button-primary($primary, $hover, $disabled) {
  background-color: $primary;
  border: 2px solid $primary;
  color: $ps-white;

  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active {
    background-color: $disabled;
    border-color: $disabled;
  }

  &:hover {
    background-color: $hover;
    border-color: $hover;
  }

  &:focus {
    background-color: $hover;
    box-shadow: 0 0 2px 2px $disabled;
    outline: none;
  }
}

@mixin button-outline($primary, $disabled) {
  background-color: $ps-white;
  border: 2px solid $primary;
  color: $primary;

  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active {
    background-color: $ps-white;
    border: 2px solid $disabled;
    color: $disabled;
  }

  &:hover {
    background-color: $primary;
    color: $ps-white;
  }

  &:focus {
    box-shadow: 0 0 2px 2px $disabled;
    outline: none;
  }
}

@mixin button-transparent($primary, $disabled, $hover-color) {
  background-color: transparent;
  border: 0;
  color: $primary;

  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active {
    background-color: $ps-white;
    border: 0;
    color: $disabled;
  }

  &:hover {
    background-color: $primary;
    color: $hover-color;
  }

  &:focus {
    box-shadow: 0 0 2px 2px $disabled;
    outline: none;
  }
}
