//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.activity {
  .undelete {
    color: $ps-gray-400;
    font-size: 13px;
    text-decoration: underline;
  }

  @media (min-width: $screen-sm-min) {
    .undeletable {
      padding-right: 80px;
    }

    .undelete-container {
      align-items: center;
      bottom: 0;
      display: flex;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 80px;
    }
  }
}
