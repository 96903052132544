//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.template-print,
.checklist-print,
.checklist-print-legacy {
  font-size: 12px;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 30px;

  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }
  h4 {
    font-size: 1.12em;
  }
  h5 {
    font-size: 0.83em;
  }
  h6 {
    font-size: 0.75em;
  }

  input::placeholder,
  textarea::placeholder {
    color: transparent;
  }

  img {
    // Avoid 1 page images
    max-height: 450px;
    max-width: 100%;
  }

  p {
    margin-bottom: 10px;
  }

  .assignment::after {
    content: none;
  }

  .widget {
    margin-bottom: 20px;
  }

  .widget-image figure {
    width: auto;
  }

  .widget-video {
    // Limit video width since there is no need to display
    margin: 0 auto;
    max-width: 400px;
  }

  .widget-form-field {
    page-break-inside: avoid;
  }

  .field-multi-select .item-checkbox-container.disabled {
    opacity: 1;
  }

  .field-multi-select .item-checkbox {
    font-size: 14px;
  }

  .field-select .items,
  .field-multi-choice .items {
    max-height: none;
  }

  .empty-merge-tag {
    border: 1px $ps-gray-400 solid;

    > .empty-merge-tag {
      border: 0;
    }
  }

  // Summary

  .summary {
    page-break-after: always;

    .list-group-item {
      min-height: 40px;
      padding: 5px 15px;
    }

    .summary-task {
      page-break-inside: avoid;
    }
  }

  .summary-task-number-container {
    align-items: center;
    background: transparent;
    border-right: 1px solid $list-group-border;
    bottom: 0;
    display: flex;
    font-size: 11px;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 30px;
  }

  .summary-task-checkbox-container {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 40px;
    position: absolute;
    top: 0;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .summary-task-checkbox {
    align-items: center;
    background-color: $ps-white;
    border: 1px solid $ps-checkbox-border-color;
    border-radius: 4px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    font-size: 14px;
    height: 20px;
    justify-content: center;
    width: 20px;
  }

  .summary-task-heading {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    justify-content: space-between;
    margin-left: 28px;
    min-height: 28px;
  }

  .summary-task-name {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 60px;
    min-height: 28px;
  }

  .section-header {
    border-bottom: 1px $list-group-border solid;
    margin: 35px 0 15px;
    padding-bottom: 10px;
    page-break-inside: avoid;
  }

  // Tasks
  .task {
    .task-header {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      page-break-inside: avoid;
    }

    .task-number-container {
      background: transparent;
      font-size: 11px;
      margin-right: 10px;
    }

    .task-checkbox-container {
      margin-right: 10px;
      width: 20px;
    }

    .task-checkbox {
      align-items: center;
      background-color: $ps-white;
      border: 1px solid $ps-gray-50;
      border-bottom-color: $ps-gray-300;
      border-radius: 4px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
      display: flex;
      font-size: 14px;
      height: 20px;
      justify-content: center;
      width: 20px;
    }

    .task-approval-info-wrapper {
      padding-bottom: 20px;
    }
  }
}
