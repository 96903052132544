//
// Progress bars
// --------------------------------------------------

.progress {
  height: 4px !important;
  margin-bottom: 0;

  .progress-bar {
    border-radius: 2px;
    box-shadow: none;
  }

  .progress-bar[aria-valuenow='0'],
  .progress-bar[aria-valuenow='1'],
  .progress-bar[aria-valuenow='2'] {
    min-width: 0;
  }

  .progress-bar-primary {
    background-color: $ps-brand-500;
  }
}
