//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.task-assignments {
  display: inline-block;

  .assignments {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .task-assignments__avatar-list {
    display: flex;
    list-style-type: none;
    padding-left: 0;
  }

  .task-assignments__avatar-item:not(:first-child) {
    margin-left: -6px;
  }
}
