#_elev_io {
  display: none;
}

// Small devices (tablets, 768px and up)
@media (min-width: $screen-sm-min) {
  // Elev.io

  #_elev_io {
    display: block;
  }

  #_elev_io ._19znh {
    transition: right 0.5s;
  }

  // Move the launcher when the Chakra drawer is open
  ._elev_io__chakra-drawer--open ._1g6cj {
    right: calc(100% - 80px) !important;
  }
}

@media print {
  ._elevio_launcher {
    display: none;
  }
}
