//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.select-assignees {
  overflow-y: visible;
}

.select-assignees-box {
  .selected-indicator {
    float: right;
    margin-right: 10px;
    margin-top: 5px;
  }

  ul {
    list-style: none;
    margin: 8px -15px 0;
    max-height: 230px;
    overflow-y: scroll;
    padding-left: 0;
  }

  li,
  .select-assignees-box__assignee {
    > a {
      color: inherit;
      display: block;
      padding: 5px 15px;
      text-decoration: none;

      &:hover {
        background-color: $ps-gray-50;
      }
    }
  }

  .delimiter {
    border-bottom: 1px solid $ps-gray-50;
    margin: 3px 0;
  }

  .image {
    float: left;
  }

  .details {
    margin: 0 42px;

    .username {
      font-size: 14px;
      line-height: 16px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .group-name {
      font-size: 14px;
      line-height: 16px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize;
      white-space: nowrap;
    }

    .email {
      font-size: 12px;
      line-height: 16px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .select-assignees-box__assignee--inactive {
    > a {
      opacity: 0.6;
    }
  }
}
