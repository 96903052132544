//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.due-drop-down-selector {
  .dropdown-menu {
    left: -8rem;
    width: 293px;

    ul li a {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .dropdown-button {
    margin-left: 0;
    max-width: 100%;
    text-align: left;
    width: 100%;
  }

  .dropdown-button-label {
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dropdown-inner-search {
    margin: 0 10px;
    position: absolute;
    top: 10px;
    width: calc(100% - 20px);
  }

  .dropdown-scrollable {
    position: relative;

    .dropdown-scrollable-inner {
      height: auto;
      list-style: none;
      margin-top: 50px;
      max-height: 300px;
      min-width: 250px;
      overflow-x: hidden;
      padding: 0 10px;

      //add back bootstrap dropdown styles
      section > li > a {
        clear: both;
        color: $ps-gray-600;
        display: block;
        line-height: 1.428571429;
        padding: ps-size(0.5, 1);
        white-space: nowrap;
      }

      section > li > a:hover,
      section > li > a:focus {
        background-color: $ps-gray-50;
        color: $ps-gray-700;
        text-decoration: none;
      }

      > .active > a,
      > .active > a:hover,
      > .active > a:focus {
        background-color: $ps-brand-500;
        color: $ps-white;
        outline: 0;
        text-decoration: none;
      }
    }
  }

  .list-title {
    clear: both;
    color: $ps-gray-400;
    display: block;
    font-size: $ps-font-size-xs;
    font-weight: 600;
    line-height: 1.428571429;
    padding: 3px ps-size(1);
    white-space: nowrap;
  }

  .selected-value {
    align-items: center;
    display: flex;
    flex-grow: 1;
  }

  .clear-button {
    background-color: transparent;
    border: 0;
    color: $ps-gray-400;
    position: absolute;
    right: ps-size(0.5);
    top: 7px;
  }
}
