// Margin Helper Classes
//
// Generates helper classes for margins based on the grid system
// h = half
//
// ml-h = margin-left: 4px
// mt-1 = margin-top: 8px
// mr-2h = margin-right: 20px

$sizes: (1, 2, 3, 4, 5);
$sides: (top, bottom, left, right);

@each $size in $sizes {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$size} {
      margin-#{$side}: #{ps-size($size)};
    }
    .m#{str-slice($side, 0, 1)}-#{$size}h {
      margin-#{$side}: #{ps-size($size + 0.5)};
    }
  }
}

@each $side in $sides {
  .m#{str-slice($side, 0, 1)}-h {
    margin-#{$side}: #{ps-size(0.5)};
  }
}
