//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../../styles/boulevard-theme/utilities/size';
@import '../../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.approval-subject-task-widget-text-renderer {
  a {
    color: $ps-blue;

    &:hover {
      color: $ps-blue-600;
    }
  }

  h1 {
    font-size: $ps-font-size-xxl;
  }

  h2 {
    font-size: $ps-font-size-xl;
  }

  h3 {
    font-size: $ps-font-size-lg;
  }

  li {
    margin-bottom: 6px;
  }

  p {
    line-height: ps-size(3);
  }

  h1,
  h2,
  h3 {
    margin: 0 0 20px;
  }

  p,
  div[class^='style-'],
  ol,
  ul,
  pre {
    margin-bottom: 20px;
  }

  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
  }

  hr {
    border-top: 1px solid $ps-gray-100;
  }

  // Make sure empty paragraphs show
  p:empty::before {
    content: '\00a0';
  }

  // Make sure empty blocks don't look weird
  pre {
    background-color: $ps-gray-100;
    border-color: $ps-gray-200;
    padding: ps-size(2) - 2;

    &:empty::before {
      content: ' ';
    }

    code {
      background-color: inherit;
      color: inherit;
      padding: 4px 0;
    }
  }
}
