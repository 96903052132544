//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../../styles/boulevard-theme/utilities/size';
@import '../../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.templateGalleryCategoriesItem {
  align-items: center;
  color: $ps-gray-700;

  cursor: pointer;
  display: flex;
  height: ps-size(5);
  line-height: ps-size(2);
  padding: ps-size(1, 2);

  user-select: none;
  width: 100%;

  @media (min-width: $screen-lg) {
    &:hover {
      color: $ps-brand-500;
    }
  }
}

.selected {
  background-color: $ps-brand-100;
  border-radius: ps-size(3);
  color: $ps-brand-500;
  font-weight: $ps-font-style-medium;
}
