//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

// Loader

.loader {
  .progress {
    border-radius: 0;
    height: 5px;
    position: absolute;
    top: $navbar-height;
    width: 100%;
    z-index: 1031;
  }
  .progress-bar {
    box-shadow: none;
  }

  .logo-spinner {
    margin: 100px 20px;
  }

  .message {
    font-size: 20px;
    margin: 100px 20px;
    text-align: center;
  }

  &.auth-callback {
    background-color: $ps-white;
    inset: 0;
    padding-top: $navbar-height;
    position: fixed;
    z-index: 1050;
  }
}
