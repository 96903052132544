//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.future-checklist {
  .container {
    max-width: 800px;
  }

  .header {
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .description {
    color: $ps-gray-500;
  }

  .checkbox {
    padding-bottom: 7px;

    label {
      margin-right: 15px;
    }
  }

  .select-wrapper {
    display: inline-block;
  }

  select {
    min-width: ps-size(18);
  }

  .future-checklist-number-input {
    display: inline-block;
    margin-right: 5px;
    width: 60px;
  }

  .assignments {
    display: flex;
    margin-bottom: 10px;
    padding: 0;
  }

  .assignment {
    display: inline-block;

    a {
      display: inline-block;
      padding: 1px;
    }
  }

  .start-date-text {
    display: none;
    margin-right: 10px;
  }

  .start-date.set .start-date-text {
    display: inline-block;
  }

  .start-date.set .start-date-btn-text {
    display: none;
  }

  .due-period label span {
    margin-right: 10px;
  }

  .button-bar button {
    margin-bottom: 20px;
    width: 100%;
  }
}
