@mixin badge-colors($color, $bg) {
  background-color: $bg;
  color: $color;
}

@mixin badge-dimensions($padding, $font-size, $line-height) {
  font-size: $font-size;
  line-height: $line-height;
  padding: $padding;

  > :not(:first-child) {
    padding-left: 4px;
  }
}
