// Boulevard Variables
//
//

//== Grid System
//
//##

$ps-base-unit: 8px;

//== Colors
//
//##

// These colors should match the chakra theme while we strangle out the angular and scss react code

// console.log(
//   Object.entries(theme.colors)
//     .filter(([color]) => 'gray|blue|green|red|purple|teal|indigo|orange|yellow'.includes(color))
//     .map(([color, shadesDict]) => {
//       return Object.entries(shadesDict)
//         .map(([shade, hex]) => `$ps-${color}-${shade}: ${hex};`)
//         .join('\n');
//     })
//     .join('\n\n'),
// );

$ps-gray-50: #f9fafb;
$ps-gray-100: #eef0f2;
$ps-gray-200: #dee4e8;
$ps-gray-300: #c2cdd6;
$ps-gray-400: #8e9eac;
$ps-gray-500: #5f7482;
$ps-gray-600: #434f5c;
$ps-gray-700: #1e2b36;
$ps-gray-800: #151d23;
$ps-gray-900: #171923;

$ps-red-50: #fff5f5;
$ps-red-100: #fbeff1;
$ps-red-200: #f8d8dd;
$ps-red-300: #f1b1bc;
$ps-red-400: #f8798d;
$ps-red-500: #e83857;
$ps-red-600: #a13242;
$ps-red-700: #9b2c2c;
$ps-red-800: #822727;
$ps-red-900: #63171b;

$ps-orange-50: #fffaf0;
$ps-orange-100: #fff4e8;
$ps-orange-200: #ffe4c6;
$ps-orange-300: #ffc98c;
$ps-orange-400: #ed8936;
$ps-orange-500: #f58200;
$ps-orange-600: #bf6e13;
$ps-orange-700: #9c4221;
$ps-orange-800: #7b341e;
$ps-orange-900: #652b19;

$ps-yellow-50: #fffff0;
$ps-yellow-100: #fff8e5;
$ps-yellow-200: #ffefbf;
$ps-yellow-300: #ffde7f;
$ps-yellow-400: #ffcb33;
$ps-yellow-500: #ffbe00;
$ps-yellow-600: #bf8f00;
$ps-yellow-700: #975a16;
$ps-yellow-800: #744210;
$ps-yellow-900: #5f370e;

$ps-green-50: #f0fff4;
$ps-green-100: #eef9f5;
$ps-green-200: #d6f2e8;
$ps-green-300: #aee4d2;
$ps-green-400: #60d3b0;
$ps-green-500: #00a589;
$ps-green-600: #006453;
$ps-green-700: #276749;
$ps-green-800: #22543d;
$ps-green-900: #1c4532;

$ps-teal-50: #e6fffa;
$ps-teal-100: #e4f7f8;
$ps-teal-200: #b9ebee;
$ps-teal-300: #79d9df;
$ps-teal-400: #38b2ac;
$ps-teal-500: #19a7b0;
$ps-teal-600: #0a7077;
$ps-teal-700: #285e61;
$ps-teal-800: #234e52;
$ps-teal-900: #1d4044;

$ps-blue-50: #f7fafc;
$ps-blue-100: #ebf4fb;
$ps-blue-200: #d1eaff;
$ps-blue-300: #a0caed;
$ps-blue-400: #51a8e4;
$ps-blue-500: #0079ca;
$ps-blue-600: #085f98;
$ps-blue-700: #2c5282;
$ps-blue-800: #2a4365;
$ps-blue-900: #1a365d;

$ps-brand-50: var(--ps-colors-brand-50);
$ps-brand-100: var(--ps-colors-brand-100);
$ps-brand-200: var(--ps-colors-brand-200);
$ps-brand-300: var(--ps-colors-brand-300);
$ps-brand-400: var(--ps-colors-brand-400);
$ps-brand-500: var(--ps-colors-brand-500);
$ps-brand-600: var(--ps-colors-brand-600);
$ps-brand-700: var(--ps-colors-brand-700);
$ps-brand-800: var(--ps-colors-brand-800);
$ps-brand-900: var(--ps-colors-brand-900);

$ps-purple-50: #faf5ff;
$ps-purple-100: #f0ecfc;
$ps-purple-200: #dad1f9;
$ps-purple-300: #b6a4f3;
$ps-purple-400: #9f7aea;
$ps-purple-500: #6a41d7;
$ps-purple-600: #5238af;
$ps-purple-700: #553c9a;
$ps-purple-800: #44337a;
$ps-purple-900: #322659;

$ps-indigo-100: #e7e9ff;
$ps-indigo-200: #c5caff;
$ps-indigo-300: #959ffd;
$ps-indigo-500: #4756e0;
$ps-indigo-600: #1b2583;

$ps-white: #fff;
$ps-black: #1e2b36;

//** Primary
$ps-blue: $ps-blue-500;
$ps-green: $ps-green-500;
$ps-yellow: $ps-yellow-500;
$ps-red: $ps-red-500;

//** Secondary
$ps-orange: $ps-orange-500;
$ps-purple: $ps-purple-500;

//** Disabled
$ps-blue-disabled: $ps-blue-300;
$ps-green-disabled: $ps-green-300;
$ps-yellow-disabled: $ps-yellow-300;
$ps-red-disabled: $ps-red-300;
$ps-orange-disabled: $ps-orange-300;
$ps-purple-disabled: $ps-purple-300;

//** Hover
$ps-blue-hover: $ps-blue-600;
$ps-green-hover: $ps-green-600;
$ps-yellow-hover: $ps-yellow-600;
$ps-red-hover: $ps-red-600;
$ps-orange-hover: $ps-orange-600;
$ps-purple-hover: $ps-purple-600;

//== Typography
//
//##

$ps-font-size-xs: 12px;
$ps-font-size-xs-line-height: 1.3333; // 16px

$ps-font-size-sm: 14px;
$ps-font-size-md: 16px;
$ps-font-size-lg: 20px;
$ps-font-size-xl: 28px;
$ps-font-size-xxl: 36px;
$ps-font-size-xxxl: 38px;
$ps-font-size-xxxxl: 48px;

$ps-primary-font-size: $ps-font-size-md;
$ps-primary-font-line-height: 1.5; // 24px

$ps-font-style-normal: 400;
$ps-font-style-medium: 500;

//== Other
// TODO Tidy up and push down unless truly global
//##

$ps-navbar-height: 62px;
$ps-navbar-border-size: 2px;
$ps-navbar-offset: $ps-navbar-height + $ps-navbar-border-size;
$ps-focus-bar-offset: 72px;

$ps-template-widgets-sidebar-width: 144px;

$ps-row-height: ps-size(8);
$ps-input-height: ps-size(5);
$ps-dashboard-input-height: ps-size(5);
$ps-task-item-height: 52px;

$ps-dropdown-options-padding: $ps-base-unit;

$ps-approval-title-color: #2a98e3;
$ps-approval-content-color: #555;

$ps-checkbox-border-color: $ps-gray-400;
$ps-checkbox-bottom-border-color: $ps-gray-400;

$table-view-border: $ps-gray-200;
$table-view-header-bg: $ps-gray-50;
$table-view-header-text: $ps-gray-500;

// == Chakra breakpoints https://chakra-ui.com/docs/styled-system/features/responsive-styles
$ps-screen-sm-min: 480px;
$ps-screen-md-min: 768px;
$ps-screen-lg-min: 992px;
$ps-screen-xl-min: 1280px;
$ps-screen-2xl-min: 1536px;
$ps-screen-3xl-min: 1920px;
