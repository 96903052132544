//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../../../../styles/boulevard-theme/utilities/size';
@import '../../../../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.nameRenderer {
  line-height: initial;
  a {
    line-height: 55px;
    text-decoration: none;
  }
}

.checklistName {
  color: $ps-gray-700;
  display: inline-block;
  font-weight: 500;
  width: 100%;

  &:hover,
  &:focus {
    color: $ps-brand-500;
  }
}

.skeletonChecklistName {
  border-radius: 3px;
  height: ps-size(3);
  left: 0;
  position: absolute;
  top: ps-size(1.5);
  width: 300px;
}

.skeletonWrapperBody {
  animation-name: skeletonAnimate;
  background-image: linear-gradient(
    135deg,
    #f00 0%,
    #ffa500 15%,
    #ff0 30%,
    #008000 45%,
    #00f 60%,
    #4b0082 75%,
    #ee82ee 80%,
    #f00 100%
  );
  background-repeat: repeat;
  background-size: 50% auto;
}

.skeletonWrapperBody div {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderSkeleton;
  animation-timing-function: linear;
  background: linear-gradient(to left, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-size: 800px 100px;
}

@keyframes placeholderSkeleton {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes skeletonAnimate {
  from {
    background-position: top left;
  }
  to {
    background-position: top right;
  }
}
