tags-input {
  * {
    color: $ps-gray-600;
    font-family: $font-family-serif;
    font-size: $ps-primary-font-size;
    line-height: ps-size(3);
  }

  .host,
  .tags:focus,
  .tags:active {
    box-shadow: none;
    outline: 0;
  }

  &.ng-invalid .tags {
    border-color: $brand-danger;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px $brand-danger;
  }

  .autocomplete {
    margin-top: ps-size(0.5);
    padding: 0;

    .suggestion-list {
      padding: ps-size(0.5);
    }

    .suggestion-item {
      border-radius: ps-size(0.5);
      color: $ps-gray-600;
      padding: ps-size(0.5);

      &.selected {
        background-color: $ps-brand-500;
        color: $ps-gray-700;

        span {
          color: $ps-white;
        }

        em {
          background-color: $ps-brand-500;
          color: $ps-white;
        }
      }
    }
  }

  .tags {
    &.focused {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }

    .input {
      font-size: $ps-primary-font-size;
      // needs to be overwritten with height, the component uses a custom height.
      height: $ps-input-height;
      margin: 0;
    }

    .tag-item {
      background: $ps-gray-100;
      border-color: $ps-gray-100;
      color: $ps-gray-700;
      height: ps-size(3.5);
      margin: 6px;
      max-width: calc(100% - 5px);
      padding-top: 1px;
      position: relative;

      &.ng-enter {
        animation: zoomIn 0.3s normal forwards;
      }

      &.selected {
        background-color: darken($ps-gray-50, 10%);
        border-color: darken($ps-gray-50, 10%);
      }

      span {
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      button {
        color: $ps-gray-700;
      }

      .remove-button {
        display: inline-block;
        float: right;
        font-family: $font-family-serif;
        line-height: 1.5;
        margin-left: ps-size(0.5);

        &:hover {
          color: $ps-gray-700;
        }
      }
    }
  }

  &[disabled] {
    .tags {
      background-color: $ps-gray-100;
      border: 1px solid $ps-gray-200;
      border-top: 0;
      .tag-item {
        background-color: $ps-gray-300;
      }
      .input {
        background-color: $ps-gray-100;
      }
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
