//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.steps-list {
  .list-group-item.step.task-type-Automated {
    background: none;
    border: 0;
    cursor: default;
    height: var(--ps-sizes-10);
    margin-left: ps-size(4) - 2;
    min-height: 0;
    padding: 0;
  }

  .list-group-item.step.task-type-Standard + .list-group-item.step.task-type-Automated {
    border-top: 1px solid $ps-gray-200;
  }

  .list-group-item:not(.task-type-Automated) {
    margin-bottom: 0;

    .step-progressbar {
      bottom: -1px;
    }

    &:first-child {
      border-top-left-radius: var(--ps-sizes-2);
      border-top-right-radius: var(--ps-sizes-2);
    }

    &:last-child {
      border-bottom-left-radius: var(--ps-sizes-2);
      border-bottom-right-radius: var(--ps-sizes-2);

      .step-progressbar {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        bottom: 0;
        left: 2px;
        right: 2px;
      }
    }

    &.step {
      margin-left: ps-size(4) - 2;
      padding-left: ps-size(2);

      &.selected {
        border-color: $ps-brand-500;
      }

      &.checkable {
        padding-right: 0;
      }

      &.checkable:not(.heading):not(.approval) {
        padding-left: 46px;
      }

      &.task-disabled:not(.selected):not(.required):not(.has-error),
      &.not-completable:not(.selected):not(.required):not(.has-error) {
        background-color: $ps-gray-50;
        color: $ps-gray-400;
      }

      &.approval-subject:not(.required):not(.has-error) {
        background-color: $ps-brand-200;
      }
    }
  }

  .show-stopped-tasks-button {
    margin-left: 15px;
  }
}
