//
// Scaffolding
// --------------------------------------------------

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  text-rendering: optimizeLegibility;
  touch-action: manipulation;
}

body {
  // These variables are available to JavaScript.
  @include define-custom-property('screen-md-min', $screen-md-min);
  font-feature-settings: 'kern';
  height: 100%;
  min-height: 100%;

  > .view {
    height: 100%;

    > .navbar-offset {
      padding-top: $ps-navbar-offset;
    }
  }
}

a {
  color: var(--ps-colors-brand-500);
  cursor: pointer;

  // Use the default color for the WF editor links
  &[data-mce-href] {
    color: var(--ps-colors-blue-500);

    &:hover {
      color: var(--ps-colors-blue-600);
    }
  }

  &:hover,
  &:active,
  &:visited,
  &:focus {
    text-decoration: none;
  }

  &:hover {
    color: $ps-brand-600;
  }

  &:focus {
    color: $ps-brand-700;
  }
}

span[data-mce-style*='color'] a {
  // prevent from overriding data-mce color
  color: inherit;
}

textarea {
  resize: vertical;
}

// Wrapper for simple pages
// --------------

.simple-page-container {
  padding-bottom: ps-size(5);
  padding-top: ps-size(4);
}
