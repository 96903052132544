//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../../styles/boulevard-theme/utilities/size';
@import '../../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.checklist-details-controls {
  column-gap: 16px;
  display: flex;
  justify-content: space-between;
  margin: 4px 30px;

  .ps-snoozer {
    padding-left: 3px;
  }

  .btn {
    border-radius: 3px;
    font-size: 14px;
    line-height: 1.5;
    padding: 6px ps-size(1);
  }

  .btn-primary {
    height: ps-size(4);
    padding: 1px 2px;
    width: ps-size(4);
  }

  .task-assign-btn {
    padding: 6px ps-size(1);
  }

  .first-control {
    column-gap: 16px;
    display: flex;
    flex-direction: row;
  }
}

.one-off-task-container {
  margin-bottom: var(--ps-space-8);
}
