//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.manage-template-members {
  .td-small {
    padding: 8px 0;
  }
}

.participant-row {
  border-bottom: 1px solid $ps-gray-200;
}

.list-header {
  padding-bottom: 32px;
}

.search-container {
  padding-left: 12px;
  padding-right: 12px;
}

.permissions-description {
  display: block;
  height: 40px;
  padding-bottom: 9px;
  padding-left: 12px;
  padding-top: 9px;
}
