//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../styles/boulevard-theme/utilities/size';
@import '../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.container {
  color: $ps-gray-400;
  display: inline-block;
  padding: 1em;
  position: absolute;
}

.icon {
  margin-right: 0.2em;
}

.link {
  color: $ps-gray-400;
}
