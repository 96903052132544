//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../styles/boulevard-theme/utilities/size';
@import '../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

$left-search-bar-width: 275px;
$template-overview-card-width: 304px + 36px;
$scrollbar-width: 12px;
$card-container-padding: 32px;

$two-cards-breakpoint: 1080px;
$three-cards-breakpoint: 1420px;

.templateGalleryModal {
  display: flex;

  height: auto;
  margin: 0;
  min-height: 100%;
  padding: 0;
  width: 100%;

  // x1 cards
  @media (min-width: $screen-sm-min) {
    height: calc(100vh - #{$ps-navbar-offset});
    margin: ps-size(4) auto;
    min-height: 600px;
    width: $left-search-bar-width + $scrollbar-width + $card-container-padding + $template-overview-card-width !important;
  }
  // x2 cards
  @media (min-width: $two-cards-breakpoint) {
    width: $left-search-bar-width + $scrollbar-width + $card-container-padding + ($template-overview-card-width * 2) !important;
  }
  // x3 cards
  @media (min-width: $three-cards-breakpoint) {
    width: $left-search-bar-width + $scrollbar-width + $card-container-padding + ($template-overview-card-width * 3) !important;
  }

  :global(.chakra-modal__body) {
    display: flex;
    flex: 2;
    flex-direction: column;
    padding: 0;
    width: 100%;

    h5 {
      color: $ps-gray-700;
      font-weight: 500;
      margin-bottom: 8px;
      margin-top: 0;
      text-align: center;
    }

    @media (min-width: $screen-sm-min) {
      flex-direction: row;
      h5 {
        text-align: left;
      }
    }
  }
}
