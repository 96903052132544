//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.editor.editable .widgets-container {
  .edit-widget-menu {
    position: absolute;
    // 6 = button size, 3 = spacing
    right: calc((var(--ps-sizes-6) + var(--ps-sizes-3)) * -1);
    top: 0;

    @media screen and (min-width: 30em) {
      right: calc((var(--ps-sizes-6) + var(--ps-sizes-1-5)) * -1);
    }

    @media screen and (min-width: $ps-screen-2xl-min) {
      right: calc((var(--ps-sizes-6) + var(--ps-sizes-3)) * -1);
    }
  }
  .conditional-logic-btn {
    display: none;
    // 6 = button size, 3 = spacing
    padding: 0 4px;
    position: absolute;
    right: calc((var(--ps-sizes-6) + var(--ps-sizes-3)) * -1);
    top: 40px;

    & i {
      color: var(--ps-colors-purple-500);
    }

    @media screen and (min-width: 30em) {
      right: calc((var(--ps-sizes-6) + var(--ps-sizes-1-5)) * -1);
    }

    @media screen and (min-width: $ps-screen-2xl-min) {
      right: calc((var(--ps-sizes-6) + var(--ps-sizes-3)) * -1);
    }
  }

  .widget-outer:hover .conditional-logic-btn {
    display: block;
  }

  .widget-outer:hover .edit-widget-menu__button,
  .widget-outer.pseudo-hover .edit-widget-menu__button {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .widget-outer {
    padding-bottom: var(--ps-sizes-2);
    padding-left: 30px;
    padding-right: 30px;
    padding-top: var(--ps-sizes-2);

    @media screen and (min-width: $ps-screen-2xl-min) {
      padding-left: var(--ps-sizes-8);
      padding-right: var(--ps-sizes-8);
    }
  }

  .widget-outer:hover,
  .widget-outer.pseudo-hover,
  .ui-sortable-helper .widget-outer {
    background-color: transparent;

    .widget-inner {
      border-color: var(--ps-colors-gray-200);
    }
  }

  .widget-inner {
    border: 1px solid var(--ps-colors-gray-100);
    border-radius: var(--ps-radii-xl);
    max-width: 640px;
    padding: var(--ps-sizes-6);
  }

  .form-field-footer--validation {
    height: var(--ps-sizes-10);
  }

  .widget-inner--field-type-Snippet .form-field-footer,
  .widget-inner--field-type-Hidden .form-field-footer {
    align-items: flex-end;
    height: 0;
    // Center aligned with hidden helper text
    margin-top: -20px;
  }

  $ps-date-button-width: 204px;
  $ps-file-button-width: 244px;

  @media screen and (min-width: $ps-screen-sm-min) {
    .widget-inner--field-type-Date .form-field-footer__right-element {
      padding-left: calc($ps-date-button-width + var(--ps-space-2));
    }

    .widget-inner--field-type-File .form-field-footer__right-element {
      padding-left: calc($ps-file-button-width + var(--ps-space-2));
    }

    .field-date button {
      width: var(--ps-date-button-width);
    }

    .field-file button {
      width: var(--ps-file-button-width);
    }

    .widget-inner--field-type-Snippet .form-field-footer,
    .widget-inner--field-type-Hidden .form-field-footer {
      margin-top: 0;
    }
  }

  // negative margin is used on the date and file widgets to align the required indicator. Extra padding needed
  .widget-inner--field-type-File,
  .widget-inner--field-type-Date {
    padding-bottom: var(--ps-sizes-8);

    @media screen and (min-width: $ps-screen-sm-min) {
      .form-field-footer {
        height: var(--ps-sizes-10);
        margin-top: calc(var(--ps-sizes-10) * -1);
      }
    }
  }

  .widget-inner--field-type-MultiChoice,
  .widget-inner--field-type-MultiSelect,
  .widget-inner--field-type-Select {
    .form-field-footer {
      @media screen and (min-width: $ps-screen-sm-min) {
        height: var(--ps-sizes-10);
      }
    }
  }

  .widget-inner--field-type-Select--dataset {
    .form-field-footer {
      @media screen and (min-width: $ps-screen-sm-min) {
        margin-top: calc(var(--ps-sizes-10) * -1);
      }
    }
  }

  ps-required-option {
    align-items: center;
    display: flex;
  }

  .required-option {
    align-items: center;
  }

  .required-option .checkbox-text {
    height: auto;
    padding-bottom: 0;
    padding-top: 0;
  }
  .required-option .checkbox {
    margin: 0;
  }

  // template-file-widget styles copied from chakra output of template-file-widget.stories
  .template-file-widget__upload-button {
    align-items: center;
    border-color: var(--ps-colors-gray-200);
    border-radius: var(--ps-radii-base);
    border-style: solid;
    border-width: var(--ps-borderWidths-px);
    display: flex;
    height: var(--ps-sizes-10);
    justify-content: space-evenly;
    margin: 0 auto;
    max-width: var(--ps-sizes-50);
    overflow: visible;
    padding-bottom: var(--ps-space-2);
    padding-top: var(--ps-space-2);
    position: relative;

    &:hover,
    &:focus {
      background-color: var(--ps-colors-gray-50);
      .template-file-widget__upload-button__left-element {
        background-color: var(--ps-colors-brand-600);
      }
    }

    @media screen and (min-width: 30em) {
      height: var(--ps-sizes-13);
      max-width: var(--ps-sizes-63);
    }

    input {
      width: 100%;
    }
  }

  .template-file-widget__upload-button__left-element {
    align-items: center;
    background: var(--ps-colors-brand-500);
    border-bottom-left-radius: var(--ps-radii-base);
    border-top-left-radius: var(--ps-radii-base);
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    height: var(--ps-sizes-10);
    justify-content: center;
    left: calc(var(--ps-space-px) * -1);
    position: absolute;
    top: calc(var(--ps-space-px) * -1);
    width: var(--ps-sizes-10);

    i {
      color: var(--ps-colors-white);
      display: inline-block;
      flex-shrink: 0;
      height: 16px !important;
      line-height: 1em;
      width: 16px !important;
    }

    @media screen and (min-width: 30em) {
      height: var(--ps-sizes-13);
      width: var(--ps-sizes-13);
    }
  }

  .template-file-widget__upload-button__spacer {
    width: var(--ps-sizes-4);
  }

  .template-file-widget__upload-button__label {
    color: var(--ps-colors-gray-600);
    font-size: var(--ps-fontSizes-md);
    font-weight: var(--ps-fontWeights-bold);
    letter-spacing: inherit;
    line-height: var(--ps-lineHeights-base);
    margin: initial;
    text-transform: inherit;
  }

  .template-file-widget__upload-button__icon {
    box-sizing: content-box;
    color: var(--ps-colors-gray-400);
    display: inline-block;
    flex-shrink: 0;
    height: 16px !important;
    line-height: 1em;
    overflow: hidden;
    width: 16px !important;
  }

  .field-select,
  .field-multi-choice {
    select.form-control {
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      color: var(--ps-colors-gray-400);
    }

    .items {
      border-bottom-left-radius: var(--ps-radii-xl);
      border-bottom-right-radius: var(--ps-radii-xl);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding-bottom: var(--ps-sizes-4);
      padding-top: var(--ps-sizes-4);

      & > *:not(style) ~ *:not(style) {
        padding-top: var(--ps-sizes-2);

        .item-number-container {
          top: var(--ps-sizes-2);
        }
      }

      button {
        margin: 20px 0 0 10px;
      }

      .item {
        align-items: center;
        display: flex;

        .step-grip {
          display: none;
        }

        .delete-item-container {
          align-items: center;
          cursor: pointer;
          display: none;
          margin-left: -25px;
          i {
            color: var(--ps-colors-gray-400);
          }
        }

        .item-number-container {
          cursor: grab;
        }

        &:hover,
        &:focus {
          .item-number {
            display: none;
          }

          .step-grip {
            display: flex;
          }

          .delete-item-container {
            display: flex;
          }
        }
      }

      .item-name {
        border: 1px solid var(--ps-colors-gray-300);
        border-radius: var(--ps-radii-base);
        color: var(--ps-colors-gray-600);

        ::placeholder {
          color: var(--ps-colors-gray-400);
        }
      }
    }
  }

  .form-group-select + ul.items {
    margin-top: 0;
  }

  .help-block {
    margin-bottom: 0;
  }

  ps-template-video-widget textarea {
    max-width: 100%;
  }

  .embed-reponsive {
    border-radius: 8px;
  }

  .widget-video figcaption {
    margin-top: var(--ps-sizes-5);
    padding-top: 0;
  }
  .widget-video .video-link-container {
    margin-bottom: 0;
  }

  // image widget
  img {
    &.img-rounded {
      border-radius: 8px;
    }
  }

  .fileinput-image {
    position: relative;
    img {
      max-width: 100%;
      width: 100%;
    }
    input {
      cursor: pointer !important;
      direction: ltr;
      filter: alpha(opacity=0);
      height: 100%;
      left: 0;
      margin: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .widget-image figcaption {
    margin-top: var(--ps-sizes-5);
    padding-top: 0;
  }

  ps-template-text-widget .widget-editor {
    border: 1px solid $ps-gray-300;
    min-height: var(--ps-sizes-30);
  }

  .mce-content-body {
    br[data-mce-bogus]::before {
      content: 'Type here';
      display: block;
    }
  }

  // embed widget
  .sandboxed-iframe {
    border-radius: var(--ps-sizes-2);
  }
}
