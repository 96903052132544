//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.approval-subject-task-approve-comment {
  .comment {
    margin-bottom: 16px;
    resize: none;
  }

  .controls {
    text-align: right;

    .btn {
      padding-left: 20px;
      padding-right: 20px;
    }

    .btn-cancel {
      margin-right: var(--ps-space-4);
    }

    .btn-approve {
      background-color: var(--ps-colors-blue-500);
      color: var(--ps-colors-white);

      .fa-thumbs-up {
        margin-right: 4px;
      }
    }
  }
}
