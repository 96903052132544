//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.checklist-print-legacy {
  .form-control {
    font-size: $ps-primary-font-size - 2;
  }

  .logo-container {
    height: 32px;
    width: 32px;
  }

  .checklist-activity {
    margin-bottom: 0;
  }

  .checklist-assignment {
    float: left;
    padding-bottom: 10px;
    width: 33.333333%;
  }

  .checklist-assignee-name {
    padding-left: 2px;
  }

  .summary-task-name {
    max-width: 70%;
  }

  .summary-task-completed-counter {
    float: right;
  }

  .summary-task-text-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .summary-task-details {
    font-size: 10px;
    margin-left: 15px;
    text-align: right;
  }

  .task {
    .task-info {
      align-items: flex-start;
      background-color: $ps-white;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 10px;
      padding: 0;
    }

    .task-assignees {
      padding-right: 30px;
    }

    .task-due-date {
      white-space: nowrap;
    }

    .checklist-due-date {
      white-space: nowrap;
    }
  }

  .doodads {
    margin: 30px auto 40px;
    max-width: 100%;
    padding: 0;

    .doodad {
      margin-bottom: 5px;
      padding: 0 0 0 20px;
      page-break-inside: avoid;

      * {
        // Override bootstrap print css
        color: $ps-gray-200 !important;
      }
    }

    .doodad-activity-icon {
      width: inherit;
    }

    .doodad-avatar {
      display: none;
    }

    .doodad-author {
      margin-top: 15px;
    }

    .doodad-date {
      line-height: 18px;
      margin-bottom: 15px;
    }

    .doodad-comment-content {
      line-height: 18px;
      margin: 5px 0;

      p {
        margin-bottom: 0;
      }
    }

    .doodad-attachment-content {
      margin: 5px 0;
    }
  }

  .widget-form-field .audit-info {
    // Override bootstrap print css
    color: $ps-gray-200 !important;

    * {
      color: $ps-gray-200 !important;
    }
    .input-group .btn {
      height: ps-size(4.5) - 1;
      line-height: 0;
    }
  }
}

.checklist-print {
  .form-control {
    font-size: $ps-primary-font-size - 2;
  }

  .checklist-print-organization-logo {
    align-items: center;
    display: flex;
    gap: 1rem; // sass-lint:disable-line no-misspelled-properties Ignores gap on sass-linter since it's not supported
  }

  .logo-container {
    height: 32px;
    width: 32px;
  }

  .checklist-activity {
    margin-bottom: 0;
  }

  .summary .list-group {
    background-color: var(--ps-colors-gray-50);
    .list-group-item {
      background: none;
    }
  }

  .chakra-form__label,
  .form-group .control-label,
  .form-group label {
    color: var(--ps-colors-gray-600);
    font-size: var(--ps-fontSizes-sm);
    font-weight: var(--ps-fontWeights-medium);
  }

  .chakra-form-control {
    [data-component='FormFieldAudit'] {
      color: var(--ps-colors-gray-400);
    }
    .chakra-input {
      font-size: var(--ps-fontSizes-sm);
    }
  }

  .chakra-input__group {
    [data-component='MaskedInput'] {
      opacity: 1;
    }
    .chakra-input {
      font-size: var(--ps-fontSizes-sm);
      &:disabled {
        color: var(--ps-colors-gray-600);
        opacity: 1;
      }
    }
  }

  .checklist-assignment {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem; // sass-lint:disable-line no-misspelled-properties Ignores gap on sass-linter since it's not supported
  }

  .checklist-assignment-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem; // sass-lint:disable-line no-misspelled-properties Ignores gap on sass-linter since it's not supported
    .checklist-assignments {
      width: 70px;
    }
  }

  .checklist-assignee-name {
    text-align: center;
  }

  .chakra-checkbox__control {
    border: 1px solid var(--ps-colors-gray-400) !important;
  }

  [data-component='SendRichEmailChecklistWidget'] p {
    font-size: var(--ps-fontSizes-sm);
    word-break: auto-phrase;
  }

  // Hide email controls (Edit/Send)
  .send-rich-email-checklist-widget__controls {
    display: none;
  }
  [data-component='EmailAttachmentItem'] .chakra-link {
    p {
      margin-bottom: 0;
    }
  }

  .summary-task-name {
    max-width: 70%;
  }

  .summary-task-completed-counter {
    float: right;
  }

  .summary-task-text-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .summary-task-details {
    font-size: 10px;
    margin-left: 15px;
    text-align: right;
  }

  .task {
    margin-bottom: 40px;
    .task-header,
    .task-number-container {
      font-size: 18px !important;
    }

    .task-info {
      align-items: flex-start;
      background-color: var(--ps-colors-white);
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 10px;
      padding: 0;
    }

    .task-assignees {
      padding-right: 30px;
    }

    .task-due-date {
      white-space: nowrap;
    }

    .task-approval-info-wrapper {
      padding-bottom: 0 !important;
    }

    .widget-container {
      padding-bottom: 20px;
    }

    .checklist-due-date {
      white-space: nowrap;
    }
  }

  .widget-text .widget-content p {
    font-size: var(--ps-fontSizes-sm);
  }

  .approval-subject-tasks-list-item {
    // Hides approve/reject button section
    .controls {
      display: none;
    }
  }

  .ui-select-bootstrap .ui-select-match .ui-select-toggle {
    opacity: 1;
  }

  .doodads {
    .doodad {
      margin-bottom: 5px;
      padding: 0 0 0 20px;
      page-break-inside: avoid;
      .activity {
        margin-bottom: 1rem;
      }
    }

    .doodad-activity-icon {
      width: inherit;
    }

    .doodad-avatar {
      display: none;
    }

    .doodad-author {
      margin-top: 15px;
    }

    .doodad-date {
      line-height: 18px;
      margin-bottom: 15px;
    }

    .doodad-comment-content {
      line-height: 18px;
      margin: 5px 0;

      p {
        margin-bottom: 0;
      }
    }

    .doodad-attachment-content {
      margin: 5px 0;
    }
  }

  .widget-form-field .audit-info {
    .input-group .btn {
      height: ps-size(4.5) - 1;
      line-height: 0;
    }
  }
  // Reduce the font size on comment section
  [data-component='AuditInfoStack'] {
    p {
      font-size: var(--ps-fontSizes-xs);
    }
    [aria-label='comment sender'] {
      font-weight: var(--ps-fontWeights-bold);
    }
  }
  [data-component='ContentText'] {
    font-size: var(--ps-fontSizes-sm);
  }
}

@media print {
  .checklist-print {
    h2,
    h3 {
      page-break-after: avoid;
    }

    .summary .list-group {
      background-color: var(--ps-colors-gray-50) !important;
      .list-group-item {
        background: none !important;
      }
    }

    .assignee-list__avatar,
    .avatar [class^='initials'] {
      background-color: var(--ps-colors-gray-300) !important;
      color: var(--ps-colors-white) !important;
    }

    .assignee-list__avatar .chakra-avatar__initials {
      color: var(--ps-colors-white) !important;
    }
    [data-component='AttachmentFile'] {
      [data-element='Center'] {
        background-color: var(--ps-colors-brand-600) !important;
        p {
          color: var(--ps-colors-white) !important;
        }
      }
    }
  }
}
