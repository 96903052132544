//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.organization-manage-settings {
  .domains {
    right: 0;
  }

  .logo-area {
    display: flex;
    flex-direction: column;
  }

  .logo-help-text {
    padding-top: 10px;
  }

  // Small devices (tablets, 768px and up)
  @media (min-width: $screen-sm-min) {
    .logo-area {
      align-items: center;
      display: flex;
      flex-direction: row;
    }

    .logo-help-text {
      padding-top: 0;
    }
  }
}
