//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.ddd-template-popup {
  ul.dropdown-menu {
    max-width: 96px;
    width: 96px;
  }

  .offset-container {
    padding-right: 0;
  }

  .label-container {
    padding-left: 10px;
    padding-right: 10px;

    .control-label {
      margin-left: 6px;
    }
  }

  .button-line .btn {
    margin-bottom: 10px;
    width: 100%;
  }

  .no-bottom-margin {
    margin-bottom: 0;
  }
  .no-right-margin {
    margin-right: 0;
  }

  .btn-offset-direction {
    max-width: 96px;
    position: relative;
    text-align: left;
    width: 96px;
  }
}

.pop-box-new.dyn-due-date-pop-box {
  width: 360px;
}
