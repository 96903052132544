//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../../../../../styles/boulevard-theme/utilities/size';
@import '../../../../../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.dualDateContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.andConnector {
  color: $ps-gray-500;
  font-size: $ps-font-size-xs;
  font-weight: bold;
  margin: 0 ps-size(1);
  text-transform: uppercase;
  width: 27px;
}
