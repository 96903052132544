//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.dropzone {
  .cover {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    color: $ps-white;
    display: none;
    font-size: 40px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    transition: background-color 0.2s ease;
    z-index: 3;

    i {
      font-size: 70px;
    }
  }

  .dragover & .cover {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .dragover &.hover .cover {
    background-color: rgba(0, 0, 0, 0.8);
  }
}
