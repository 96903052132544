//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../styles/boulevard-theme/utilities/size';
@import '../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.header {
  display: none;
  padding: ps-size(2, 0);

  @media (min-width: $screen-sm-min) {
    display: inherit;
  }
}

.title {
  color: $ps-gray-700;
  font-size: $ps-font-size-xs;
  font-weight: bold;
  height: ps-size(2);
  letter-spacing: 1px;
  line-height: ps-size(2);
  text-transform: uppercase;
}

.categoryDropdwonSelect {
  margin-top: ps-size(2);
  @media (min-width: $screen-sm-min) {
    display: none;
  }
}

.categoryListSelect {
  display: none;
  @media (min-width: $screen-sm-min) {
    display: inherit;
  }
}
