//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

@mixin task-item {
  .step {
    cursor: pointer;
    min-height: 32px;
    outline: none;
    padding: 5px 86px 5px 37px;

    &.heading {
      border-bottom-width: 2px;
      font-weight: bold;
      padding-bottom: 4px;
    }

    &.approval-rule-subject {
      background-color: $ps-gray-100;
    }
  }

  .ui-sortable-helper {
    border-radius: 0;
    display: block;
    left: 20px !important;

    .widget-menu-btn {
      display: none !important;
    }
  }

  // Medium devices (desktops, 992px and up)
  @media (min-width: $screen-md-min) {
    .step {
      padding: 5px 56px 5px 37px;
    }
  }
}

.steps-list {
  opacity: 1;

  &.ng-hide {
    opacity: 0;
  }

  .steps-list.ng-hide-add,
  .steps-list.ng-hide-remove {
    transition: 0.3s ease-in all;
  }

  @include task-item();
}

.steps-header {
  border-color: var(--ps-colors-gray-200);
  color: var(--ps-colors-gray-500);
  font-size: var(--ps-fontSizes-sm);
  font-weight: 400;
  line-height: var(--ps-lineHeights-short);
}

.scroller-with-ui-sortable {
  // makes sure ui-sortable doesn't get .os-content as offsetParent.
  .os-content {
    position: static;
  }
}
