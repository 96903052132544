//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.field-select {
  .field-select__empty-text {
    color: var(--ps-colors-gray-400);
    font-size: var(--ps-fontSizes-xs);
    font-weight: var(--ps-fontWeights-semibold);
    padding-left: var(--ps-space-2);
    padding-right: var(--ps-space-2);
  }

  .ui-select-bootstrap .ui-select-match-text {
    margin-top: var(--ps-space-1-5);
  }
}
