//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.widget-video {
  page-break-inside: avoid; // Avoid splitting videos in half in print mode
  text-align: center;

  .s3 {
    display: inline-block;
    width: 100%;
  }

  figcaption {
    color: $ps-gray-400;
    font-size: 12px;
    font-style: italic;
    padding: 6px 0 0;
    position: relative;
    text-align: left;
    width: 100%;
  }

  textarea {
    display: block;
    margin: 0 auto;
    max-width: 500px;
  }

  video {
    vertical-align: middle;
  }

  .widget-video-container {
    background: $ps-gray-50;
    border: 1px solid $panel-default-border;
  }

  .widget-video-or {
    margin: 0 12px 0 0;
  }

  .widget-video-spinner {
    align-items: center;
    background: $ps-gray-50;
    color: $ps-gray-500;
    display: flex;
    font-size: 20px;
    justify-content: center;
    text-align: center;
  }

  .fileinput-image {
    position: relative;
    img {
      max-width: 100%;
      width: 100%;
    }

    .fileinput-image__placeholder {
      align-items: center;
      aspect-ratio: 16 / 9; // sass-lint:disable-line no-misspelled-properties sass-lint has no support to aspect-ratio
      background-color: var(--ps-colors-brand-100);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      position: relative;

      .fa.fa-circle-play {
        color: var(--ps-colors-white);
        font-size: 100px;

        @media (min-width: $screen-md-min) {
          font-size: 160px;
        }
      }

      .fileinput-image__add {
        align-items: center;
        background-color: var(--ps-colors-brand-500);
        border-radius: 4px;
        color: #fff;
        display: flex;
        font-size: 12px;
        height: 40px;
        justify-content: center;
        position: absolute;
        right: 16px;
        top: 16px;
        width: 40px;

        @media (min-width: $screen-md-min) {
          font-size: 18px;
          height: 50px;
          width: 50px;
        }

        .fa.fa-plus {
          color: var(--ps-colors-white);
        }
      }
    }

    input {
      cursor: pointer !important;
      direction: ltr;
      filter: alpha(opacity=0);
      height: 100%;
      left: 0;
      margin: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .video-link-container {
    align-items: center;
    display: flex;
    margin: 20px 0;
  }
}
