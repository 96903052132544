//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../styles/boulevard-theme/utilities/size';
@import '../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.approval-rules-view-container {
  .empty-list {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    text-align: center;
  }

  .list-title {
    padding-top: 15px;
  }

  .header {
    color: $ps-gray-400;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 10px;
  }
}
