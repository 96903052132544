@mixin fa-unicode-icon($fa-icon, $size: $ps-primary-font-size, $weight: 400) {
  -webkit-font-smoothing: antialiased;
  align-items: center;
  content: fa-content($fa-icon);
  display: flex;
  font-family: 'Font Awesome 6 Pro';
  font-size: $size;
  font-style: normal;
  font-variant: normal;
  font-weight: $weight;
  height: 100%;
  justify-content: center;
  line-height: 1em;
  text-rendering: auto;
}
