//
// Wells
// --------------------------------------------------

.well {
  border: 1px solid darken($well-bg, 14%);
  margin: ps-size(5) auto ps-size(3);
  max-width: 500px;
  padding: 29px;
}
