//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.blvdCheckbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  overflow: hidden;
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.checkbox {
  border-radius: ps-size(0.5);
  box-sizing: border-box;
  height: ps-size(2.5);
  margin-right: ps-size(1);
  min-height: ps-size(2.5);
  min-width: ps-size(2.5);
  width: ps-size(2.5);
}

.checked {
  background-color: $ps-brand-500;
  color: $ps-white;
  line-height: ps-size(2.5);
  text-align: center;

  i {
    font-size: 14px !important;
  }
}

.unchecked {
  background-color: $ps-white;
  border: 1px solid $ps-gray-400;
}
