//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.membersItem {
  align-content: space-between;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 8px;

  &.selectable {
    cursor: pointer;
  }

  &.selectable:hover {
    background-color: $ps-brand-500;

    .userName {
      color: $ps-white;
    }

    .email {
      color: $ps-white;
    }
  }

  .labels {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding-left: 16px;
  }

  .userName {
    color: $ps-gray-600;
    font-size: 16px;
  }

  .email {
    color: $ps-gray-500;
    font-size: 12px;
  }

  .deleteIcon {
    color: $ps-gray-300;
    cursor: pointer;
    margin-right: 16px;

    &:hover {
      color: $ps-gray-600;
    }
  }

  .memberInfo {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
