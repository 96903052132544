.uppercase-capitalized {
  color: $ps-gray-400;
  font-size: $ps-font-size-xs;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.primary-text {
  font-size: $ps-primary-font-size;
  line-height: $ps-primary-font-line-height;
}

.text-xs {
  color: $ps-gray-500;
  font-size: $ps-font-size-xs;
  line-height: $ps-font-size-xs-line-height;
}

.page-header {
  @extend .uppercase-capitalized;
  margin-bottom: ps-size(2);
  margin-top: ps-size(5);
  padding-bottom: ps-size(2);

  &.first {
    margin-top: 0;
  }
}

.text-limited {
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncate-text {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.help-block {
  color: $ps-gray-400;
}
