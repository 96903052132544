//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../../styles/boulevard-theme/utilities/size';
@import '../../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.checklistDashboardGrid {
  color: $ps-gray-500;
  font-size: $ps-font-size-sm;
  height: calc(100% - 136px);
  margin-top: ps-size(2);
  width: 100%;

  @media (min-width: $screen-sm-min) {
    margin-top: ps-size(3);
  }

  :global(.ag-center-cols-container) {
    min-width: 100% !important;

    .row {
      border-left: 0;
    }
  }

  :global(.ag-header-row) {
    color: $ps-gray-500;
    font-weight: $ps-font-style-medium;

    :global(.ag-header-cell-text) {
      font-size: $ps-font-size-xs;
      margin-right: ps-size(1);
    }
  }

  :global(.ag-row) {
    background-color: $ps-white !important;
    border-bottom: $ps-gray-200 solid 1px !important;
    height: ps-size(7) !important;
    line-height: 38px;

    :global(.ag-cell) {
      align-items: center;
      display: inline-flex;
      padding: ps-size(0, 1);

      :global(.ag-react-container) {
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100%);
      }
    }
  }

  :global(.ag-cell-focus),
  :global(.ag-cell) {
    outline: none !important;
  }

  :global(.ag-header-icon) {
    font-size: $ps-font-size-sm;
    padding-left: ps-size(1);
  }

  :global(.ag-header-cell) {
    padding: ps-size(0, 1);
    text-transform: uppercase;
  }

  :global(.ag-header-cell-resize) {
    &:after {
      background-color: $ps-gray-200;
      content: '';
      display: block;
      height: 30%;
      left: calc(50% - 1px);
      position: absolute;
      top: 35%;
      width: 2px;
      z-index: 1;
    }
  }

  :global(.ag-tooltip) {
    background-color: $ps-gray-700;
    border-radius: 4px;
    color: $ps-white;
    font-size: $ps-font-size-xs;
    padding: 2px ps-size(1);
  }
}
