//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.updating-checklist {
  padding-bottom: 16px;
  position: relative;

  .updating-checklist-text {
    color: $ps-gray-400;
    font-weight: 500;
    left: 40px;
    position: relative;
  }
}

.updating-checklist-spinner {
  display: inline;
  left: 16px;
}

.rightbar .sidebar .divisor {
  margin-bottom: 8px;
  margin-top: 8px;
}

.rightbar {
  .rightbar-show-btn {
    background-color: $ps-gray-50;
    border-radius: 4px 0 0 4px;
    box-shadow: 0 0 6px rgba($ps-gray-700, 0.4);
    padding-right: 20px;
    position: fixed;
    right: -4px;
    top: $ps-navbar-offset + 10px;
    transform: translate(0, 0);
    transition: 0.1s ease-in all;
    z-index: 1030;
  }

  .rightbar-show-btn.ng-hide,
  .dragover & .rightbar-show-btn {
    transform: translate(150px, 0);
  }

  .rightbar-hide-btn {
    color: $ps-gray-500;
    height: 52px;
    left: -32px;
    overflow: hidden;
    position: absolute;
    top: 9px;
    width: 32px;

    i {
      background-color: $ps-gray-50;
      border-bottom: 1px solid $ps-gray-200;
      border-left: 1px solid $ps-gray-200;
      border-radius: 3px 0 0 3px;
      border-top: 1px solid $ps-gray-200;
      box-shadow: -2px 2px 4px 0 $ps-gray-200;
      height: 30px;
      margin: 6px 0 0 12px;
      padding: 6px;
      position: absolute;
      right: 0;
      top: 2px;
      width: 26px;
    }
  }

  .sidebar {
    background-color: $ps-gray-50;
    border-left: 1px solid $ps-gray-200;
    bottom: 0;
    box-shadow: -2px 0 4px 0 $ps-gray-200;
    display: block;
    position: fixed;
    right: 0;
    top: $ps-navbar-offset;
    transform: translate3d(0, 0, 0); //hide scrollbars below
    transition: 0.1s ease-in transform;
    width: 270px;
    z-index: 1029;

    hr {
      border-color: $btn-default-border;
      clear: both;
      margin: ps-size(2, 0);
    }

    h4 {
      color: $ps-gray-500;
      font-size: 16px;
      line-height: 1.25em;
      margin-bottom: 6px;
      margin-top: 0;
    }

    .btn {
      display: block;
      margin-bottom: 20px;
      position: relative;
      text-align: left;
      width: 100%;
    }

    .list-group-item {
      outline: none;

      .label {
        top: 5px;
      }
    }

    .btn-transparent:hover {
      background-color: $ps-gray-400;
    }

    &.instantly {
      transition: none;
    }
  } //end .sidebar

  .sidebar.ng-hide,
  .dragover & .sidebar {
    transform: translate(300px, 0);
  }

  .sidebar-scroller {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .sidebar-content {
    padding: ps-size(2, 2, 9);
  }

  .sidebar-status {
    color: $ps-gray-500;
    margin-bottom: 20px;
  }

  .sidebar-menu .list-group-item {
    border: 0;
    padding: 8px;
  }

  .sidebar-menu {
    background: transparent;
    border-style: none;
    box-shadow: none;

    .panel-heading {
      background: transparent;
      border-style: none;
      display: block;
      margin-bottom: ps-size(1);
      padding: 0;
    }

    .list-group {
      background: $ps-white;
      border: 1px solid $ps-gray-300;
      border-radius: ps-size(0.5);
      padding: ps-size(0.5);
    }
  } // end .sidebar-menu

  .memberships {
    margin: 15px -1px;
    margin-left: ps-size(2);
    padding: 0;
  }

  .membership {
    display: inline-block;

    a {
      display: inline-block;
      padding: 1px;
      vertical-align: middle;
    }
  }

  .memberships-add-btn.emphasize {
    animation-delay: 0.1s;
    animation-duration: 1s;
    animation-name: shake;
    animation-timing-function: ease-in;
  }

  .due-date a {
    color: $ps-gray-500;
    text-decoration: underline;
  }

  .due-date-value {
    float: right;
    font-weight: normal;
  }

  .checklist-sharing {
    overflow: auto;

    h4 {
      margin-top: 5px;
    }

    .btn {
      margin-bottom: 0;
      width: auto;
    }

    .form-group {
      margin: 50px 0 0;
    }

    .input-group {
      width: 100%;
    }

    .input-group-btn .btn {
      margin-bottom: 0;
    }
  }

  .activities .sentence {
    line-height: 18px;
    margin-bottom: 4px;
    word-wrap: break-word;

    a {
      color: $ps-gray-500;
      text-decoration: underline;
    }
  }

  .activities .alert {
    margin: 0 -12px;
  }

  .activities li {
    border-bottom: 1px solid $ps-gray-50;
    min-height: 32px;
    padding: 10px 0 10px 40px;
    position: relative;
  }

  .activities .subject-avatar {
    left: 0;
    position: absolute;
    top: 12px;
  }

  .activities .sentence-context {
    color: $ps-gray-500;
    font-size: $ps-font-size-xs;
    line-height: 18px;
    word-wrap: break-word;
  }

  ul {
    margin-bottom: 20px;
    width: 100%;

    li a {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
