//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.widgets-container {
  .widgets-message {
    margin: 60px auto 30px;
    max-width: 500px;
    padding: 0 30px;
    text-align: center;
  }

  .task-bar-template-cell {
    padding-right: 5px;
  }

  .approval-note-header {
    margin: 16px auto 8px;
    max-width: 700px;
    padding-left: 30px;
    padding-right: 30px;

    @media screen and (min-width: $ps-screen-2xl-min) {
      padding-left: var(--ps-sizes-8);
      padding-right: var(--ps-sizes-8);
    }
  }

  .approval-rules-widget-container {
    margin: 0 auto 30px;
    max-width: 700px;
    padding: 0 30px;
  }

  .move-menu {
    list-style: none;
  }

  .move-menu-trigger {
    position: absolute;
    right: 5px;
  }

  .move-indicator {
    color: $ps-brand-500;
    left: 11px;
    position: absolute;
    top: 12px;
  }
}

// outside because it's appended to body
.move-menu-label {
  margin-left: 16px;
}
