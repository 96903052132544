//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.plan-top-panel {
  text-align: center;

  &__level {
    color: $ps-gray-500;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 15px;
    padding: 8px 0;
    text-transform: uppercase;
  }

  .cost {
    height: 72px;
    line-height: 59px;

    .integer-part {
      font-size: 48px;
    }

    .decimal-part {
      font-size: 20px;
    }
  }

  .cost-custom {
    font-size: 32px;
  }

  .per {
    color: $ps-gray-400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 8px;
  }

  &__level-free {
    .per {
      display: none;
    }

    .cost {
      color: $ps-gray-400;
    }
  }

  &__level-basic {
    border-top: 5px solid $ps-yellow;
  }

  &__level-standard {
    border-top: 5px solid $ps-green;
  }

  &__level-professional {
    border-top: 5px solid $ps-brand-500;

    .plan-level-pricing-panel--freemium & {
      border-top: 5px solid $ps-green;
    }
  }

  &__level-enterprise {
    border-top: 5px solid $ps-brand-500;
  }

  @media (min-width: $screen-sm-min) {
    &__level-basic {
      border-bottom: 1px solid $ps-gray-50;
    }

    &__level-standard {
      border-bottom: 1px solid $ps-gray-50;
    }

    &__level-professional {
      border-bottom: 1px solid $ps-gray-50;
    }

    &__level-enterprise {
      border-bottom: 1px solid $ps-gray-50;
    }
  }
}
