//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.template-migration-modal {
  margin: 32px;
  text-align: center;

  .updating-checklists {
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0 0;
  }

  .this-update {
    color: $ps-gray-400;
  }

  .got-it-button {
    width: 100px;
  }

  .migration-progress-bar-container {
    position: relative;
  }

  .migration-progress-bar {
    height: 4px;
    margin: 18px 0;
    width: 100%;
  }

  .progress-bar-migration {
    background-color: $ps-brand-500;
  }

  .migration-progress-info {
    color: $ps-gray-600;
    position: relative;
    top: 8px;
    white-space: nowrap;
  }
}
