//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.organization-manage-users {
  .invite-users {
    margin-bottom: 20px;
  }

  .logo-help-text {
    line-height: 1.4;
  }

  .organization-manage-users-table > ul {
    margin-bottom: 10px;
  }

  .users-table-cell {
    padding: 8px 4px;
  }

  .users-table-avatar {
    padding: ps-size(1, 0, 1, 3);
  }

  .users-table-name {
    font-size: $ps-primary-font-size;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .users-table-dropdown {
    font-size: $ps-font-size-xs;
    .btn {
      border-radius: 3px;
      font-size: $ps-font-size-xs;
      height: $ps-input-height;
      line-height: 1.5;
      padding: 5px 10px;
    }
  }

  .users-tab-remove-btn-container {
    padding-left: 0;
  }

  // Extra small devices (phones, 480px and up)
  @media (min-width: $screen-xs-min) {
    .users-tab-table-cell {
      padding: 8px;
    }

    .users-table-avatar {
      padding: 8px;
    }

    .users-table-dropdown {
      font-size: 14px;

      .btn {
        border-radius: 4px;
        font-size: 14px;
        line-height: 1.428571429;
        padding: 6px 12px;
      }
    }
  }
}
