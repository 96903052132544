//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.blvdButton {
  position: relative;
  white-space: nowrap;
}

.disabled {
  pointer-events: none;
}

.regular {
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  font-size: ps-size(2);
  font-weight: bold;
  height: $ps-dashboard-input-height;
  line-height: ps-size(3);
  padding: 6px ps-size(2);
  text-align: center;
}

.regularLeft {
  padding: 6px ps-size(1.5);
  text-align: left;
}

.small {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  font-size: ps-size(1.5);
  font-weight: bold;
  height: ps-size(4);
  letter-spacing: 0;
  line-height: 15px;
  padding: ps-size(0.5, 1.5);
}

.icon {
  margin-right: ps-size(1);
}

@mixin primary($primary, $hover, $disabled) {
  background-color: $primary;
  border-color: $primary;
  color: $ps-white;

  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active {
    background-color: $disabled;
    border-color: $disabled;
  }

  &:hover,
  &:active {
    background-color: $hover;
    border-color: $hover;
  }

  &:focus {
    box-shadow: 0 0 2px 2px $disabled;
    outline: none;
  }
}

@mixin outline($primary, $disabled) {
  background-color: $ps-white;
  border-color: $primary;
  color: $primary;

  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active {
    background-color: $ps-white;
    border-color: $disabled;
    color: $disabled;
  }

  &:active,
  &:focus,
  &:hover {
    background-color: $primary;
    color: $ps-white;
  }

  &:focus {
    box-shadow: 0 0 2px 2px $disabled;
    outline: none;
  }
}

@mixin transparent($primary, $disabled) {
  background-color: $ps-white;
  border: 0;
  color: $primary;

  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active {
    background-color: $ps-white;
    border: 0;
    color: $disabled;
  }

  &:active,
  &:focus,
  &:hover {
    background-color: $primary;
    color: $ps-white;
  }

  &:focus {
    box-shadow: 0 0 2px 2px $disabled;
    outline: none;
  }
}

.actionPrimary {
  @include primary($ps-green, $ps-green-hover, $ps-green-disabled);
}

.brandPrimary {
  @include primary($ps-blue, $ps-blue-hover, $ps-blue-disabled);
}

.warningPrimary {
  @include primary($ps-yellow, $ps-yellow-hover, $ps-yellow-disabled);
}

.dangerPrimary {
  @include primary($ps-red, $ps-red-hover, $ps-red-disabled);
}

.actionOutline {
  @include outline($ps-green, $ps-green-disabled);
}

.brandOutline {
  @include outline($ps-blue, $ps-blue-disabled);
}

.warningOutline {
  @include outline($ps-yellow, $ps-yellow-disabled);
}

.dangerOutline {
  @include outline($ps-red, $ps-red-disabled);
}

.actionTransparent {
  @include transparent($ps-green, $ps-green-disabled);
}

.brandTransparent {
  @include transparent($ps-blue, $ps-blue-disabled);
}

.warningTransparent {
  @include transparent($ps-yellow, $ps-yellow-disabled);
}

.dangerTransparent {
  @include transparent($ps-red, $ps-red-disabled);
}

.secondaryAsh {
  background-color: $ps-gray-50;
  border: 1px solid $ps-gray-300;
  color: $ps-gray-600;
  font-weight: normal;

  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active {
    background-color: $ps-gray-200;
    border-color: $ps-gray-300;
    color: $ps-gray-400;
  }

  &:active,
  &:focus,
  &:hover {
    background-color: $ps-gray-100;
    border-color: $ps-gray-300;
  }

  &:focus {
    box-shadow: 0 0 2px 2px $ps-gray-200;
    outline: none;
  }
}

.secondaryWhite {
  background-color: $ps-white;
  border: 1px solid $ps-gray-300;
  color: $ps-gray-600;
  font-weight: normal;

  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active {
    background-color: $ps-gray-200;
    border-color: $ps-gray-300;
    color: $ps-gray-400;
  }

  &:active,
  &:focus,
  &:hover {
    background-color: $ps-gray-100;
    border-color: $ps-gray-300;
  }

  &:focus {
    box-shadow: 0 0 2px 2px $ps-gray-200;
    outline: none;
  }
}

.secondaryTransparent {
  background-color: $ps-white;
  border: 0;
  color: $ps-gray-600;
  font-weight: normal;

  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active {
    background-color: $ps-white;
    color: $ps-gray-400;
  }

  &:active,
  &:focus,
  &:hover {
    background-color: $ps-gray-100;
  }

  &:focus {
    box-shadow: 0 0 2px 2px $ps-gray-200;
    outline: none;
  }
}

.hideOnLoading {
  opacity: 0;
}

.loadingIconContainer {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.semanticButton {
  background: transparent;
  border: 0;
  color: currentColor;
  font-size: inherit;
  padding: 0;
}
