//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.text-widget-toolbar {
  margin-bottom: ps-size(1);
  padding: 0;

  .btn-toolbar {
    margin-bottom: 0;

    .btn-group {
      margin: 0;

      .btn {
        min-width: ps-size(5);
        padding: ps-size(1, 1.5);
      }
    }

    .dropdown.btn-group .btn:nth-child(1) .caret {
      margin: 0;
      padding: 0 !important;

      &:after {
        font-size: 0.75em;
      }
    }
  }

  .dropdown-menu {
    .btn-transparent-info {
      min-width: ps-size(5);
    }

    > li > a {
      padding-left: ps-size(2);
      padding-right: ps-size(2);

      &:hover {
        color: inherit;
      }
    }
  }

  .btn-group .btn-transparent-info {
    color: rgba(60, 60, 60, 0.5);
    padding: ps-size(0.5);

    .custom-font-icon {
      border-bottom: 3px solid rgba(60, 60, 60, 0.5);
      padding-bottom: 1px;
    }

    .btn-transparent:hover {
      background-color: $ps-gray-100;
      color: $ps-gray-700;

      .custom-font-icon {
        border-bottom: 3px solid $ps-gray-700;
      }
    }

    &:hover {
      background-color: $ps-brand-200;
    }

    &.active {
      background-color: $ps-brand-200;
    }
  }

  .color-dropdown {
    min-width: 152px;
  }

  .color-dropdown-inner {
    padding: 0 5px;
  }

  .color-dropdown-label {
    color: $ps-gray-400;
    display: block;
    font-size: 12px;
    font-variant: all-small-caps;
    font-weight: 600;
  }

  .color-dropdown-btn.btn {
    margin: 3px;
  }

  .style-span {
    display: block;
  }

  .style-dropdown {
    height: auto;
    max-height: 215px;
    max-width: 500px;
    overflow-x: hidden;
    z-index: 3;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 11px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      border: 2px solid $ps-white;
      border-radius: 8px;
    }
  }

  .style-normal {
    line-height: 2;
  }

  .style-heading1 {
    font-family: inherit;
    font-size: 36px;
    font-weight: 500;
  }

  .style-heading2 {
    font-family: inherit;
    font-size: 30px;
    font-weight: 500;
  }

  .style-heading3 {
    font-family: inherit;
    font-size: 24px;
    font-weight: 500;
  }

  .style-heading4 {
    font-family: inherit;
    font-size: 18px;
    font-weight: 500;
  }

  .style-code {
    background-color: $ps-gray-50;
    border-radius: 4px;
    color: #c7254e;
    display: inline-block;
    font-size: 90%;
    margin: 12.5px 0;
    padding: 2px 4px;
  }

  .style-formatted {
    background-color: $ps-gray-50;
    border: 1px solid $ps-gray-300;
    border-radius: 4px;
    font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size: 13px;
    margin: 3.5px 0;
    padding: 9.5px;
    width: 100%;
  }

  .style-formatted,
  .style-success,
  .style-info,
  .style-warning,
  .style-danger {
    // Overrides classes from template-text-widget.scss file
    margin-bottom: 2.5px;
    margin-top: 2.5px;
  }

  .c {
    border-radius: 3px;
    height: 25px;
    padding: 0;
    width: 25px;
  }

  .text-color-1 {
    background-color: $ps-gray-600;
  }

  .text-color-2 {
    background-color: #b3b3b3;
  }

  .text-color-3 {
    background-color: #e15951;
  }

  .text-color-4 {
    background-color: #007db6;
  }

  .text-color-5 {
    background-color: #00b4a6;
  }

  .highlight-1 {
    background-color: #fce8a7;
  }

  .highlight-2 {
    background-color: #ffd0b9;
  }

  .highlight-3 {
    background-color: #b3f0c1;
  }

  .highlight-4 {
    background-color: #b4e5fa;
  }

  .highlight-5 {
    background: url('images/no-highlight.svg') no-repeat center;
    border: 1px solid $ps-gray-50;
  }
}

.widget-outer {
  &:hover {
    .text-widget-toolbar .btn-group .btn-transparent-info {
      color: rgba(60, 60, 60, 1);

      .custom-font-icon {
        border-bottom: 3px solid rgba(60, 60, 60, 1);
      }
    }
  }
}
