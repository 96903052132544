//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.pop-box-new {
  background-color: $btn-default-bg;
  border: 1px solid $btn-default-border;
  border-radius: 4px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  display: none;
  max-height: calc(100% - 10px);
  overflow-y: visible;
  position: fixed;
  width: 300px;
  z-index: 1051;

  .pop-box-header {
    height: 36px;
    position: relative;
    text-align: center;
  }

  .pop-box-header-title {
    border-bottom: 1px solid $ps-gray-100;
    color: $ps-gray-500;
    font-weight: $ps-font-style-medium;
    line-height: 36px;
    margin: 0 15px;
    overflow: hidden;
    padding: ps-size(0, 4);
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .pop-box-header-close-btn {
    color: $ps-gray-500;
    font-size: 18px;
    line-height: 36px;
    padding-right: 15px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .pop-box-header-close-btn:hover {
    text-decoration: none;
  }

  .pop-box-content {
    padding: 10px 15px;
  }

  .pop-box-content-list-item-group {
    list-style-type: none;
    margin: 0 -10px;
    padding: 0;
  }

  .pop-box-content-list-item:hover {
    background-color: $dropdown-link-hover-bg;

    a {
      color: $dropdown-link-hover-color;
      text-decoration: none;
    }
  }

  .pop-box-content-list-item > a {
    color: $dropdown-link-color;
    display: block;
    padding: 6px 10px;
  }
}
