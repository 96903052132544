//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.widgets-container {
  // template-file-widget-preview styles copied from chakra output of template-file-widget-preview.stories
  .widget-thumbnail-wrapper {
    align-items: stretch;
    border-style: none;
    display: flex;
    flex-direction: column;

    & > *:not(style) ~ *:not(style) {
      margin-bottom: var(--ps-space-0);
      margin-inline-end: var(--ps-space-0);
      margin-inline-start: var(--ps-space-0);
      margin-top: var(--ps-space-3);
    }
  }

  ps-checklist-file-form-field .widget-thumbnail-wrapper {
    height: 80px;
    position: relative;

    & > *:not(style) ~ *:not(style) {
      margin-bottom: var(--ps-space-4);
      margin-top: var(--ps-space-0);
    }
  }

  .widget-thumbnail-wrapper__details-container {
    align-items: center;
    border-color: var(--ps-colors-gray-200);
    border-radius: var(--ps-radii-base);
    border-style: solid;
    border-width: var(--ps-borderWidths-px);
    display: flex;
    height: var(--ps-sizes-10);
    justify-content: flex-start;
    padding-bottom: var(--ps-space-2);
    padding-top: var(--ps-space-2);
    position: relative;

    @media screen and (min-width: 30em) {
      height: var(--ps-sizes-13);
    }
  }

  .widget-thumbnail-wrapper__left-element {
    align-items: center;
    background: var(--ps-colors-brand-600);
    border: 0;
    border-bottom-left-radius: var(--ps-radii-base);
    border-bottom-right-radius: 0;
    border-top-left-radius: var(--ps-radii-base);
    border-top-right-radius: 0;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    height: var(--ps-sizes-10);
    justify-content: center;
    left: calc(var(--ps-space-px) * -1);
    position: absolute;
    top: calc(var(--ps-space-px) * -1);
    width: var(--ps-sizes-10);

    @media screen and (min-width: 30em) {
      height: var(--ps-sizes-13);
      width: var(--ps-sizes-13);
    }

    p {
      color: var(--ps-colors-white);
      font-size: var(--ps-fontSizes-sm);
      font-weight: var(--ps-fontWeights-semibold);
      letter-spacing: inherit;
      line-height: var(--ps-lineHeights-short);
      margin: initial;
      text-transform: uppercase;
    }
  }

  .widget-thumbnail-wrapper__left-element-spacer {
    flex-shrink: 0;
    width: var(--ps-sizes-10);

    @media screen and (min-width: 30em) {
      width: var(--ps-sizes-13);
    }
  }

  .widget-thumbnail-wrapper__details {
    align-items: flex-start;
    border-style: none;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    padding-inline-end: var(--ps-space-4);
    padding-inline-start: var(--ps-space-4);

    & > *:not(style) ~ *:not(style) {
      margin-bottom: var(--ps-space-0);
      margin-inline-end: var(--ps-space-0);
      margin-inline-start: var(--ps-space-0);
      margin-top: var(--ps-space-0);
    }
  }

  .widget-thumbnail-wrapper__filename {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: var(--ps-colors-gray-700);
    display: -webkit-box;
    font-size: var(--ps-fontSizes-sm);
    font-style: normal;
    letter-spacing: inherit;
    line-height: var(--ps-lineHeights-short);
    margin: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: inherit;
  }

  .widget-thumbnail-wrapper__file-size {
    color: var(--ps-colors-gray-500);
    font-size: var(--ps-fontSizes-xs);
    letter-spacing: inherit;
    line-height: var(--ps-lineHeights-short);
    margin: initial;
    text-transform: inherit;
  }

  .widget-thumbnail-wrapper__spacer {
    align-self: stretch;
    flex: 1;
    justify-self: stretch;
  }

  .widget-thumbnail-wrapper__actions {
    align-items: center;
    display: inline-flex;
    flex-shrink: 0;
    padding-inline-end: var(--ps-space-3);
    padding-inline-start: var(--ps-space-3);

    & > *:not(style) ~ *:not(style) {
      margin-inline-start: var(--ps-space-1);
    }
  }

  .widget-thumbnail-wrapper__action {
    align-items: center;
    appearance: none;
    border-radius: var(--ps-radii-base);
    color: inherit;
    display: inline-flex;
    font-size: var(--ps-fontSizes-sm);
    font-weight: var(--ps-fontWeights-bold);
    height: var(--ps-sizes-8);
    justify-content: center;
    line-height: var(--ps-lineHeights-taller);
    min-width: var(--ps-sizes-8);
    outline: 2px solid transparent;
    outline-offset: 2px;
    padding: var(--ps-space-0);
    padding-inline-end: var(--ps-space-3);
    padding-inline-start: var(--ps-space-3);
    position: relative;
    transition-duration: var(--ps-transition-duration-normal);
    transition-property: var(--ps-transition-property-common);
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;

    &:hover,
    &:focus {
      background: var(--ps-colors-gray-100);
    }

    i {
      color: var(--ps-colors-gray-500);
      display: inline-block;
      flex-shrink: 0;
      height: 16px !important;
      line-height: 1em;
      width: 16px !important;
    }
  }

  .widget-thumbnail-wrapper__action--edit.widget-thumbnail-wrapper__action--editing {
    i {
      color: var(--ps-colors-gray-300);
    }
  }

  .widget-thumbnail-wrapper__description-input {
    appearance: none;
    background: inherit;
    border: 1px solid;
    border-color: inherit;
    border-radius: var(--ps-radii-base);
    font-size: var(--ps-fontSizes-md);
    height: var(--ps-sizes-10);
    min-width: var(--ps-sizes-0);
    outline: 2px solid transparent;
    outline-offset: 2px;
    padding-inline-end: var(--ps-space-4);
    padding-inline-start: var(--ps-space-4);
    position: relative;
    transition-duration: var(--ps-transition-duration-normal);
    transition-property: var(--ps-transition-property-common);
    width: 100%;
  }
}
