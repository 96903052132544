//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.task-permission-selector {
  width: 100%;

  .nav-tabs {
    margin-bottom: ps-size(0.5);
    margin-top: 0;
  }

  button.btn-dropdown-trigger {
    padding-right: ps-size(1.5);
    text-align: left;
    width: 100%;
  }

  .dropdown-menu {
    left: 0;
    margin-top: ps-size(0.5);
    padding-bottom: 0;
    padding-top: 0;
    width: 268px;

    ul li a {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .dropdown-menu-bulk {
    bottom: 35px;
    height: 340px;
    top: unset;
  }

  .dropdown-scrollable {
    height: auto;
    list-style: none;
    margin-top: ps-size(0.5);
    max-height: 300px;
    min-width: 250px;
    overflow-x: hidden;
    padding: 0 10px ps-size(0.5);
    position: relative;

    //add back bootstrap dropdown styles
    section > li > a {
      clear: both;
      color: $ps-gray-600;
      display: block;
      line-height: 1.428571429;
      padding: 3px 20px;
      white-space: nowrap;
    }

    section > li > a:hover,
    section > li > a:focus {
      background-color: $ps-gray-50;
      color: $ps-gray-700;
      text-decoration: none;
    }

    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      background-color: $ps-brand-500;
      color: $ps-white;
      outline: 0;
      text-decoration: none;
    }

    .no-results {
      padding-top: 8px;
      text-align: center;
    }
  }

  .dropdown-inner-search {
    padding: 8px;

    button {
      background-color: transparent;
      border: 0;
      color: $ps-gray-400;
      right: 4px;
      top: 12px;
    }
  }
}
