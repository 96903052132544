//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.add-stop-task {
  .upgrade-title {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .explanation-box {
    padding: 15px 0 30px;

    p {
      margin-top: 10px;
    }
  }

  .upgrade-description {
    margin-bottom: 30px;
  }

  .upgrade-btn-box {
    padding-bottom: 15px;
  }

  .upgrade-btn-box:first-of-type {
    padding-bottom: 30px;
  }
}
