//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.membership-list-item {
  border-radius: 4px;
  display: block;
  padding: 5px 0;
  position: relative;

  .image {
    float: left;
  }

  .details {
    margin: 0 38px;

    .username {
      font-size: 14px;
      line-height: 16px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .email {
      font-size: 12px;
      line-height: 16px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .remove-btn {
    float: right;
    height: 32px;
    line-height: 32px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 5px;
    width: 32px;
  }
}

.membership-list-item.selectable {
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 1px;
  padding: 5px 5px 4px;

  &:hover,
  &:focus {
    background-color: $ps-brand-500;
    color: $ps-white;

    .email {
      background-color: $ps-brand-500;
      color: $ps-white;
    }
  }

  .details {
    margin: 0 42px;
  }
}
