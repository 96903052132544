//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../../../../styles/boulevard-theme/utilities/size';
@import '../../../../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.title {
  border-bottom: 1px solid $ps-gray-100;
  color: $ps-gray-500;
  font-size: $ps-font-size-xs;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: ps-size(2);
  padding: ps-size(2) 0 ps-size(1);
  text-align: center;
  text-transform: uppercase;
}

.content {
  padding: ps-size(2) ps-size(2) 0;
}

.footer {
  border-top: 1px solid $ps-gray-100;
  padding: ps-size(3);
}

.overdueTaskItem {
  margin-bottom: ps-size(3);
}

.taskName {
  color: $ps-gray-700;
  font-size: $ps-primary-font-size;
  height: ps-size(3);
  line-height: ps-size(3);
  margin-bottom: ps-size(1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.maskedTaskName {
  color: $ps-gray-500;
  font-size: $ps-font-size-sm;
}

.taskInfo {
  align-items: center;
  display: flex;
}

.assignees {
  left: 70px;
  position: absolute;
}

.dueDate {
  background-color: $ps-red-100;
  border-radius: 4px;
  color: $ps-red;

  font-size: $ps-font-size-xs;
  font-weight: bold;
  height: ps-size(3);

  line-height: ps-size(3);
  text-align: center;
  width: 45px;
}

.leftCount {
  color: $ps-gray-500;
  font-size: $ps-font-size-xs;
  font-weight: 500;
  height: ps-size(2);
  line-height: ps-size(2);
  margin: 0;
}

.button {
  width: 100%;
}
