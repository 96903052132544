//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.plan-select-button {
  padding: 0 16px;

  button {
    background-color: $ps-white;
    border-color: $ps-gray-300;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    margin: 16px 0;
    padding: 4px;
    width: 100%;

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: $ps-gray-50;
      color: $ps-gray-400;
    }
  }

  &__selected-level-basic {
    border-color: $ps-yellow;
    color: $ps-yellow;
  }

  &__selected-level-standard {
    border-color: $ps-green;
    color: $ps-green;
  }

  &__selected-level-professional {
    border-color: $ps-brand-500;
    color: $ps-brand-500;

    .plan-level-pricing-panel--freemium & {
      border-color: $ps-green;
      color: $ps-green;
    }
  }

  &__selected-level-enterprise {
    border-color: $ps-purple;
    color: $ps-purple;
  }
}
