//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../styles/boulevard-theme/utilities/size';
@import '../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.container {
  border: 1px solid $ps-gray-200;
  border-radius: 4px;
  box-shadow: 0 2px 4px $ps-gray-200;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: ps-size(33);
  width: ps-size(38);

  &:hover {
    background-color: $ps-gray-50;
  }

  img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: 150px;
    object-fit: cover;
  }
}

.textContent {
  padding: ps-size(2);
  .templateName {
    color: $ps-gray-700;
    display: block;
    font-weight: 500;
    margin-bottom: ps-size(1);
    margin-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .templateDescription {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: $ps-gray-500;
    display: -webkit-box;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
