//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.required-option {
  align-items: flex-start;
  display: flex;

  .checkbox-text {
    cursor: default;
    font-size: 12px;
    height: 36px;
    line-height: 16px;
    outline: none;
    overflow: hidden;
    padding: 10px 6px;
  }

  .checkbox {
    background-color: $ps-white;
    border: 1px solid $ps-checkbox-border-color;
    border-radius: 4px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: block;
    min-height: ps-size(2);
    padding-left: 1px;
    padding-top: 1px;
    width: ps-size(2);

    &.required {
      background-color: $ps-brand-500;
      border-color: $ps-brand-500;

      .checkbox-checkmark {
        display: block;
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .checkbox-checkmark {
    color: $ps-white;
    display: none;
    font-size: 1.2rem;
  }
}
