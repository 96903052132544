//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

//used for directives/ checklist-assigner.html and checklist-task-assigner.html
.checklist-task-assigner {
  align-items: center;
  display: flex;

  .task-assign-btn {
    padding: 6px;
    width: 34px;
  }

  .assigner-pop-box {
    display: inline-block;
  }

  @media (min-width: $screen-sm-min) {
    .task-assign-btn {
      padding: 6px 12px;
      width: inherit;
    }
  }
}

.checklist-task-assigner-my-work-ga {
  align-items: center;
  display: flex;
  height: 32px;

  .assigner-container {
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    height: 32px;
    overflow: hidden;
  }

  .task-assign-btn {
    align-items: center;
    display: flex;
    height: 32px;
    justify-content: center;
    padding-top: 1px;
    width: 32px;
  }

  .assignments-container {
    border-left: 1px solid var(--ps-colors-gray-200);
    height: 32px;
    min-width: 32px;
  }

  @media (min-width: $screen-sm-min) {
    .task-assign-btn {
      padding: 6px 12px;
      width: auto;
    }
  }
}
