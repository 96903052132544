//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../../../../styles/boulevard-theme/utilities/size';
@import '../../../../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.checklistDashboardSearchFilter {
  input {
    background-color: $ps-white;
    border: 1px solid $ps-gray-300;
    box-shadow: none;
    ::placeholder {
      color: $ps-gray-400;
    }

    @media (min-width: $screen-sm-min) {
      width: 225px;
    }

    @media (min-width: $screen-lg-min) {
      width: 366px;
    }

    &:focus {
      box-shadow: none;
    }
  }

  i {
    color: $ps-gray-500;
  }
}
