//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

@mixin avatar-size($size) {
  height: $size;
  width: $size;
}

@mixin avatar-child-position($size) {
  bottom: $size;
  right: $size;
}

.avatar {
  display: inline-block;
  position: relative;
}

.avatarExtraSmall {
  @include avatar-size(ps-size(2));
}

.avatarSmall {
  @include avatar-size(ps-size(3));
}

.avatarNormal {
  @include avatar-size(ps-size(4));
}

.avatarMedium {
  @include avatar-size(ps-size(5));
}

.avatarLarge {
  @include avatar-size(ps-size(6));
}

.img {
  background-color: $ps-gray-50;
  border: 1px solid $ps-white;
  border-radius: 50%;
}

.imgExtraSmall {
  @include avatar-size(ps-size(2));
}

.imgSmall {
  @include avatar-size(ps-size(3));
}

.imgNormal {
  @include avatar-size(ps-size(4));
}

.imgMedium {
  @include avatar-size(ps-size(5));
}

.imgLarge {
  @include avatar-size(ps-size(6));
}

.initials {
  align-items: center;
  background-color: $ps-gray-300;
  border: 1px solid $ps-white;
  border-radius: 50%;
  color: $ps-white;
  display: flex !important;
  font-weight: 500;
  justify-content: center;
  text-align: center;
}
.initialsExtraSmall {
  @include avatar-size(ps-size(2));
  font-size: calc(#{$ps-font-size-xs} - 1px);
}

.initialsSmall {
  @include avatar-size(ps-size(3));
  font-size: calc(#{$ps-font-size-xs} - 1px);
}

.initialsNormal {
  @include avatar-size(ps-size(4));
  font-size: $ps-font-size-xs;
}

.initialsMedium {
  @include avatar-size(ps-size(5));
  font-size: $ps-primary-font-size;
}

.initialsLarge {
  @include avatar-size(ps-size(6));
  font-size: $ps-primary-font-size;
}

.avatarBadge {
  @include avatar-child-position(0);
  font-size: 8px !important;
  line-height: 0.9 !important;
  padding: 3px 5px !important;
  position: absolute !important;
  top: auto !important;
}

.avatarBadgeSmall {
  @include avatar-child-position(ps-size(-1));
}

.avatarBadgeNormal {
  @include avatar-child-position(ps-size(-0.5));
}

.avatarBadgeMedium {
  @include avatar-child-position(ps-size(0));
}

.avatarBadgeLarge {
  @include avatar-child-position(ps-size(0));
}

.unconfirmed {
  @include avatar-child-position(0);
  @include avatar-size(ps-size(2));
  color: $btn-warning-bg;
  display: block;
  position: absolute;
  text-align: center;
}

.unconfirmedSmall {
  @include avatar-child-position(ps-size(-0.5));
  font-size: 10px;
}

.unconfirmedNormal {
  @include avatar-child-position(ps-size(-0.5));
  font-size: $ps-font-size-xs;
}

.unconfirmedMedium {
  @include avatar-child-position(ps-size(0));
  font-size: $ps-font-size-xs;
}

.unconfirmedLarge {
  @include avatar-child-position(ps-size(0));
  font-size: $ps-font-size-xs;
}
