//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.field-snippet {
  page-break-inside: avoid;

  .help-block {
    font-size: $ps-font-size-sm;
  }

  label:empty::before {
    color: $ps-gray-400;
    content: '(' attr(data-placeholder) ')';
  }

  .merge-tag-input-group {
    position: relative;

    .form-control {
      padding-right: ps-size(5);
    }

    .merge-tag-selector-trigger {
      position: absolute;
      right: 0;
      top: 0;

      .btn {
        border: 0;
        color: $ps-gray-400;
        margin: 3px;
        padding: 4px 9px;
      }
    }

    &:hover {
      .btn {
        background-color: #e6e6e6;
      }
    }
  }

  .field-snippet--dashed {
    @media screen and (min-width: $ps-screen-sm-min) {
      .help-block {
        text-align: right;
      }
    }

    .form-control,
    .input-group-addon {
      border: 1px dashed $ps-gray-300;
    }
  }
}
