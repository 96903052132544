//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.auth-reskin {
  // Bootstrap fixes to remove all padding & overflow
  padding: 0;

  .row {
    margin: 0;
  }

  .col-md-6 {
    padding: 0;
  }
  // /Bootstrap fixes

  &--spacing {
    > * {
      margin-top: ps-size(3);

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .left-panel {
    @extend .auth-reskin--spacing;
    @extend .flex-centered;
    background: linear-gradient(147.64deg, $ps-brand-500 25.19%, $ps-purple-500 124.7%);
    background-blend-mode: multiply;
    flex-direction: column;
    padding: 2em;
    @media (min-width: $screen-md-min) {
      height: 100vh;
    }

    &--opacity {
      opacity: 0.7;
    }

    &__testimonials {
      @extend .flex-centered;
      list-style-type: none;
      margin: 0;
      min-width: 430px;
      padding: 0;

      > li {
        @extend .flex-centered;
        color: $ps-white;
        display: flex;
        justify-content: center;
        width: 20%;
      }
    }

    &__ps-logo {
      width: 150px;
    }

    &__subtitle {
      color: $ps-white;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0;
      text-align: center;
      text-transform: inherit;
      @media (min-width: $screen-md-min) {
        font-size: 36px;
        line-height: 48px;
        max-width: ps-size(71);
      }
    }

    &__subsubtitle {
      color: $ps-white;
      font-size: $ps-font-size-xs;
      font-weight: 700;
      letter-spacing: 0.167em;
      text-transform: uppercase;
    }
  }

  .right-panel {
    &__container {
      @extend .flex-centered;
      @media (min-width: $screen-md-min) {
        height: 100vh;
      }
      padding: ps-size(6) ps-size(3);
    }

    &__content {
      @extend .flex-centered;
      color: $ps-gray-500;
      flex-direction: column;
      height: 100%;
      max-width: ps-size(52);
      min-width: ps-size(45);

      @media (max-width: $screen-xs-max) {
        padding-left: ps-size(2);
        padding-right: ps-size(2);
      }
    }

    &__password-label-container {
      align-items: baseline;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &--fullwidth {
      width: 100%;
    }

    &__main {
      @extend .flex-centered;
      @extend .auth-reskin--spacing;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;

      ps-sign-in-with-provider-button {
        width: 100%;
      }
    }

    &__title {
      color: $ps-black;
      font-size: 1.6rem;
      line-height: unset;
      margin-bottom: 0;

      &--large {
        font-size: $ps-font-size-xl;
      }
    }

    &__divider {
      @extend .flex-centered;
      align-items: center;
      width: 100%;

      hr {
        border-top: 1px solid $ps-gray-200;
        flex-grow: 1;
        margin: 0;
      }

      p {
        color: $ps-gray-500;
        font-size: $ps-font-size-xs;
        font-weight: 700;
        margin: 0 1em;
        text-transform: uppercase;
      }
    }

    &__button {
      border: 0;
      border-radius: 0.4rem;
      width: 100%;

      &--primary {
        background: $ps-brand-500;
        color: $ps-white;

        &:hover {
          background: $ps-brand-600;
        }
      }

      &--google {
        @extend .flex-centered;
        background: $ps-white;
        border: 2px solid $ps-gray-200;
        color: $ps-gray-500;
        height: ps-size(6);
        margin-top: ps-size(3);

        &:hover {
          background: $ps-gray-100;
        }

        img {
          margin-right: ps-size(1);
        }
      }
    }

    &__disclaimer {
      color: $ps-gray-400;
      font-size: $ps-font-size-xs;
    }
  }
}

.auth {
  .sign-up-steps .step {
    color: $ps-gray-400;
    margin-bottom: 10px;
    text-align: center;
  }

  .sign-up-steps .active {
    color: $ps-gray-700;
  }

  .sign-up-steps .progress {
    height: 5px;
    opacity: 0.8;
  }

  .sign-up-steps .progress-bar {
    box-shadow: none;
  }

  .face-pile .face {
    text-align: center;
  }

  .side {
    margin-bottom: 50px;
  }

  .create-account-btn {
    margin-top: 25px;
  }

  .provider-side .btn {
    margin-bottom: 20px;
    position: relative;
    width: 100%;
  }

  .google-img {
    border-radius: 4px 0 0 4px;
    border-right: 1px solid $btn-primary-border;
    height: 46px;
    left: 0;
    position: absolute;
    top: 0;
    width: 46px;
  }

  .provider-side .btn:active img,
  .provider-side .btn:focus img,
  .provider-side .btn:hover img {
    border-color: $ps-brand-700;
  }

  h2 {
    color: $ps-gray-500;
    margin-bottom: 30px;
  }

  .instructions {
    margin: 20px 0 30px;
  }

  .face-pile img {
    border-radius: 4px;
    margin-bottom: 20px;
  }

  input {
    margin-bottom: 20px;
  }

  .input-disclaimer {
    margin-bottom: 15px;
  }

  .checkbox {
    margin: 20px 0 30px;
  }

  .menu {
    text-align: center;
  }

  .disclaimer {
    font-size: 12px;
    margin-bottom: 15px;
  }
}

// Responsive

// Small devices (tablets, 768px and up) //
@media (min-width: $screen-sm-min) {
}

// Medium devices (desktops, 992px and up) //
@media (min-width: $screen-md-min) {
  .container {
    width: 1000px;
  }

  .auth {
    .side {
      padding: 0 50px;
    }
  }
}

// Large devices (large desktops, 1280px and up) //
@media (min-width: $screen-lg-min) {
}
