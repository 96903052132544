//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.widget-image {
  page-break-inside: avoid;
  text-align: center;

  figure {
    display: inline-block;
    width: 100%;

    a,
    img {
      display: block;
    }
  }

  figcaption {
    color: $ps-gray-400;
    font-size: 12px;
    font-style: normal;
    padding: 6px 0 0;
    position: relative;
    text-align: left;
    width: 100%;
  }

  img {
    display: inline-block;
    margin: 0 auto;
  }

  .fileinput-image__placeholder {
    align-items: center;
    aspect-ratio: 16 / 9; // sass-lint:disable-line no-misspelled-properties sass-lint has no support to aspect-ratio
    background-color: var(--ps-colors-brand-100);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    position: relative;

    .fa.fa-image {
      color: var(--ps-colors-white);
      font-size: 100px;

      @media (min-width: $screen-md-min) {
        font-size: 160px;
      }
    }

    .fileinput-image__add {
      align-items: center;
      background-color: var(--ps-colors-brand-500);
      border-radius: 4px;
      color: #fff;
      display: flex;
      font-size: 12px;
      height: 40px;
      justify-content: center;
      position: absolute;
      right: 16px;
      top: 16px;
      width: 40px;

      @media (min-width: $screen-md-min) {
        font-size: 18px;
        height: 50px;
        width: 50px;
      }

      .fa.fa-plus {
        color: var(--ps-colors-white);
      }
    }
  }

  @media print {
    [href]::after {
      content: none;
    }
  }
}
