//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../../../styles/boulevard-theme/utilities/size';
@import '../../../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.task-templates-select {
  max-height: 438px;
  padding-top: 10px;
  width: 100%;

  .tasks-select-list-part {
    display: inline-block;
    max-height: 327px;
    overflow-y: auto;
    width: 100%;
  }

  .tasks-selector-list {
    height: 100%;
    list-style: none;
    margin: 0;
    overflow-x: hidden;
    padding-left: 0;
    width: 100%;

    > li > a {
      clear: both;
      color: $ps-gray-600;
      display: block;
      overflow: hidden;
      padding: 3px 15px 3px 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      &.heading {
        font-weight: bold;
        padding-left: 0;
      }

      &.disabled,
      &.disabled:hover,
      &.disabled:focus {
        color: $ps-gray-200;
        cursor: not-allowed;
        text-decoration: none;

        .tasks-select-list-checkbox {
          background-color: $ps-gray-50;

          .fa-check {
            color: $ps-gray-50;
          }
        }
      }

      &.active,
      &.active:hover,
      &.active:focus {
        .tasks-select-list-checkbox {
          background-color: $ps-gray-300;

          .fa-minus {
            color: $ps-gray-400;
          }
        }
      }
    }

    > li > a:hover,
    > li > a:focus {
      background-color: $ps-gray-50;
      color: $ps-gray-700;
      text-decoration: none;
    }

    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      background-color: $ps-brand-500;
      color: $ps-white;
      outline: 0;
      text-decoration: none;
    }
  }

  .tasks-select-list-title {
    clear: both;
    color: $ps-gray-400;
    display: block;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.428571429;
    padding: 3px 0;
    white-space: nowrap;
  }

  .tasks-select-list-checkbox {
    align-items: center;
    background-color: $ps-white;
    border: 1px solid $ps-checkbox-border-color;
    border-radius: 4px;
    display: inline-flex;
    height: 20px;
    justify-content: center;
    margin-right: 4px;
    width: 20px;

    &.checked {
      background-color: $ps-brand-500;
      border-color: $ps-brand-500;
    }

    .fa-check {
      color: $ps-white;
      font-size: 16px;
    }
  }

  .tasks-select-btn-group {
    border-top: 1px solid $ps-gray-300;
    height: 41px;
    overflow: hidden;
    padding: 5px;

    .btn-clear {
      margin-left: 5px;
    }
  }

  .search {
    display: block;
    height: 34px;
    margin-bottom: 10px;

    .icon {
      background-color: transparent;
      border: 0;
      color: $ps-gray-400;
      display: block;
      height: 34px;
      line-height: 34px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 34px;
      z-index: 2;
    }
  }

  // Small devices (tablets, 768px and up)
  @media (min-width: $screen-sm-min) {
  }

  // Medium devices (desktops, 992px and up)
  @media (min-width: $screen-md-min) {
  }

  // Large devices (large desktops, 1280px and up)
  @media (min-width: $screen-lg-min) {
  }
}
