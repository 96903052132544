//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.statusbar {
  align-content: center;
  align-items: stretch;
  background-color: $ps-gray-50;
  display: flex;
  flex-direction: column;
  height: $ps-navbar-offset;
  overflow: hidden;
  position: fixed;
  top: $ps-navbar-offset;
  width: 100%;
  z-index: 1030;

  .status-container {
    color: $ps-white;
    display: flex;
    justify-content: space-between;
    margin-bottom: auto;
    margin-top: auto;
    position: relative;
    text-align: center;

    .left {
      width: 70px;
    }

    .middle {
      flex-grow: 1;
    }

    .right {
      width: 70px;
    }

    @media (max-width: $ps-screen-md-min) {
      .left {
        display: none;
      }
      .middle {
        width: 100%;
      }
      .right {
        position: absolute;
        right: -20px;
        top: 9px;
      }
    }

    img {
      vertical-align: baseline;
    }

    .status-info {
      display: inline-block;
      margin: 0;

      h3 {
        font-size: 22px;
        margin: 0;
        position: relative;
      }

      p {
        margin: 0;
        position: relative;
        top: -4px;
      }

      small {
        font-size: 60%;
        position: relative;
        top: -17px;
      }

      @media (max-width: $ps-screen-md-min) {
        padding-left: 2.4em;
        padding-right: 2.4em;
        width: 100%;

        h3 {
          font-size: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          top: 2px;
          white-space: nowrap;
          width: 100%;
        }
        p {
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          top: -3px;
          white-space: nowrap;
          width: 100%;
        }
      }
    }
  }

  &.archived {
    background-color: #f0ad4e;
  }

  &.completed {
    background-color: #1bc98e;
  }
  &.ms-teams {
    top: 0;
  }

  .print-btn {
    margin-bottom: auto;
    margin-left: auto;
    margin-top: auto;

    a {
      border: 0;
      color: $ps-white;
      font-size: 16px;

      &.completed {
        background-color: #35b08b;
      }

      &.archived {
        background-color: #cd9342;
      }
    }
  }
}
