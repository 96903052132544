//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../../../../../styles/boulevard-theme/utilities/size';
@import '../../../../../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.filterRow {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: ps-size(1, 0, 2);
}

.delete {
  color: $ps-gray-300;
  display: flex;

  i {
    height: ps-size(1) + 2;
  }

  &:hover {
    text-decoration: none;
  }
}

.columnDropdown {
  margin-right: ps-size(2);
  width: 276px;

  @media (min-width: $screen-sm-min) {
    width: ps-size(20);
  }
}

.filterFields {
  display: flex;
  flex-direction: column;

  @media (min-width: $screen-sm-min) {
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
  }
}

.operand {
  width: 276px;

  @media (min-width: $screen-sm-min) {
    flex-grow: 1;
    min-width: 150px;
    width: auto;
  }
}
