//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../../styles/boulevard-theme/utilities/size';
@import '../../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.widgets-list-inbox {
  .widgets-message {
    margin: 30px auto 60px;
  }
}

.task-details-masked {
  margin: 60px auto;
  text-align: center;

  .lock-icon img {
    margin-bottom: 16px;
    width: 48px;
  }
}

.task-details-controls {
  display: flex;
  justify-content: space-between;
  margin: 4px 30px;

  .btn {
    font-size: 14px;
    padding: 6px 8px;
  }

  .ps-snoozer {
    padding-left: 3px;
  }

  .btn-primary {
    height: ps-size(4);
    padding: 1px 2px;
    width: ps-size(4);
  }

  .task-assign-btn {
    padding: 6px ps-size(1);
  }

  .first-control {
    column-gap: 16px;
    display: flex;
  }
}

.task-details {
  .approvals-widget-container {
    .next-btn {
      margin-bottom: 15px;
      margin-right: var(--ps-space-1);
    }

    .approval-buttons {
      display: inline-block;
    }

    .modal-footer {
      padding: 15px 0;
    }
  }
}
