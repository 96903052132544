//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

$border-color: $ps-gray-50;

.plan-level-pricing-panel {
  .label-features {
    border-top: 1px solid $border-color;
    color: $ps-gray-400;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    margin-top: 147px;
    padding-bottom: 20px;
    padding-top: 24px;
    text-transform: uppercase;
  }

  .selected-level-free {
    background-color: $ps-gray-50;
  }

  .selected-level-basic {
    background-color: $ps-yellow-100;
  }

  .selected-level-standard {
    background-color: $ps-green-100;
  }

  .selected-level-professional {
    background-color: $ps-brand-100;
  }
  &--freemium .selected-level-professional {
    background-color: $ps-green-100;
  }

  .selected-level-enterprise {
    background-color: $ps-purple-100;
  }

  @media (min-width: $screen-sm-min) {
    .pricing-items {
      padding-left: 0 !important;
    }

    &__free-plan-item {
      padding-left: 0 !important;
    }

    .pricing-feature-list {
      padding-right: 0 !important;
    }
  }
}
