//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

// the style should match the `.widgets-dit-bar .sidebar-btn-group .btn` class in `widgets-edit-bar.scss`
.btn-dragging {
  align-items: center;
  background-color: $ps-brand-100;
  border: 1px solid $ps-brand-500;
  border-radius: var(--ps-radii-full);
  box-shadow: 0 15px 30px 0 rgba(28, 42, 51, 0.4);
  cursor: move;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  height: var(--ps-sizes-8);
  padding-bottom: var(--ps-sizes-2);
  padding-left: var(--ps-sizes-4);
  padding-right: var(--ps-sizes-4);
  padding-top: var(--ps-sizes-2);
  width: 110px;

  span,
  i {
    color: $ps-brand-500;
  }

  &:hover {
    background-color: $ps-brand-100;
    border-color: $ps-brand-500;
    color: $ps-brand-500;
  }
}
