//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.logoSpinner {
  margin: 20px; // should be removed once we reactify the loader.
  text-align: center;
}

:global(.loader) {
  .logoSpinner {
    margin: 100px 20px;
  }
}

.logoSpinnerImage {
  display: block;
  height: ps-size(8);
  margin: 20px auto;
  opacity: 0;
  transition: 2s;
  vertical-align: baseline;
  width: ps-size(8);
}

.logoSpinnerImageLoaded {
  opacity: 1;
}

.logoSpinnerText {
  color: $ps-gray-400;
  font-size: 20px;
  text-align: center;
}
