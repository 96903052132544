//
// Modals
// --------------------------------------------------

.modal-close {
  color: $ps-gray-500;
  font-size: 22px;
  font-weight: $ps-font-style-medium;
  opacity: 1;

  &:hover {
    color: $ps-gray-700;
  }
}

.modal-dialog {
  .modal-header {
    align-items: center;
    border-bottom: 0;
    display: flex;
    justify-content: center;
    min-height: ps-size(5);
    padding: ps-size(0, 2);
    position: relative;

    &:after {
      background-color: $ps-gray-100;
      bottom: 0;
      content: '';
      height: 1px;
      left: ps-size(2);
      position: absolute;
      right: ps-size(2);
      width: auto;
    }

    &.full-width-border::after {
      left: 0;
      right: 0;
    }

    h4 {
      color: $ps-gray-500;
      flex-grow: 1;
      font-size: $ps-font-size-md;
      font-weight: $ps-font-style-medium;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
    }

    .close {
      @extend .modal-close;
      order: 2;
    }
  }

  // reduce box shadow.
  .modal-content {
    border: 1px solid $ps-gray-100;
    border-radius: ps-size(0.5);
    box-shadow: ps-size(0, 0, 0.5, 0) $ps-gray-200;
    box-sizing: border-box;
  }

  .modal-footer {
    padding: ps-size(2);
  }
}

.modal-backdrop {
  background-color: $ps-gray-700;

  &.in {
    opacity: 0.5;
  }
}

@media (min-width: $screen-sm-min) {
  .modal-lg {
    width: 700px;
  }
}

@media (min-width: $screen-md-min) {
  .modal-lg {
    width: $modal-lg;
  }
}

@media (min-width: $screen-sm-min) {
  .modal-xl {
    margin: 10px;
    width: auto;
  }
}

@media (min-width: $screen-lg-min) {
  .modal-xl {
    margin: 30px auto;
    width: 1280px;
  }
}
