//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.merge-tag-selector-box {
  .search-container {
    padding-bottom: 10px;
  }

  .purpose-explained {
    border-bottom: 1px solid $ps-gray-50;
    color: $ps-gray-400;
    margin-bottom: 10px;
    padding: 0 6px 10px;
  }

  .list-group {
    height: 204px;
    margin-bottom: 0;
    overflow-x: hidden;
    overflow-y: scroll;

    // This only works for Safari and Chrome but whatcha going to do?
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 11px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      border: 2px solid $ps-white;
      border-radius: 8px;
    }

    .list-group-item {
      border: 0;
      margin-left: -15px;
      margin-right: -15px;
      overflow: hidden;
      padding: 5px 21px;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:first-child {
        border-radius: 0;
      }
    }
  }
}
