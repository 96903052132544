//
// Date Picker
// --------------------------------------------------

.datepicker-box {
  table {
    outline: none;
    // prevent padding from blvd buttons.
    button.btn:not(.dropdown-toggle) {
      padding-left: ps-size(0.5) !important;
      padding-right: ps-size(0.5) !important;
    }
  }

  td {
    // reset padding for each td.
    padding: 2px;
    // prevent boulevard default padding for first/last child be applied to calendar table.
    &:first-child {
      padding-left: 2px;
    }
    &:last-child {
      padding-right: 2px;
    }
  }

  // buttons for month view and year view.
  .uib-year button.btn,
  .uib-month button.btn {
    box-shadow: none;
    padding: ps-size(0.5) !important;

    &.active {
      background-color: $ps-brand-500;
      border-color: $ps-brand-500;
      color: $ps-white;
    }

    // calendar current month/year highlighted
    &:not(.active) {
      .text-info {
        color: $ps-brand-500;
        font-weight: bold;
      }
    }
  }

  // nav prev month button, change icon to font awesome
  button.uib-left {
    padding: ps-size(0.5) !important;
    padding-right: ps-size(0.5) !important;
    width: ps-size(4);

    i:before {
      @include fa-unicode-icon($fa-var-chevron-left);
    }
  }

  // nav next month button, change icon to font awesome
  button.uib-right {
    padding: ps-size(0.5) !important;
    width: ps-size(4);

    i:before {
      @include fa-unicode-icon($fa-var-chevron-right);
    }
  }

  // current month button/title
  .uib-title {
    text-transform: uppercase;
  }

  // calendar each day
  .uib-day {
    .btn:not(.dropdown-toggle),
    .btn {
      border-color: transparent;
      border-radius: 4px;
      box-shadow: none;
      height: 40px;
      padding: 0 !important;
      width: 40px;
    }

    // calendar current day highlighted
    .btn:not(.active) {
      .text-info {
        color: $ps-brand-500;
        font-weight: bold;
      }
    }

    // prev/next month days.
    .text-muted {
      color: $ps-gray-300;
    }

    // calendar selected day.
    .btn-info:hover,
    .btn-info:focus,
    .btn-info:active,
    .btn-info.active,
    .open > .btn-info.dropdown-toggle {
      background-color: $ps-brand-500;
      border-color: $ps-brand-500;
      color: $ps-white;
    }
  }

  // remove form button, make sure it looks outlined.
  .remove-btn {
    @include button-outline($brand-danger, $brand-danger-disabled);
  }
}
