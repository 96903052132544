//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.role-assignment-upgrade {
  .explanation-box-image {
    margin-top: 15px;
    text-align: center;
  }

  .upgrade-title {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .upgrade-description {
    margin: 1rem auto;
    max-width: 250px;
    text-align: center;
  }

  .upgrade-btn-box {
    margin: 15px 0;
    text-align: center;
  }
}
