//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.field-multi-select {
  .items {
    list-style-type: none;
    margin-bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 0;
  }

  .item {
    display: flex;
    padding: 0 10px 0 ps-size(0.5);
    position: relative;

    &.completed {
      .item-checkbox {
        background-color: $ps-brand-500;
        border-color: $ps-brand-500;
      }

      .item-name-static {
        color: lighten($panel-default-text, 50%);
        font-style: italic;
        text-decoration: line-through;
      }

      .item-checkbox-checkmark {
        color: $ps-white;
        display: inline;
      }
    }
  }

  .item-number-container {
    align-items: center;
    background: transparent;
    border-right: transparent;
    bottom: 0;
    color: $ps-gray-400;
    cursor: grab;
    display: flex;
    font-size: $ps-font-size-xs;
    justify-content: flex-end;
    min-width: ps-size(2);
    width: auto;
    .step-grip {
      display: none;
    }
  }

  .item-checkbox-container {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    margin-left: ps-size(1);
    width: 20px;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .item-checkbox {
    align-items: center;
    background-color: $ps-white;
    border: 1px solid $ps-checkbox-border-color;
    border-radius: 4px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    height: ps-size(2.5);
    justify-content: center;
    padding: 0 1px;
    width: ps-size(2.5);
    &[disabled] {
      background-color: $ps-gray-100;
      cursor: not-allowed;
    }
  }

  .item-checkbox-checkmark {
    color: $ps-gray-700;
    display: none;
  }

  .item-name {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    margin-left: $ps-base-unit;
    min-height: 34px;
    outline: none;
    padding: 7px 5px;

    &:focus {
      @include form-control-focus();
      background-color: $ps-white;
    }

    &:hover {
      background-color: $ps-white;
    }
  }

  .item-name-static {
    display: block;
    outline: none;
    overflow: hidden;
    padding: 7px 5px 7px 7px;

    &:empty::before {
      color: $ps-gray-400;
      content: '(' attr(data-placeholder) ')';
    }

    &[disabled] {
      color: $ps-gray-400;
    }
  }

  .subtask-actions-container {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    visibility: hidden;

    .merge-tag-container {
      margin-right: 4px;
    }

    .delete-item-container {
      align-items: center;
      background: transparent;
      border: 0;
      border-radius: var(--ps-radii-base);
      color: $ps-gray-500;
      display: flex;
      height: ps-size(4);
      justify-content: center;
      width: ps-size(4);

      &:hover {
        background-color: $ps-brand-50;
      }
    }
  }

  .editable .item:hover {
    .item-number-container {
      .item-number--hideable {
        display: none;
      }
      .step-grip {
        display: block;
      }
    }
  }

  .editable .item:focus-within,
  .editable .item:hover {
    .subtask-actions-container {
      visibility: visible;
    }
  }
}
