//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.editor {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  &.viewable .widgets-container,
  &.viewable .widgets {
    top: $ps-navbar-offset;
  }

  &.editable .widgets-container,
  &.editable .widgets {
    top: ($ps-navbar-offset + $ps-focus-bar-offset);
  }

  // Widgets
  .widgets-container {
    bottom: 0;
    display: none;
    left: 0;
    margin: 0;
    position: fixed;
    right: 0;
    top: $ps-navbar-offset;
  }

  .widgets-container--ai-task {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 300px;
  }

  &.widgets-shown .widgets-container {
    display: block;
  }

  .widgets-edit-bar-open {
    padding-right: 0;
  }

  .toggle-view-wrapper {
    display: inline-block;
    float: right;
    position: fixed;
  }

  .show-tasks-btn {
    background-color: $ps-gray-50;
    border-radius: 0 4px 4px 0;
    box-shadow: 0 0 6px rgba($ps-gray-700, 0.4);
    left: -4px;
    padding-left: 20px;
    position: absolute;
    top: 10px;
    z-index: 501;
  }

  .widgets-scroller-inner {
    background-color: #fff;
    border-radius: 8px;
    margin: 24px auto;
    max-width: 700px;
    padding-bottom: 8px;
  }
  .widgets-scroller {
    height: 100%;
    opacity: 95%;
    overflow-y: auto;
  }

  .widgets {
    color: $ps-gray-700;
    margin: 0 auto;
    padding: 30px 0;
    &.ms-teams {
      padding-left: 30px;
    }
  }

  .widgets-header-container {
    margin: 0 auto;
    max-width: 700px;
    padding: 0 30px;

    h3 {
      overflow: hidden;
    }
  }

  .widgets-header {
    border-bottom: 1px solid $panel-default-border;
    color: $ps-gray-700;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    margin: 0;
    padding: 10px 0;

    .completed {
      color: $ps-gray-500;
      font-style: italic;
      text-decoration: line-through;
    }
  }

  .widgets-message {
    margin: 60px auto 30px;
    max-width: 500px;
    padding: 0 30px;
    text-align: center;

    &.full {
      margin: 0 auto;
      max-width: none;
      padding: 60px 30px;
      width: 100%;
    }
  }

  .widgets-task-status-toggle-btn-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 30px auto;
    max-width: 700px;
    padding: 15px 30px;

    .btn {
      margin-bottom: 15px;
    }

    .next-btn {
      margin-right: var(--ps-space-2);
    }
  }

  .widget-container.ng-leave.ng-leave-active,
  .widget-container.ng-move,
  .widget-container.ng-enter {
    opacity: 0;
  }

  .widget-container.ng-leave,
  .widget-container.ng-move.ng-move-active,
  .widget-container.ng-enter.ng-enter-active {
    opacity: 1;
  }

  .widget-container {
    position: relative;
  }

  .sortable-placeholder {
    background-color: $ps-brand-300;
    border: 1px solid $ps-brand-300;
    display: block;
  }

  .widget-outer {
    border: 1px solid transparent;
    padding: 14px 0;
  }

  .widget-container__drag-handle {
    color: var(--ps-colors-gray-400);
    cursor: grab;
    display: none;
    left: 0;
    padding: 16px;
    position: absolute;
    top: 50%;
    transform: translate(-100%, -50%);
  }

  &:not(.viewable) .widget-outer:hover .widget-container__drag-handle {
    display: block;
  }

  .widget-inner {
    margin: 0 auto;
    max-width: 700px;
    padding: 0 30px;
    position: relative;
  }

  .widget-menu-btn {
    background: $ps-gray-400;
    border-radius: 4px;
    color: $ps-white;
    font-size: 15px;
    height: 21px;
    line-height: 21px;
    position: absolute;
    text-align: center;
    width: 21px;

    &:hover {
      background: $ps-brand-300;
    }

    &.delete {
      right: 5px;
      top: -7px;
    }

    &.settings {
      cursor: pointer;
      right: 5px;
      top: 50px;
    }

    &.copy {
      bottom: -7px;
      right: 5px;
    }

    &.move-trigger {
      bottom: -7px;
      justify-content: center;
      right: 0;
    }

    &.move-up {
      left: 5px;
      top: -7px;
    }

    &.move-down {
      bottom: -7px;
      left: 5px;
    }
  }

  .widget-menu-btn--settings-modified {
    background: $ps-gray-700;
  }

  .widget-menu-btn.settings.widget-menu-btn--settings-with-conditional-logic {
    top: 35px;
  }

  .widget-outer:hover .widget-menu-btn,
  .widget-outer.pseudo-hover .widget-menu-btn {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  // Responsive
  // Extra small devices (phones, 480px and up)
  @media (min-width: $screen-xs-min) {
    .widgets-edit-bar-open {
      padding-right: $ps-template-widgets-sidebar-width;
    }
  }

  // Small devices (tablets, 768px and up)
  @media (min-width: $screen-sm-min) {
    .widgets-container {
      top: $ps-navbar-offset;
    }

    .widget-bar-show-btn {
      top: 112px;
    }

    .steps-header-share-btn-text {
      display: inline;
    }

    .toggle-view-btn {
      margin-left: 10px;
    }
  }

  // Medium devices (desktops, 992px and up)
  @media (min-width: $screen-md-min) {
    .show-tasks-btn {
      display: none;
    }

    .widgets-container {
      display: block;
      left: 400px;
    }

    &.editable .widget-outer:hover,
    &.editable .widget-outer.pseudo-hover,
    &.editable .ui-sortable-helper .widget-outer {
      background: $ps-brand-50;
      border-color: transparent;
    }

    .widget-menu-btn {
      display: none;
    }
  }

  @media (min-width: $ps-screen-xl-min) {
    .widgets-container {
      left: 550px;
    }
  }

  // 2XLarge devices (2xlarge desktops, ≥1536px)
  @media (min-width: $ps-screen-2xl-min) {
    .widgets-container {
      left: 576px;
    }
  }
  // 3XLarge devices (3xlarge desktops, ≥1920px)
  @media (min-width: $ps-screen-3xl-min) {
    .widgets-container {
      left: 672px;
    }
  }
}
