//
// Badges
// --------------------------------------------------

.badge {
  @include badge-dimensions(4px 5px 4px 5px, $ps-font-size-xs, 1.4rem);
  @include badge-colors($ps-gray-100, $ps-gray-400);
  display: inline;
  font-weight: 600;
  position: relative;

  &.badge-inverted {
    @include badge-colors($ps-gray-700, $ps-gray-100);
  }
  &.badge-light {
    @include badge-colors($ps-gray-400, $ps-gray-100);
  }

  &.badge-primary {
    @include badge-colors($ps-white, $ps-blue);
    &.badge-inverted {
      @include badge-colors($ps-gray-700, $ps-blue-200);
    }
    &.badge-light {
      @include badge-colors($ps-blue-500, $ps-blue-100);
    }
    &.badge-dim {
      @include badge-colors($ps-gray-500, $ps-gray-200);
    }
  }

  &.badge-success {
    @include badge-colors($ps-white, $ps-green);
    &.badge-inverted {
      @include badge-colors($ps-gray-700, $ps-green-200);
    }
    &.badge-light {
      @include badge-colors($ps-green, $ps-green-100);
    }
  }

  &.badge-danger {
    @include badge-colors($ps-white, $ps-red);
    &.badge-inverted {
      @include badge-colors($ps-gray-700, $ps-red-200);
    }
    &.badge-light {
      @include badge-colors($ps-red, $ps-red-100);
    }
  }

  &.badge-warning {
    @include badge-colors($ps-white, $ps-yellow);
    &.badge-inverted {
      @include badge-colors($ps-gray-700, $ps-yellow-200);
    }
    &.badge-light {
      @include badge-colors($ps-yellow, $ps-yellow-100);
    }
  }

  &.badge-md {
    @include badge-dimensions(4px 6px, $ps-primary-font-size, 2.4rem);
  }
  &.badge-lg {
    @include badge-dimensions(12px 14px 12px 14px, $ps-primary-font-size, 2.4rem);
  }

  &.badge-right {
    align-items: center;
    position: absolute;
    right: ps-size(1);
    top: calc((100% - 22px) / 2);
  }

  a > & {
    top: -1px;
  }
}
