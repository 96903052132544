//
// Forms
// --------------------------------------------------

// Base form elements components
// --------------

label:not(.pseudo-input):not(.chakra-form__label) {
  color: $ps-gray-600;
  font-weight: 500;

  .form-group-disabled &,
  .form-group &:empty::before {
    color: $ps-gray-400;
    opacity: 0.8;
  }
  &.disabled {
    color: $ps-gray-300;
  }
}

.form-group {
  .btn.disabled,
  .btn[disabled],
  fieldset[disabled] .btn {
    background-color: $ps-gray-200;
    &:hover {
      cursor: not-allowed;
    }
  }
}

.help-block {
  color: $ps-gray-400;
}

.text-muted {
  color: $ps-gray-600;
}

input.form-control {
  box-shadow: none;
}

.form-inline .form-control {
  width: 100%;
}

// Form control feedback states
// --------------

.has-success {
  @include form-control-validation($state-success-text, $state-success-border, $state-success-bg);

  .input-group-addon {
    background-color: $brand-success;
    color: $ps-white;
  }
}

.has-warning {
  @include form-control-validation($state-warning-text, $state-warning-border, $state-warning-bg);

  .input-group-addon {
    background-color: $brand-warning;
    color: $ps-white;
  }
}

.has-error {
  @include form-control-validation($state-danger-text, $state-danger-border, $state-danger-bg);

  .input-group-addon {
    background-color: $brand-danger;
    color: $ps-white;
  }
}

// Horizontal forms
// --------------

// Adds support for stacking multiply columns per form-group row.
.form-horizontal {
  .form-group {
    [class*='col-']:not(:last-child):not(.control-label) {
      margin-bottom: 15px;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .form-horizontal {
    .form-group {
      [class*='col-']:not(:last-child):not(.control-label) {
        margin-bottom: 0;
      }
    }
  }
}

// Select input
// --------------
//
// Themed using a custom dropdown icon

.select-wrapper {
  position: relative;

  select.form-control {
    appearance: none;
    display: block;
    line-height: initial;
    position: relative !important;
  }
  select.form-control-disabled {
    border: 1px solid $ps-gray-200;
    border-top: 0;
    color: $ps-gray-400;
  }

  &:after {
    @include fa-unicode-icon($fa-var-chevron-down);
    pointer-events: none;
    position: absolute;
    right: ps-size(1.5);
    top: 2px;
    z-index: 2;
  }
}

// Radio/Checkbox inputs
// --------------
//
// Native elements themed by hiding the native input and rendering a custom span/icon

label.pseudo-input {
  cursor: pointer;
  padding-left: 0;
  user-select: none;

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  span {
    background-color: $ps-white;
    border: 1px solid $ps-gray-400;
    display: inline-block;
    height: 1.25em;
    margin-bottom: 0.15em;
    margin-right: 4px;
    padding-top: 0.0625em;
    position: relative;
    vertical-align: middle;
    width: 1.25em;
  }

  input[type='checkbox'] + span {
    border-radius: 4px;
  }

  input[type='radio'] + span {
    border-radius: 1em;
  }

  input:hover + span {
    background-color: $ps-gray-100;
  }

  input:checked + span {
    background-color: $ps-brand-500;
    border: 0;
    color: $ps-white;
  }

  input[type='checkbox']:checked + span {
    &:after {
      @include fa-unicode-icon($fa-var-check, 0.9em);
    }
  }

  input[type='radio']:checked + span {
    &:after {
      @include fa-unicode-icon($fa-var-dot-circle, 1.5em);
    }
  }

  input:disabled + span {
    background-color: darken($ps-white, 2);
    cursor: not-allowed;
  }
}

// Search
// --------------

.input-search {
  margin-bottom: 20px;
  position: relative;

  .form-control-feedback {
    align-items: center;
    background-color: transparent;
    border: 0;
    color: $ps-gray-400;
    display: flex;
    justify-content: center;
    top: 0;
  }

  .form-control-feedback:hover,
  .form-control-feedback:focus {
    color: $ps-gray-600;
  }

  i {
    display: block;
  }
}
