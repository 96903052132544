//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.widget-text {
  .widget-toolbar {
    > .mce-tinymce {
      border: 1px solid $panel-default-border;
    }
  }

  .widget-editor {
    background-color: $ps-white;
    border: 1px solid $ps-white;
    border-radius: 4px;
    cursor: text;
    max-height: 500px;
    outline: 0;
    overflow-y: auto;
    padding: 10px;

    &:focus {
      @include form-control-focus();
    }
  }

  .widget-editor--disabled {
    background-color: var(--ps-colors-gray-100);
    color: var(--ps-colors-gray-400);
    cursor: not-allowed;
  }

  .widget-content,
  .widget-editor {
    word-wrap: break-word;

    a {
      color: $ps-blue;

      &:hover {
        color: $ps-blue-600;
      }
    }

    h1 {
      font-size: $ps-font-size-xxl;
    }

    h2 {
      font-size: $ps-font-size-xl;
    }

    h3 {
      font-size: $ps-font-size-lg;
    }

    li {
      margin-bottom: 6px;
    }

    p {
      line-height: ps-size(3);
    }

    h1,
    h2,
    h3 {
      margin: 0 0 20px;
    }

    p,
    div[class^='style-'],
    ol,
    ul,
    pre {
      margin-bottom: 20px;
    }

    ol ol,
    ol ul,
    ul ol,
    ul ul {
      margin-bottom: 0;
    }

    hr {
      border-top: 1px solid $ps-gray-100;
    }

    // Style numbered list like google docs
    ol > li > ol {
      list-style-type: lower-alpha;

      > li > ol {
        list-style-type: lower-roman;
      }
    }

    // Make sure empty paragraphs show
    p:empty::before {
      content: '\00a0';
    }

    // Make sure empty blocks don't look weird
    pre {
      background-color: $ps-gray-100;
      border-color: $ps-gray-200;
      padding: ps-size(2) - 2;

      &:empty::before {
        content: ' ';
      }

      code {
        background-color: inherit;
        color: inherit;
        padding: 4px 0;
      }
    }

    code {
      background-color: $ps-red-100;
      color: $ps-red-hover;
      padding: ps-size(0.5);
    }

    // Make sure that the color picked by the user isn't overwritten
    .style-info > span:not[style],
    &.mce-content-body {
      a {
        color: $ps-blue-500;
      }

      a:hover {
        color: $ps-blue-600;
      }

      a:focus {
        color: $ps-blue-700;
      }
    }

    span[style*='color'] > a {
      color: inherit;
    }

    .style-info > span[style] {
      a {
        color: inherit;
      }
    }
  }

  .widget-editor :last-child,
  .widget-content :last-child {
    margin-bottom: 0;
  }

  .mce-edit-focus {
    outline: none;
  }

  // These styles are applied to the text editor area AND the toolbar menu
  // If you change these classes it will affect both places

  .style-success {
    background-color: $ps-green-200;
    border: 1px solid $ps-green-disabled;
    border-radius: 4px;
    font-family: inherit;
    margin-top: 5px;
    padding: ps-size(2) - 2;
    width: 100%;
  }

  .style-info {
    background-color: $ps-blue-200;
    border: 1px solid $ps-blue-disabled;
    border-radius: 4px;
    font-family: inherit;
    margin-top: 5px;
    padding: ps-size(2) - 2;
    width: 100%;
  }

  .style-warning {
    background-color: $ps-yellow-200;
    border: 1px solid $ps-yellow-disabled;
    border-radius: 4px;
    font-family: inherit;
    margin-top: 5px;
    padding: ps-size(2) - 2;
    width: 100%;
  }

  .style-danger {
    background-color: $ps-red-200;
    border: 1px solid $ps-red-disabled;
    border-radius: 4px;
    font-family: inherit;
    margin-top: 5px;
    padding: ps-size(2) - 2;
    width: 100%;
  }

  .style-blockquote {
    border-left: 3px solid $ps-gray-200;
    font-size: $ps-font-size-md;
    padding: ps-size(2) - 2;
  }

  .btn-toolbar {
    display: flex;
    flex-wrap: wrap;

    .btn-group {
      float: none !important;
    }
  }
}
