//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.required-indicator {
  .required-field {
    .control-label::after {
      color: $ps-red;
      content: ' *';
      font-weight: normal;
    }

    .field-multi-select .item-name-static::after {
      color: $ps-red;
      content: ' *';
    }
  }

  .has-error {
    button,
    .fileinput-button,
    .tinymce-text-field,
    .tinymce-textarea-field {
      border: 1px solid $ps-red-500;
    }

    .item {
      .item-checkbox {
        border-color: $ps-red-500;
      }

      &.completed .item-checkbox {
        border-color: $ps-gray-50;
      }
    }

    .control-label {
      color: $ps-gray-600;
    }
  }

  .required-text {
    color: $ps-red-500;
    font-size: 12px;
    padding-top: ps-size(1);

    &.field-is-multi-select {
      padding-left: 24px;
    }
  }
}
