//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.widget-form-field {
  form .form-group:last-child {
    margin-bottom: 0;
  }

  .disabled-mask-container {
    position: relative;

    .disabled-mask {
      bottom: 0;
      cursor: not-allowed;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .no-cursor {
      cursor: not-allowed;
    }
  }

  .audit-info {
    color: $ps-gray-400;
    display: block;
    font-size: 12px;
    margin: 4px 0;

    span {
      font-weight: bold;
    }
  }
  .has-error {
    .widget-thumbnail-img {
      border-color: $ps-red-500;
    }
  }

  .form-field-footer {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-top: var(--ps-sizes-4);
    position: relative;
  }

  .form-field-footer > *:not(:empty):not(:last-child) {
    margin-bottom: var(--ps-sizes-4);
  }

  @media screen and (min-width: $ps-screen-sm-min) {
    .form-field-footer {
      flex-direction: row;
      height: var(--ps-sizes-5);
      justify-content: space-between;
    }

    .form-field-footer > *:not(:empty):not(:last-child) {
      margin-bottom: 0;
      margin-right: var(--ps-sizes-4);
    }
  }

  .form-field-footer__right-element {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between;
    width: auto;
  }

  .validation-indicator {
    font-size: 12px;
    font-style: italic;
    margin-bottom: var(--ps-sizes-1);
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }

  .btn-outline-info--chakra {
    border: 1px solid var(--ps-colors-gray-300);
    border-radius: var(--ps-radii-base);
    color: var(--ps-colors-gray-600);
    font-size: 14px;

    &:hover {
      background-color: var(--ps-colors-gray-100);
      color: #434f5c;
    }

    &:focus {
      box-shadow: var(--ps-shadows-none);
      outline: 2px solid transparent;
      outline-offset: 2px;
    }
  }
}
