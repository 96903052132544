//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.field-multi-choice {
  label:empty::before {
    color: $ps-gray-400;
    content: '(' attr(data-placeholder) ')';
  }

  .form-group-select {
    margin-bottom: 0;

    & + ul.items {
      margin-top: $ps-dropdown-options-padding;
    }

    select {
      color: $ps-gray-400;
    }
  }

  .items {
    border: 1px $input-border solid;
    border-radius: $input-border-radius;
    list-style-type: none;
    margin-bottom: 0;
    max-height: 202px;
    overflow-y: auto;
    padding: 10px 0;
  }

  .item {
    color: $ps-gray-400;
    display: flex;
    padding: 0 10px 0 30px;
    position: relative;
  }

  .item-number-container {
    align-items: center;
    background: transparent;
    border-right: transparent;
    bottom: 0;
    color: lighten($panel-default-text, 50%);
    cursor: grab;
    display: flex;
    font-size: 11px;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 30px;

    .step-grip {
      display: none;
    }
  }

  .item-name {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    min-height: 34px;
    outline: none;
    padding: 7px 5px;

    &:focus {
      @include form-control-focus();
      background-color: $ps-white;
    }

    &:hover {
      background-color: $ps-white;
    }
  }

  .item-name-static {
    display: block;
    margin-right: 20px;
    outline: none;
    overflow: hidden;
    padding: 4px;
    text-overflow: ellipsis;

    &:empty::before {
      color: $ps-gray-400;
      content: '(' attr(data-placeholder) ')';
    }
  }
}

.has-error .field-multi-choice .tags {
  border-color: $ps-red;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  &.focused {
    border-color: $ps-red;
  }

  &:focus {
    border-color: $ps-red;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px $ps-red;
  }
}
