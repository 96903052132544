//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.widgets-edit-bar {
  .sidebar {
    background-color: $ps-brand-50;
    bottom: 0;
    padding-bottom: var(--ps-sizes-8);
    padding-top: var(--ps-sizes-2);

    position: fixed;
    right: 0;
    top: ($ps-navbar-offset + $ps-focus-bar-offset);
    transform: translate3d(0, 0, 0); //hide scrollbars below
    transition: 0.1s ease-in all;
    width: $ps-template-widgets-sidebar-width;
    z-index: 10;

    &.ng-hide,
    .dragover & {
      transform: translate(110px, 0);
    }
  }

  .widgets-edit-bar__drag-and-drop-label {
    color: var(--ps-colors-gray-400);
    font-size: var(--ps-fontSizes-xs);
    font-style: italic;
    margin-bottom: var(--ps-sizes-1);
    padding-inline-start: var(--ps-sizes-4);
  }

  .sidebar-btn-group {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 40px;
    padding-left: var(--ps-sizes-2);
    padding-right: var(--ps-sizes-2);

    .sidebar-btn-group__section {
      margin-bottom: var(--ps-sizes-2);
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    i {
      color: $ps-gray-500;
    }

    h3 {
      color: $ps-gray-400;
      font-weight: var(--ps-fontWeights-bold);
      margin: 0;
      padding-inline-start: var(--ps-sizes-2);
    }

    a {
      color: $ps-gray-600;
      height: var(--ps-sizes-8);
      padding-bottom: var(--ps-sizes-2);
      padding-left: var(--ps-sizes-4);
      padding-right: var(--ps-sizes-4);
      padding-top: var(--ps-sizes-2);
    }

    // the active style should match the `.btn-dragging` class in `drag-drop.scss`
    .btn {
      align-items: center;
      border-color: $ps-gray-100;
      border-radius: var(--ps-radii-full);
      box-shadow: none;
      cursor: move !important;
      display: flex;
      flex-direction: row;
      margin: var(--ps-sizes-1) 0;
      transition-duration: 0.5s;
      transition-property: color, background, top, right;
      transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
      width: 100%;

      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }

      i {
        color: $ps-gray-500;
        font-size: 12px;
      }

      .widget-text {
        color: $ps-gray-600;
        font-size: 12px;
      }

      &:hover {
        background-color: $ps-gray-100;
        border-color: $ps-gray-300;

        .widget-text,
        i {
          color: $ps-gray-600;
        }
      }

      &:active {
        background-color: $ps-brand-100;
        border-color: $ps-brand-500;
        box-shadow: 0 15px 30px 0 rgba(28, 42, 51, 0.4);
        position: relative;
        right: -5px;
        top: -5px;
        z-index: 300;

        .widget-text,
        i {
          color: $ps-brand-500;
        }
      }
    }
  }
}

.sidebar-show-btn {
  background-color: $ps-gray-50;
  border-radius: 4px 0 0 4px;
  box-shadow: 0 0 6px rgba($ps-gray-700, 0.4);
  padding-right: 20px;
  position: fixed;
  right: -4px;
  top: ($ps-navbar-offset + $ps-focus-bar-offset) + 10px;
  transform: translate3d(0, 0, 0); //hide scrollbars below
  transition: 0.1s ease-in all;
  z-index: 501;

  &.ng-hide,
  .dragover & {
    transform: translate(150px, 0);
  }
}

.sidebar-hide-btn {
  color: $ps-gray-500;
  height: 52px;
  left: ps-size(-4);
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 1px;
  width: ps-size(4);

  > i {
    background-color: $ps-gray-50;
    border-radius: 3px 0 0 3px;
    box-shadow: 0 0 6px rgba($ps-gray-700, 0.4);
    height: 30px;
    margin: 6px 0 0 12px;
    padding: 8px 4px 8px 6px;
    position: absolute;
    right: 0;
    top: 2px;
    width: 26px;
  }
}

.drag-dropdown-icon {
  background: url('../../images/task-template/dropdown_icon.svg') center center no-repeat;
  display: inline-block;
  height: 11px;
  margin-left: 2px;
}

.drag-multichoice-icon {
  background: url('../../images/task-template/multichoice_icon.svg') center center no-repeat;
  display: inline-block;
  height: 11px;
  margin-left: 2px;
}
