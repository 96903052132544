//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../styles/boulevard-theme/utilities/size';
@import '../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.task-approval-info-container {
  color: $ps-gray-500;

  .rejected-container {
    border: 1px solid $ps-red;
  }
  .approved-container {
    border: 1px solid $ps-green;
  }
  .main-container {
    background-color: $ps-white;
    border-radius: 5px 5px 0 0;
    padding: 20px;
    position: relative;

    &:last-child {
      border-radius: 5px;
    }

    .content {
      margin-left: 0;
      padding-left: 0;

      @media (min-width: $screen-sm-min) {
        .approval-header {
          display: flex;
          flex-direction: row;
          font-size: 14px;

          font-weight: 400;
          justify-content: space-between;
          width: 100%;

          .rejected-text {
            color: $ps-red;
          }

          .approved-text {
            color: $ps-green;
          }

          .approval-date {
            color: $ps-gray-400;
            font-size: 14px;
          }
        }
      }

      @media (max-width: $screen-sm-min) {
        .approval-header {
          display: flex;
          // Small devices (tablets, 768px and up)
          flex-direction: column;
          font-size: 14px;
          font-weight: 400;
          justify-content: space-between;
          width: 100%;

          .rejected-text {
            color: $ps-red;
          }

          .approved-text {
            color: $ps-green;
          }

          .approval-date {
            color: $ps-gray-400;
            font-size: 14px;
          }
        }
      }
    }

    .comment-section {
      border-top: 1px solid $ps-gray-100;
      margin-top: 20px;
      padding: 20px 0 0;

      .approver-info {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .about-approver {
          font-size: 14px;
          font-weight: 400;
          padding-left: 15px;
        }
      }

      .comment {
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        padding: 15px 0 0;
        word-wrap: break-word;
      }
    }
  }
}
