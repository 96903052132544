//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../../../styles/boulevard-theme/utilities/size';
@import '../../../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.approval-subject-tasks-list-item {
  background-color: $ps-gray-50;
  border-radius: 4px;
  padding: 24px;

  &.approval-subject-tasks-list-item--editing-status {
    margin-bottom: 16px;
    margin-left: 16px;
    position: relative;

    &:before {
      content: '';
      display: block;
      height: calc(100% + 16px);
      left: -16px;
      position: absolute;
      top: 0;
      width: 2px;
    }

    .approval-subject-tasks-list-item__title-wrapper .approval-subject-tasks-list-item__edit-status-btn {
      display: none;
    }
  }

  .main-container {
    position: relative;

    .task-due-offset {
      max-width: 300px;

      .fa-clock {
        display: none;
      }
    }

    .title {
      color: $ps-brand-500;
      display: block;
      font-size: 16px;
      font-weight: 500;
      line-height: 17px;
      margin: 0 0 10px;
      outline: none;
      overflow: hidden;
      white-space: nowrap;
    }

    .body {
      color: $ps-gray-500;
      font-size: 14px;
      font-style: italic;
      height: 19px;
      line-height: 19px;
      margin-top: 10px;
      width: 182px;
    }

    .main-data {
      display: flex;
      margin-bottom: 16px;

      > :not(:first-child) {
        margin-left: 48px;
      }

      .due-date {
        .value {
          color: var(--ps-colors-gray-400);
        }
      }
    }

    .assignments,
    .due-date {
      .control-label {
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  .approval-subject-tasks-list-item__title-wrapper {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto;

    .title {
      white-space: initial;
    }

    &:hover {
      .approval-subject-tasks-list-item__edit-status-btn {
        opacity: 1;
      }
    }
  }

  .approval-subject-tasks-list-item__edit-status-btn {
    color: var(--ps-colors-gray-500);
    font-size: 12px;
    max-height: 18px;
    opacity: 0;
    padding-bottom: 0;
    padding-top: 0;

    &:hover {
      background-color: transparent;
    }
  }

  .bottom-menu {
    margin-top: 36px;

    .controls {
      .btn {
        font-size: 14px;
      }

      .btn-group-justified-sm {
        border-collapse: separate;
        display: table;
        margin-top: 10px;
        table-layout: fixed;
        width: 100%;

        > .btn,
        > .btn-group {
          display: table-cell;
          float: none;
          width: 2%;
        }

        > .btn-group:last-child {
          width: 1px;
        }
      }

      .btn-approve,
      .btn-reject {
        min-width: 100px;
        width: calc(100% - 42px);
      }

      .btn-approve,
      .btn-approve + .btn-comment {
        background-color: var(--ps-colors-blue-500);
        border: 2px solid var(--ps-colors-blue-500);
        color: var(--ps-colors-white);

        &:hover {
          color: var(--ps-colors-white);
        }
      }

      .btn-approve + .btn-comment {
        border-left: 2px solid var(--ps-colors-white);
      }
    }
  }

  &.approval-subject-tasks-list-item--is-condensed {
    background-color: transparent;
    margin: 0;
    padding: 0;

    .title {
      border-left: 2px solid;
      margin: 0;
      padding: 8px 0 8px 16px;
    }
  }

  // Small devices (tablets, 768px and up)
  @media (min-width: $screen-sm-min) {
    .bottom-menu {
      .controls {
        text-align: right;

        .btn-approve,
        .btn-reject {
          padding-left: 20px;
          padding-right: 20px;
          width: auto;
        }

        .btn-cancel + .btn-group {
          margin-left: var(--ps-space-3);
        }

        .btn-group-justified-sm {
          border-collapse: unset;
          display: inline-block;
          margin-top: 0;
          table-layout: auto;
          width: auto;

          > .btn,
          > .btn-group {
            display: block;
            float: left;
            width: auto;
          }

          > .btn-group:last-child {
            width: auto;
          }

          > .btn-group .btn {
            width: auto;
          }
        }

        .btn-group + .btn-group {
          margin-left: 12px;
        }
      }
    }
  }
}
