//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.task-due-date {
  .task-due-date-btn {
    max-width: 200px;
    min-width: 34px;
    overflow: hidden;
    padding: 6px;
    text-overflow: ellipsis;
  }

  @media (min-width: $screen-sm-min) {
    .task-due-date-btn {
      padding: 6px 12px;
      width: inherit;
    }
  }
}
