//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.checklist-add-multiple {
  .container {
    max-width: 800px;
  }

  .header {
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .description {
    color: $ps-gray-500;
  }

  .download-example-csv-btn {
    color: $link-color;
    display: inline-block;
    margin: 0 10px;
  }

  .download-example-csv-btn:hover {
    color: $link-hover-color;
  }

  form {
    margin-bottom: 30px;
  }

  textarea {
    font-family: monospace;
  }

  .problematic-lines {
    margin-bottom: 15px;
  }

  .problematic-line {
    font-family: monospace;
  }

  .tips-header {
    margin: 0 0 20px;
  }
}
