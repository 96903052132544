//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

$plan-level-pricing-panel-max-width: 300px;
$plan-level-pricing-panel-thin-border: 2px;
$plan-level-pricing-panel-current-border-color: $ps-gray-200;
$plan-level-pricing-panel-selected-border-color: $ps-gray-200;
$plan-level-pricing-panel-heading-font-color: $ps-white;

.plan-level-selector {
  .plan-level-billing-cycle {
    border-radius: 0;
    margin: 0;
    padding: 0 7px;
    text-align: center;

    label {
      border-radius: 4px;
      box-shadow: inset 0 0 0 1px $ps-gray-400;
      display: block;
      padding: 15px;

      &:hover {
        background-color: $ps-gray-50;
      }
    }

    input[type='radio'] {
      height: 0;
      opacity: 0;
      width: 0;
    }

    input[type='radio']:active ~ label {
      opacity: 1;
    }

    input[type='radio']:checked ~ label {
      background-color: rgba($ps-brand-500, 0.1);
      border: 0;
      box-shadow: inset 0 0 0 2px $ps-brand-500;
      opacity: 1;

      .billing-cycle-hr {
        border-top: 1px solid $ps-brand-500;
      }
    }

    p {
      margin-bottom: 0;
    }

    .billing-cycle-period {
      color: $ps-gray-500;
      font-size: 12px;
      font-weight: bold;
      height: 15px;
      letter-spacing: 1px;
      line-height: 15px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    .billing-cycle-per-member-cost {
      font-size: 24px;
      font-weight: bold;
      height: 29px;
      line-height: 29px;
    }

    .billing-cycle-per-member-line-2 {
      color: $ps-gray-500;
      font-size: 12px;
      height: 15px;
      line-height: 15px;
      padding-top: 4px;
    }

    .billing-cycle-hr {
      margin: 15px 0 10px;
    }
  }

  .plan-level-pricing-selected {
    .active {
      background-color: rgba($ps-brand-500, 0.1);
      border-color: $ps-brand-500;
      box-shadow: inset 0 0 0 1px $ps-brand-500;
    }
  }

  .plan-level-selector-slider {
    margin: 0 auto;
    max-width: $plan-level-pricing-panel-max-width;
  }

  &__plan-info {
    background-color: $ps-gray-50;
    border-radius: 6px;
    box-sizing: border-box;
    margin: 16px 0;
    padding: 16px;
  }
}

.plan-level-pricing {
  border: 1px solid transparent;
  border-radius: 0;
  box-shadow: transparent;
  margin: 0 auto;
  max-width: $plan-level-pricing-panel-max-width;

  .panel-body {
    padding: 10px;
  }

  .plan-body {
    box-shadow: inset 0 0 0 1px $table-border-color;
  }

  .heading {
    color: $plan-level-pricing-panel-heading-font-color;
  }

  sup {
    font-size: 15px;
    top: -15px;
  }

  .btn,
  .btn:focus,
  .btn:active {
    outline: none;
  }

  .plan-cost-integer-part {
    font-size: 40px;
  }

  .enterprise {
    font-size: 30px;
    line-height: 57px;
  }

  .plan-cost-decimal-part {
    font-size: 20px;
  }

  span > div {
    margin-bottom: 20px;
    opacity: 0.8;
  }
}

.plan-level-pricing-features {
  list-style: none;
  padding-left: 0;

  li {
    font-size: 11.5px;
    overflow: hidden;
    padding-bottom: 4px;
    padding-top: 7px;

    &:not(:last-of-type) {
      border-bottom: 1px solid $ps-gray-400;
    }
  }

  .tooltip-style {
    cursor: help;
  }

  .fa-times {
    color: $brand-danger;
  }

  .fa-check {
    color: $brand-success;
  }

  .fa-question {
    color: $btn-default-border;
  }
}
