//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.field-hidden {
  .field-hidden--dashed {
    @media screen and (min-width: $ps-screen-sm-min) {
      .help-block {
        text-align: right;
      }
    }

    .form-control,
    .input-group-addon {
      border: 1px dashed $ps-gray-300;
    }
  }
  .help-block {
    font-size: $ps-font-size-sm;
  }
}
