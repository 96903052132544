//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.widget-email {
  textarea {
    min-height: 34px;
  }

  .merge-tag-input-group {
    position: relative;

    .form-control {
      padding-right: 40px;
    }

    .merge-tag-selector-trigger {
      position: absolute;
      right: 0;
      top: 0;

      .btn {
        border: 0;
        color: $ps-gray-400;
        margin: 3px;
        padding: 4px 9px;
      }
    }

    &:hover {
      .btn {
        background-color: #e6e6e6;
      }
    }
  }
}
