//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.template-add {
  .loading-screen {
    align-items: center;
    background-color: rgba($ps-gray-700, 0.5);
    bottom: 0;
    color: $ps-white;
    display: flex;
    flex-direction: column;
    font-size: 22px;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 53px;
    z-index: 501;
  }
}

// Responsive

// Small devices (tablets, 768px and up) //
@media (min-width: $screen-sm-min) {
  .template-add {
    .loading-screen {
      top: $ps-navbar-offset;
    }
  }
}
