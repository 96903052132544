//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../../styles/boulevard-theme/utilities/size';
@import '../../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.approval-subject-task-widget-members-renderer {
  .avatar {
    align-items: center;
    display: flex;

    > :not(:first-child) {
      margin-left: 8px;
    }
  }

  .avatar__label {
    color: var(--ps-colors-gray-600);
  }
}
