//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.datepicker-box {
  .date-fields {
    margin-left: 0;
    margin-right: 0;
  }

  .row {
    padding: 0 7.5px;

    > * {
      padding: 0 7.5px;
    }
  }

  .btn {
    margin-bottom: 0;
    width: 100%;
  }

  table {
    margin-bottom: 20px;
    width: 100%;

    thead .btn-default {
      border-color: transparent;
      margin-bottom: 10px;
    }
  }

  .uib-year button {
    padding: 6px 8px;
  }

  .today-btn,
  .save-btn,
  .remove-btn {
    margin-bottom: 10px;
  }

  .remove-time-btn {
    background-color: transparent;
    border: 0;
    margin-right: 4px;
    pointer-events: all;
    top: -8px;
  }
}

.override-modal {
  .override-img {
    margin: 15px 0;
    max-width: 100px;
  }

  .button-override {
    border-top: 1px solid #e5e5e5;
    margin-bottom: 1rem;
    margin-top: 2rem;
    padding-top: 2rem;
  }
}

.override-message {
  background-color: $ps-orange;
  border-radius: 5px;
  color: $ps-white;
  font-size: 0.9em;
  font-weight: bold;
  margin: 1rem 0;
  padding: 8px;
  text-align: center;
}

.pop-box.datepicker-pop-box {
  width: 360px;
}
