//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.free-plan-item {
  border: 1px solid $ps-gray-300;
  border-top: 5px solid $ps-gray-300;
  margin: 16px 0;

  &__top-panel {
    border-right: 1px solid $ps-gray-50;
  }

  &__labels {
    color: $ps-gray-500;
    font-size: 12px;
    padding-top: 8px;
  }

  &__label {
    height: 32px;
  }

  &__button {
    height: 100px;
    padding: 22px 0 0;
  }

  @media (max-width: $screen-sm-min) {
    &__labels {
      padding-top: 0;
    }
  }
}
