//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.field-file {
  label:empty::before {
    color: $ps-gray-400;
    content: '(' attr(data-placeholder) ')';
  }

  .btn-group-file {
    max-width: 100%;
  }

  .btn-download {
    max-width: calc(100% - 36px);
    overflow: hidden;
  }

  .btn-remove {
    max-width: 36px;
  }

  .btn-default--grey {
    background-color: $ps-gray-200;
  }
}

.has-error .field-file {
  .fileinput-button,
  .widget-thumbnail {
    border: 1px solid $ps-red-500;
  }
}

.field-file__has-error {
  color: $ps-red;
  font-size: ps-size(1.5);
  padding-top: ps-size(0.5);
}
.field-file__placeholder {
  color: $ps-gray-400;
  font-size: $ps-font-size-sm;
  font-style: italic;
  padding-top: ps-size(1);
}
