//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.searchInput {
  min-width: 200px;
  position: relative;

  @media (max-width: $ps-screen-md-min) {
    min-width: 140px;
  }

  input {
    border-radius: 4px;
    font-size: $ps-primary-font-size;
    height: $ps-dashboard-input-height;
    line-height: ps-size(2.5);
    padding-left: ps-size(4);
    width: 100% !important;
  }
}

.clearable {
  input {
    padding-right: ps-size(3.5);
  }
}

.searchIcon {
  color: $ps-gray-500;
  font-size: $ps-primary-font-size;
  left: ps-size(1.5);
  line-height: $ps-primary-font-size;
  position: absolute;
  top: floor(($ps-dashboard-input-height - ps-size(2)) * 0.5);
}

.clearIcon {
  color: $ps-gray-500;
  font-size: $ps-primary-font-size;
  line-height: $ps-primary-font-size;
  position: absolute;
  right: ps-size(1);
  text-align: center;
  top: floor(($ps-dashboard-input-height - ps-size(2)) * 0.5);
}

.grayColor {
  input {
    background-color: $ps-gray-100;
    border-color: $ps-gray-50;
    box-shadow: none;
    color: $ps-gray-500;
    outline: none;

    &:focus {
      border-color: $ps-gray-50;
      box-shadow: none;
      color: $ps-gray-700;
    }
  }
}
