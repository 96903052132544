//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.manage {
  .manage-title {
    color: $ps-gray-700;
    line-height: 32px;
  }

  .item {
    margin-bottom: 20px;

    button {
      width: 100%;
    }
  }

  .no-activity-warning {
    padding: ps-size(1);
  }
}

// Medium devices (desktops, 992px and up)
@media (min-width: $screen-md-min) {
  .manage {
    max-width: 768px;
  }
}
