//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.template-share-modal {
  &.template-share-modal--tabs-hidden {
    border-radius: 16px;

    .nav.nav-tabs {
      display: none;
    }

    .modal-header {
      border-bottom: 1px solid var(--ps-colors-gray-100);
      padding: var(--ps-sizes-4);
    }
  }
  .learn-more-link {
    float: left;
    margin-top: 7px;
  }

  .modal-header {
    padding: 32px 32px 0;
    h4 {
      color: $ps-gray-700;
      font-size: $ps-font-size-lg;
      font-weight: bold;
    }
    .modal-title {
      text-align: left;
    }

    .modal-title-wrapper {
      display: flex;
      width: 100%;

      .modal-title {
        font-size: $ps-font-size-md;
        margin-left: var(--ps-sizes-2);
      }
    }
  }
}
