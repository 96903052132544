//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.widget-file {
  page-break-inside: avoid;
  text-align: center;

  figure {
    display: inline-block;
    width: 100%;
  }

  figcaption {
    color: $ps-gray-400;
    font-size: 12px;
    font-style: italic;
    padding: 6px 0 0;
    position: relative;
    text-align: left;
    width: 100%;
  }

  %thumbnail {
    background-color: $ps-gray-300;
    background-size: cover;
    border: 1px solid $ps-gray-50;
    border-radius: 3px;
    height: 78px;
    left: 0;
    position: absolute;
    text-align: center;
    text-decoration: none;
    top: 0;
    width: 80pt;
    z-index: 0;
  }

  .widget-thumbnail {
    @extend %thumbnail;
    color: $ps-gray-400;
    display: block;
    font-size: 18px;
    font-weight: 700;
    line-height: 78px;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }

  .widget-thumbnail-img {
    @extend %thumbnail;
  }

  .widget-info {
    left: 120px;
    position: absolute;
    right: 0;
    top: 10px;
  }

  .widget-info-inner-quiet {
    color: $ps-gray-400;
    line-height: 20px;
    margin-left: 4px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
  }

  .widget-info-inner-menu {
    color: $ps-gray-400;
    margin-right: 5px;
    text-decoration: underline;
  }

  .widget-info-inner-menu:hover {
    color: $ps-gray-600;
  }

  @media print {
    [href]::after {
      content: none;
    }
  }
}
