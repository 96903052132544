//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../styles/boulevard-theme/utilities/size';
@import '../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.approval-subject-tasks-group {
  margin-bottom: 24px;

  &--approved {
    .approval-subject-tasks-list-item--editing-status:before {
      background-color: $ps-green-500;
    }
  }

  &--rejected {
    .approval-subject-tasks-list-item--editing-status:before {
      background-color: $ps-red-500;
    }
  }

  .group-title {
    color: $ps-gray-400;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 15px;

    .rejected &,
    .approved & {
      border-radius: 4px;
      max-width: fit-content;
      padding: 2px 4px;
    }

    .rejected & {
      background-color: $ps-red-100;
      color: $ps-red-500;
    }

    .approved & {
      background-color: $ps-green-100;
      color: $ps-green-500;
    }
  }

  .content .title {
    .awaiting & {
      border-color: $ps-gray-200;
    }

    .rejected & {
      border-color: $ps-red;
    }

    .approved & {
      border-color: $ps-green;
    }
  }
}
