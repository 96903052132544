//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../styles/boulevard-theme/utilities/size';
@import '../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.thumbnail {
  background-color: $ps-gray-50;
  border: 1px solid $ps-gray-300;
  border-radius: 3px;
  cursor: pointer;
  height: 78px;
  object-fit: cover;
  text-align: center;
  text-decoration: none;
  width: 80pt;
}

.fullsize {
  border-radius: 4px;
  display: block;
  height: auto;
  max-width: 100%;
}
