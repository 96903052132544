//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

// Checklists

.checklist {
  &.with-statusbar {
    .steps-container,
    .widgets-container,
    .checklist-left-pane-steps-container {
      top: calc(#{$ps-navbar-offset} * 2);
    }
    .rightbar .sidebar {
      top: calc(#{$ps-navbar-offset} * 2);
    }

    .rightbar .rightbar-show-btn {
      top: calc(#{$ps-navbar-offset} * 2 + 10px);
    }
    .steps-container.ms-teams,
    .widgets-container.ms-teams {
      top: $ps-navbar-offset;
    }
  }
}

.editor {
  .checklist-rightbar-open {
    padding-right: 0;
  }

  @media (min-width: $screen-xs-min) {
    .checklist-rightbar-open {
      padding-right: 270px;
    }
  }
}
