//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.approval-task-action-menu {
  text-align: right;

  .btn-approve {
    padding-left: 10px;
    padding-right: 10px;
  }

  .btn-reject {
    padding-left: 55px;
    padding-right: 10px;
  }

  .btn-approve,
  .btn-reject {
    width: calc(100% - 42px);
  }

  .btn-comment {
    width: 42px;
  }

  .btn-approve,
  .btn-approve + .btn-comment {
    background-color: var(--ps-colors-blue-500);
    border: 2px solid var(--ps-colors-blue-500);
    color: var(--ps-colors-white);

    &:hover {
      color: var(--ps-colors-white);
    }
  }

  .btn-approve + .btn-comment {
    border-left: 2px solid var(--ps-colors-white);
  }

  .awaiting-number {
    font-style: normal;
  }

  .btn-group-justified-sm {
    border-collapse: separate;
    display: table;
    table-layout: fixed;
    width: 100%;
    > .btn,
    > .btn-group {
      display: table-cell;
      float: none;
    }
    > .btn-group:last-child {
      width: 1px;
    }
  }

  .btn {
    margin-bottom: 15px;
  }

  // Small devices (tablets, 768px and up)
  @media (min-width: $screen-sm-min) {
    margin-left: var(--ps-space-2);

    .btn-approve,
    .btn-reject {
      padding-left: 20px;
      padding-right: 20px;
    }

    .btn-group + .btn-group {
      margin-left: var(--ps-space-3);
    }

    .btn-group-justified-sm {
      border-collapse: unset;
      display: inline-block;
      table-layout: auto;
      width: auto;
      > .btn,
      > .btn-group {
        display: block;
        float: left;
        width: auto;
      }
      > .btn-group:last-child {
        width: auto;
      }
      > .btn-group .btn {
        width: auto;
      }
    }
  }

  // Medium devices (desktops, 992px and up)
  @media (min-width: $screen-md-min) {
  }

  // Large devices (large desktops, 1280px and up)
  @media (min-width: $screen-lg-min) {
  }
}
