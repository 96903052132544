//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.mentions__suggestions__list {
  background: $ps-white;
  border: 1px solid $ps-gray-300;
  border-radius: 3px;
  color: $ps-gray-700;
  left: 0;
  margin-top: 18px;
  min-width: 120px;
  position: absolute;
  top: 0;
  z-index: 11110 !important;
}

.mentions__suggestions__item {
  cursor: pointer;
  display: block;
  padding: 5px 10px;
  white-space: nowrap;

  .avatar-label {
    display: inline-block;
    padding: 0 10px;
  }
}

.mentions__suggestions__item--focused {
  background: $ps-brand-500;
  color: $ps-white;
}

.mentions__highlighter {
  min-height: ps-size(8);
  padding-bottom: ps-size(2);
}

.mentions {
  .mentions__input {
    background-color: transparent;
    background-image: none;
    border: 0;
    box-shadow: none;
    color: $ps-gray-700;
    min-height: 64px;
    padding: 4px;

    &:focus {
      border: 0;
      box-shadow: none !important;
      outline: 0;
    }
  }
}
