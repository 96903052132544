@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50% {
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    left: 8px;
  }

  10% {
    left: -8px;
  }

  30% {
    left: 8px;
  }

  60% {
    left: -8px;
  }

  100% {
    left: 0;
  }
}
