//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../styles/boulevard-theme/utilities/size';
@import '../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.approval-rules-edit-container {
  border: 1px solid $ps-gray-300;
  border-radius: 4px;
  display: inline-block;
  max-height: 480px;
  width: 100%;

  .title {
    border-bottom: 1px solid $ps-gray-300;
    color: $ps-gray-400;
    margin-bottom: 10px;
    padding: 10px;
    text-align: center;
  }

  .body {
    max-height: 428px;
  }
}
