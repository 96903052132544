//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../styles/boulevard-theme/utilities/size';
@import '../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.approval-subject-task-reject-modal {
  .close {
    padding: 5px 0;
    position: absolute;
    right: 10px;
    top: 0;
  }

  .modal-title {
    margin-bottom: 20px;
  }

  .modal-body {
    padding: 20px;
  }

  // Responsive

  // Extra small devices (phones, 480px and up)
  @media (min-width: $screen-xs-min) {
  }
}
