.ui-select-bootstrap {
  .ui-select-match {
    .ui-select-toggle {
      align-items: center;
      border-bottom-left-radius: 4px !important;
      border-top-left-radius: 4px !important;
      display: flex;
      padding-left: $padding-base-horizontal;
      padding-right: ps-size(3.5);

      .ui-select-placeholder {
        display: inline-block;
        width: 100%;
      }

      .ui-select-match-text {
        align-items: center;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      > .caret {
        border-width: 0;
        font-style: normal;
        margin-top: 0;
        right: ps-size(1.5);
        top: calc(50% - 5px);
        width: 14px;

        &:before {
          @include fa-unicode-icon($fa-var-chevron-down);
          height: 100%;
          padding-left: 0;
        }
      }
    }
  }

  .ng-dirty.ng-invalid {
    > button.btn.ui-select-match {
      border-color: $ps-red;
    }
  }

  .ui-select-choices-row {
    &.active > .ui-select-choices-row-inner {
      background-color: $ps-brand-500;
      color: $ps-white;

      &:hover,
      &:focus {
        color: $ps-gray-700;
      }
    }

    .ui-select-choices-row-inner {
      align-items: center;
      display: flex;
      padding: ps-size(0.5) ($padding-base-horizontal * 0.5);

      &:hover,
      &:focus {
        background-color: $ps-brand-200;
      }
    }
  }
}
