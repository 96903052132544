//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.avatarCounter {
  align-items: center;
  background-color: $ps-gray-400;
  border: 1px solid $ps-white;
  border-radius: ps-size(4);
  color: $ps-white;
  display: flex;
  font-weight: 500;
  justify-content: center;
  position: relative;
}

.sizeExtraSmall {
  font-size: calc(#{$ps-font-size-xs} - 1px);
  height: ps-size(3);
  line-height: ps-size(3);
  width: ps-size(3);
}

.sizeSmall {
  font-size: calc(#{$ps-font-size-xs} - 1px);
  height: ps-size(3.5);
  line-height: ps-size(3.5);
  width: ps-size(3.5);
}

.sizeNormal {
  font-size: $ps-font-size-xs;
  height: ps-size(4);
  line-height: ps-size(4);
  width: ps-size(4);
}

.sizeMedium {
  font-size: $ps-primary-font-size;
  height: ps-size(5);
  line-height: ps-size(5);
  width: ps-size(5);
}

.sizeLarge {
  font-size: $ps-primary-font-size;
  height: ps-size(6);
  line-height: ps-size(6);
  width: ps-size(6);
}
