//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

// Keep in sync with styles in editor-utils.ts
.widget-text,
.approval-subject-task-widget-table-renderer {
  .table-form-field-merge-tag {
    // has to be placed on wrapper in order for radius to work
    border: 1px solid $ps-gray-300;
    border-radius: 4px;
    margin-bottom: 16px;
    overflow-x: auto;

    table {
      width: 100%;
    }

    thead {
      background-color: $ps-gray-100;
      border-bottom: 1px solid $ps-gray-300;

      th {
        color: $ps-gray-800;
        font-size: $ps-font-size-sm;
        font-weight: bold;
        min-width: 200px;
        text-transform: none;
      }
    }

    tbody {
      tr:nth-child(even) {
        background-color: $ps-gray-50;
      }

      tr {
        border-top: 1px solid $ps-gray-200;
      }
    }

    th,
    td {
      height: 39px;
      padding: 4px 17px;
      text-align: left;
    }
  }
}
