//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.task-templates-list-multi-select-menu {
  background-color: $ps-white;
  bottom: 0;
  display: block;
  margin: 0;
  opacity: 0;
  overflow-y: auto;
  position: fixed;
  right: 0;
  transition: visibility 0s, opacity 0.4s linear;
  visibility: hidden;
  z-index: 300;

  .items-container {
    margin: 0 auto;
    max-width: 300px;
    padding: 50px 30px;
    text-align: center;

    .clear-selection-container {
      font-size: 18px;
      margin: 20px -10px;
    }
  }

  hr {
    margin: 10px 0;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

.navbar-offset .editor .task-templates-list-multi-select-menu {
  top: $ps-navbar-offset + $ps-focus-bar-offset;
}

.editor .task-templates-list-multi-select-menu {
  top: $ps-focus-bar-offset;
}

.editor {
  &.widgets-shown .task-templates-list-multi-select-menu {
    background-color: $ps-gray-50;
    display: none;
  }

  // Small devices (tablets, 768px and up)
  @media (min-width: $screen-sm-min) {
  }

  // Medium devices (desktops, 992px and up)
  @media (min-width: $screen-md-min) {
    &.widgets-shown .task-templates-list-multi-select-menu {
      display: block;
      left: 400px;
    }
  }

  @media (min-width: $ps-screen-xl-min) {
    &.widgets-shown .task-templates-list-multi-select-menu {
      left: 550px;
    }
  }
  @media (min-width: $ps-screen-2xl-min) {
    &.widgets-shown .task-templates-list-multi-select-menu {
      left: 576px;
    }
  }
  // 3XLarge devices (3xlarge desktops, ≥1920px)
  @media (min-width: $ps-screen-3xl-min) {
    &.widgets-shown .task-templates-list-multi-select-menu {
      left: 672px;
    }
  }
}
