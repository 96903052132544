//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.field-date {
  page-break-inside: avoid;

  label:empty::before {
    color: $ps-gray-400;
    content: '(' attr(data-placeholder) ')';
  }

  .btn-default--grey {
    background-color: $ps-gray-200;
  }
}

.field-date__error {
  color: $ps-red;
  font-size: var(--ps-fontSizes-xs);
  padding-top: ps-size(1);
}
