//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.role-assignment-avatar {
  display: inline-block;
  position: relative;

  .initials {
    background-color: $ps-gray-300;
    border: 1px solid $ps-white;
    border-radius: 50%;
    color: $ps-white;
    display: inline-block;
    font-size: $ps-font-size-xs;
    font-weight: 700;
    height: ps-size(4);
    line-height: ps-size(4);
    text-align: center;
    width: ps-size(4);
  }

  .badge {
    bottom: 1px;
    font-size: 8px;
    line-height: 0.9;
    padding: 3px 5px;
    position: absolute;
    right: 1px;
  }
}
