//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../styles/boulevard-theme/utilities/size';
@import '../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.task-template-bulk-stop-task {
  display: table;
  width: 100%;

  .add-stop-task-button {
    width: 100%;
  }

  .remove-stop-task-button {
    padding-left: 5px;

    .btn {
      height: $ps-input-height;
      padding: 6px 9px;
    }

    .fa-stack {
      font-size: 0.5em;
    }
  }

  .stop-task-button-cell {
    display: table-cell;
    vertical-align: top;
    width: 100%;
  }
}
