//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../../../../styles/boulevard-theme/utilities/size';
@import '../../../../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.progressRenderer {
  align-items: center;
  display: flex;
  width: 240px;

  & :global(.progress) {
    margin-bottom: 0;
  }
}

.percentageLabel {
  color: $ps-gray-700;
  font-size: $ps-font-size-sm;
  line-height: ps-size(3);
  position: relative;
  text-align: right;
  width: 60px;
}
