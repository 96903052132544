//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.progressbar {
  .progress {
    border-radius: 0;
    height: 5px;
    left: 0;
    opacity: 0.8;
    position: fixed;
    right: 0;
    top: $ps-navbar-offset;
    z-index: 1032;
  }

  .progress-bar {
    background-color: $ps-brand-500;
    box-shadow: none;
  }
}
