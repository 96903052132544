//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.next-bill-details {
  margin-left: -4px;
  margin-right: -4px;
}

.plan-details {
  .plan-status {
    color: $ps-gray-500;
  }

  .panel-limit-exceeded > .panel-heading {
    background-color: $btn-danger-bg;
    color: $btn-danger-color;
  }

  .plan-info {
    border-radius: 6px;
    box-sizing: border-box;
    margin: 16px 0;
    padding: 16px;
  }

  .plan-info-default {
    background-color: $ps-gray-50;
  }

  .plan-info-warning {
    background-color: $ps-yellow-100;
  }

  .plan-info-danger {
    background-color: $ps-red-100;
  }

  .plan-info-info {
    background-color: $ps-brand-100;
  }

  .plan-frame {
    border: 1px solid $ps-gray-300;
    border-radius: 6px;
  }

  .plan-divider {
    margin: 0 10px;
  }

  .active-info {
    padding: 8px 0;
    white-space: nowrap;
  }

  .change-plan-button {
    text-align: right;

    button {
      margin-top: 8px;
      min-width: 120px;
      width: 100%;
    }
  }

  @media (min-width: $screen-sm-min) {
    .change-plan-button {
      button {
        margin-top: 0;
        width: auto;
      }
    }
  }
}
