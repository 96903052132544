// Bootstrap Variable Overrides
//
// Layout and formatting should match Bootstrap.
// bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss.
//
// This file should ONLY contain variables which override Bootstrap
// If we add our own variables they should be prefixed with $ps- and
// stored in _boulevard-variables.scss

//== Colors
//
//##

$gray-darker: $ps-gray-700;
$gray-dark: $ps-gray-700;
$gray: $ps-gray-500;
$gray-light: $ps-gray-400;
$gray-lighter: $ps-gray-100;

$brand-primary: $ps-blue;
$brand-primary-disabled: $ps-blue-disabled;
$brand-success: $ps-green;
$brand-success-disabled: $ps-green-disabled;
$brand-info: $ps-white;
$brand-info-disabled: $ps-gray-400;
$brand-warning: $ps-yellow;
$brand-warning-disabled: $ps-yellow-disabled;
$brand-danger: $ps-red;
$brand-danger-disabled: $ps-red-disabled;

//== Typography
//
//##

$font-family-sans-serif: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', sans-serif;
$font-family-serif: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', sans-serif;

$font-size-small: $ps-font-size-xs;
$font-size-base: $ps-font-size-md;
$font-size-large: $ps-font-size-lg;

$font-size-h1: $ps-font-size-xxxl;
$font-size-h2: $ps-font-size-xxl;
$font-size-h3: $ps-font-size-xl;
$font-size-h4: $ps-font-size-lg;
$font-size-h5: $ps-font-size-md;
$font-size-h6: $ps-font-size-xs;

$headings-font-weight: 400;

//== Components
//
//##

$padding-small-vertical: 3px;
$padding-small-horizontal: 8px;

$padding-base-vertical: 7px;
$padding-base-horizontal: 12px;

$padding-large-vertical: 11px;
$padding-large-horizontal: 16px;

$line-height-large: 1.2; // 24px
$line-height-base: 1.5; // 24px
$line-height-small: 1.33; // 16px

//== Tables
//
//##

$table-border-color: $ps-gray-200;
$table-bg-hover: $ps-gray-400;
$table-bg-active: $ps-gray-50;

//== Buttons
//
//##

$btn-font-weight: bold;

$btn-default-color: $ps-gray-600;
$btn-default-bg: $ps-white;
$btn-default-border: $ps-gray-300;

$btn-info-border: $ps-gray-300;
$btn-info-bg: $ps-gray-50;
$btn-info-color: $ps-gray-600;

$btn-primary-border: $ps-blue-500;
$btn-success-border: $brand-success;
$btn-danger-border: $brand-danger;
$btn-warning-border: $brand-warning;

//== Forms
//
//##

$input-color: $ps-gray-700;
$input-color-placeholder: lighten($input-color, 45%);
$input-bg-disabled: $ps-gray-100;
$input-border: $ps-gray-300;

$input-height-small: (ceil($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2);
$input-height-base: (ceil($font-size-base * $line-height-base) + ($padding-base-vertical * 2) + 2);
$input-height-large: (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2);

//== Dropdowns
//
//##

$dropdown-link-hover-bg: $ps-brand-500;
$dropdown-link-hover-color: $ps-white;
$dropdown-link-active-bg: $ps-brand-500;
$dropdown-link-active-color: $ps-white;
$dropdown-border: $ps-gray-300;

//== Grid system
//
//##

$grid-gutter-width: ps-size(2);

//== Navbar
//
//##

$navbar-height: $ps-navbar-height;
$navbar-margin-bottom: 0;
$navbar-default-bg: $ps-white;
$navbar-default-color: $ps-gray-600;
$navbar-default-link-color: $ps-gray-500;
$navbar-default-link-hover-color: $ps-brand-500;
$navbar-border-radius: 0;

$nav-link-padding: 10px (ps-size(3) * 0.5);

//== Form states and alerts
//
//##

$state-success-text: $brand-success;
$state-success-bg: $brand-success;
$state-success-border: $brand-success;

$state-info-text: darken($brand-info, 5%);
$state-info-bg: $brand-info;
$state-info-border: $brand-info;

$state-warning-text: darken($brand-warning, 5%);
$state-warning-bg: $brand-warning;
$state-warning-border: $brand-warning;

$state-danger-text: darken($brand-danger, 5%);
$state-danger-bg: $brand-danger;
$state-danger-border: $brand-danger;

//== Tooltips
//
//##

$tooltip-bg: $ps-gray-700;
$tooltip-arrow-width: ps-size(0.5);

//== Modals
//
//##

$modal-title-padding: 0 15px;
$modal-content-bg: $ps-white;
$modal-backdrop-opacity: 0.04;
$modal-header-border-color: $ps-gray-100;
$modal-content-border-color: $ps-gray-100;
$modal-title-line-height: 2.6;

//== Badges
//
//##

$badge-border-radius: 4px;
$badge-line-height: $ps-primary-font-size;
$badge-bg: $ps-gray-50;
$badge-color: $ps-gray-700;

//== List group
//
//##

$list-group-border: $ps-gray-200;

//== Panels
//
//##
$panel-default-heading-bg: $ps-gray-50;
$panel-default-border: $ps-gray-200;

//== Breakpoints (to match Chakra https://chakra-ui.com/docs/styled-system/features/responsive-styles)
//
//##

$screen-xs: $ps-screen-sm-min;
$screen-sm: $ps-screen-md-min;
$screen-md: $ps-screen-lg-min;
$screen-lg: $ps-screen-xl-min;
