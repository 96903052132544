//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.manage-assignments-box {
  ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  li {
    padding: 5px 0;
  }

  .image {
    float: left;
  }

  .details {
    margin: 0 42px;

    .username-wrapper {
      .username {
        display: inline-block;
        font-size: 14px;
        line-height: 16px;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .username-wrapper.from-rule {
      .username {
        max-width: calc(100% - 20px);
      }
    }

    .role-assigned-indicator {
      display: inline-block;
      line-height: 16px;
      overflow: hidden;
    }

    .email {
      font-size: 12px;
      line-height: 16px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .unassign-btn {
    float: right;
    height: 32px;
    line-height: 32px;
    padding: 0;
    width: 32px;
  }
}
