//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.spinner-container {
  display: block;
  position: relative;

  &.spinner-container-xs {
    height: 5vh;
  }

  &.spinner-container-sm {
    height: 10vh;
  }

  &.spinner-container-md {
    height: 15vh;
  }

  &.spinner-container-lg {
    height: 30vh;
  }

  &.spinner-container-input {
    height: 34px;
  }

  &.spinner-container-input-lg {
    height: 46px;
  }

  &.spinner-container-right {
    right: 15px;
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  .ps-spinner::before {
    animation: spinner 0.6s linear infinite;
    border: 2px solid $ps-gray-50;
    border-radius: 50%;
    border-top-color: $ps-brand-500;
    box-sizing: border-box;
    content: '';
    height: 20px;
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
    width: 20px;
  }
  //there should be big and small
  &.spinner-beside-text {
    display: inline;

    .spinner-text {
      margin-left: 25px;
    }

    .ps-spinner::before {
      left: 0;
      margin-left: 0;
      margin-top: 0;
      position: absolute;
      top: 0;
    }
  }

  &.spinner-white .ps-spinner::before {
    border: 2px solid rgba(255, 255, 255, 0.4);
    border-top-color: $ps-white;
  }

  &.spinner-black .ps-spinner::before {
    border: 2px solid rgba(0, 0, 0, 0.4);
    border-top-color: $ps-white;
  }
}
