//
// Labels
// --------------------------------------------------

.label {
  // Right align labels nested inside buttons or items
  .btn &,
  .list-group-item & {
    align-items: center;
    bottom: 6px;
    display: flex;
    padding-bottom: 0;
    padding-top: 0;
    position: absolute;
    right: 6px;
    top: 6px;
  }
}
