//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

// Templates

.template {
  .table-view-container {
    display: flex;
    flex: 1 0 auto;
    flex-flow: column;
  }

  &.template--workflow-focus-bar {
    .widgets-container {
      top: ($ps-navbar-offset + $ps-focus-bar-offset);
    }
  }

  .template-view-bar {
    .nav-tabs {
      width: 100%;
    }
    .nav-tabs > li {
      width: 50%;
    }
  }

  .template-tab-overview {
    background: $ps-gray-50;
    bottom: 0;
    display: flex;
    flex-flow: column;
    left: 0;
    padding: 20px;
    position: fixed;
    right: 0;
    top: ($ps-navbar-offset + $ps-focus-bar-offset);

    .template-header-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .template-tab-automation {
    padding: 0;
    position: relative;
  }

  .template-widgets .widgets a {
  }
}

.template__focus-bar {
  height: 100%;
  min-height: $ps-focus-bar-offset;
  position: relative;
  z-index: 1030;
}

.template__view-bar {
  position: relative;
  z-index: 9999;
}

.template__view-bar--automation {
  margin-top: 0;
  z-index: 1;

  @media (min-width: $screen-md) {
    text-align: left;
    width: 550px;
  }
  // Large devices (desktops, ≥992px)
  @media (min-width: $ps-screen-lg-min) {
    width: 400px;
  }
  @media (min-width: $ps-screen-xl-min) {
    width: 550px;
  }
  // 2XLarge devices (2xlarge desktops, ≥1536px)
  @media (min-width: $ps-screen-2xl-min) {
    width: 576px;
  }
  // 3XLarge devices (3xlarge desktops, ≥1920px)
  @media (min-width: $ps-screen-3xl-min) {
    width: 672px;
  }
}
