//
// Typography
// --------------------------------------------------

// Headings
// -------------------------

h1,
.h1 {
  line-height: 56px;
}
h2,
.h2 {
  line-height: 48px;
}
h3,
.h3 {
  line-height: 32px;
}
h4,
.h4 {
  line-height: 24px;
}
h5,
.h5 {
  line-height: 24px;
}
h6,
.h6 {
  line-height: 16px;
}
