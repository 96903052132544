//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.task-bar-container--editor-ux {
  margin-bottom: var(--ps-sizes-4) !important;

  .task-bar-inner {
    align-items: center;
    padding: 0;
  }

  .template-task-assigner {
    align-items: center;
    display: inline-flex;
    padding-right: 0;

    ps-task-assignments,
    ps-avatar,
    ps-avatar .avatar {
      display: flex;
    }

    .template-task-assigner-avatar.btn-outline-info {
      border-bottom-left-radius: 0;
      border-left: 0;
      border-top-left-radius: 0;
      padding: 0;

      a.assignment {
        display: block;
        height: 100%;
        padding: 0 4px;
        width: 100%;
      }
    }
    .template-task-assigner-avatar--empty.btn-outline-info {
      display: none;
    }

    .avatar .badge,
    .role-assignment-avatar .badge {
      bottom: 0 !important;
      padding: 1px 0 !important;
      right: -2px !important;
    }

    .role-assignment-avatar .initials {
      font-size: var(--ps-fontSizes-sm);
      height: var(--ps-sizes-6);
      line-height: var(--ps-sizes-6);
      width: var(--ps-sizes-6);
    }
  }

  .editor.viewable & {
    .task-bar-template-cell .btn-outline-info {
      border: 0;
    }

    .task-bar-template-cell {
      margin-right: 0;
    }

    .template-task-assigner {
      padding: 0;
    }
  }

  .task-bar-template-cell--active {
    .task-bar-template-cell__button .far {
      color: var(--ps-colors-purple-500);

      .editor.viewable & {
        color: var(--ps-colors-gray-400);
      }
    }
  }

  .task-due-offset {
    align-items: center;
    display: flex;
  }

  .task-bar-template-cell {
    margin-right: var(--ps-sizes-3);

    &:not(.template-task-assigner) {
      padding-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    .btn-outline-info {
      align-items: center;
      border-width: 1px;
      color: var(--ps-colors-gray-400);
      display: flex;
      font-size: var(--ps-fontSizes-sm);
      height: var(--ps-sizes-8);
      padding: 4px 6px;

      &:hover {
        background-color: var(--ps-colors-gray-100);
      }

      i {
        font-size: var(--ps-fontSizes-md);
      }

      i + span {
        margin-left: var(--ps-sizes-1);
      }
    }
  }

  .stop-task-button .fa-stack {
    font-size: var(--ps-fontSizes-md);
    height: 2em;
    line-height: 2em;
  }

  & + .widgets-header-container h3 {
    line-height: 1.5;
    padding-bottom: var(--ps-sizes-4);
    padding-top: 0;
  }

  ps-task-permission-template-button .task-bar-template-cell__button > span,
  .stop-task-button > button > span {
    display: flex;
  }

  .template-task-assigner--has-assignments {
    ps-pop-box-new .btn-outline-info {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
