//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.doodads {
  textarea {
    min-height: ps-size(8);
  }

  .form-comment {
    margin-bottom: 0;
  }

  .help-block {
    font-size: 12px;
    overflow: hidden;
  }

  // Doodad

  .doodad {
    margin-bottom: 20px;
    padding: 0 0 0 40px;
    position: relative;
  }

  .doodad-avatar {
    left: 0;
    position: absolute;
    top: 0;
  }

  .doodad-author-line {
    line-height: 18px;
  }

  .doodad-author {
    font-weight: bold;
  }

  .doodad-date {
    color: $ps-gray-400;
    font-size: 11px;
    line-height: 18px;
  }

  // Comment Doodad

  .doodad-comment {
    min-height: 32px;
  }

  .doodad-comment-content {
    line-height: 18px;
    margin: 10px 0;
    word-wrap: break-word;

    p {
      margin-bottom: 10px;
    }

    blockquote {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 10px;

      p {
        margin-bottom: 0;
      }
    }
  }

  .at-mention {
    background-color: $ps-gray-200;
    border-radius: 3px;
    display: inline-block;
    margin: -1px 0;
    padding: 1px 4px;
  }

  .doodad-interactions-container {
    display: inline;
  }

  // Attachment Doodad

  .doodad-attachment {
    min-height: 32px;
  }

  .doodad-attachment-content {
    margin: 10px 0;
  }

  .doodad-attachment-content-image-container {
    padding: 10px 0 0;
    text-align: center;
  }

  .doodad-attachment-progress {
    margin: 10px 0;
  }

  // Activity Doodad

  .doodad-activity-icon {
    color: $ps-gray-100;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 32px;
  }

  .doodad-activity-content {
    color: $ps-gray-400;
    font-size: 12px;

    a {
      color: $ps-gray-400;
      font-weight: bold;
    }
  }

  .doodad-activity-date {
    margin-left: 10px;
    white-space: nowrap;
  }

  .sentence {
    display: inline;
  }

  .sentence-context {
    display: inline;
    margin-left: 10px;
  }

  @media print {
    [href]::after {
      content: none;
    }
  }

  // Embedded video
  .embedded-video {
    margin-top: 10px;
  }

  // Responsive
  // Small devices (tablets, 768px and up)
  @media (min-width: $screen-sm-min) {
    .help-block {
      overflow: inherit;
    }
  }
}
