//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../styles/boulevard-theme/utilities/size';
@import '../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.templateContentHeadline {
  color: $ps-gray-700;
  font-weight: 500;
  margin: ps-size(0, 4);
  text-align: center;

  @media (min-width: $screen-sm-min) {
    padding: 0;
    text-align: left;
  }
}

.templateContentSubheadline {
  margin: ps-size(0.5, 4, 0);
}

.templateGalleryCategoriesPanel {
  background-color: $ps-gray-50;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ps-size(3);

  @media (min-width: $screen-sm-min) {
    max-width: 275px;
  }
}

.templateContentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: ps-size(3);
}

.dividerContainer {
  align-items: center;
  border-top: 1px solid $ps-gray-100;
  display: flex;
  justify-content: center;
  margin-top: ps-size(4);
  width: 100%;

  span {
    background-color: $ps-gray-50;
    margin-bottom: ps-size(3);
    margin-top: -14px;
    padding: 0 ps-size(1);
  }

  @media (min-width: $screen-sm-min) {
    margin-bottom: ps-size(2);

    span {
      display: none;
    }
  }
}

.plusIcon {
  margin-right: 5px;
}

.spinnerContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 100px;
  width: 100%;
}
