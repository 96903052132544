//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.editor-breadcrumb {
  align-items: center;
  color: $ps-gray-300;
  display: flex;
  font-size: $ps-font-size-xs;
  line-height: ps-size(3);

  .editor-breadcrumb-segment {
    display: inline-block;
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;

    &.btn {
      padding-left: ps-size(1);
      padding-right: ps-size(1);

      .fa-fw {
        margin-right: 0;
      }
    }
  }

  .editor-breadcrumb-segment-more-btn {
    margin-left: 3px;
    padding: 0 6px 2px;

    .fa-stack-lg {
      font-size: $ps-primary-font-size;
    }

    .fa-stack-sm {
      font-size: 7px;
    }
  }

  .editor-breadcrumb-segment-delimiter {
    align-items: center;
    color: $ps-gray-300;
    display: flex;
    font-size: $ps-font-size-xs;
    line-height: ps-size(3);
    padding: ps-size(0, 0.5);
  }
}

.editor-breadcrumb-segment-wrapper {
  display: inline-flex;
  flex-shrink: 2.5;
  min-width: 60px;

  &:last-child {
    flex-shrink: 1;
  }
}
