//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../../../styles/boulevard-theme/utilities/size';
@import '../../../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.approval-rule-subject-tasks-list {
  li:not(:last-child) {
    .approval-rule-subject-tasks-list-item {
      margin-bottom: ps-size(2);
    }
  }

  .approval-rule-subject-tasks-list-item {
    align-items: center;
    display: flex;

    .approval-rule-subject-tasks-list-item__container {
      align-items: flex-start;
      background-color: $ps-white;
      border: 1px solid var(--ps-colors-gray-100);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-bottom: 0;
      min-width: 50%;
      padding: 16px 20px;
      width: 100%;

      .task-due-offset {
        color: var(--ps-colors-gray-400);
        font-size: 14px;
        margin-top: 12px;
        max-width: unset;
        padding-left: 0;
        padding-right: 0;

        .fa-clock {
          display: none;
        }

        .truncate-text {
          margin-left: 0;
        }
      }

      .title {
        color: $ps-brand-500;
        display: block;
        font-size: $ps-primary-font-size;
        font-weight: 500;
        line-height: 24px;
        margin: 0;
        outline: none;
        overflow: hidden;
      }

      .due-date {
        flex-shrink: 1;
        overflow: hidden;
        text-overflow: ellipsis;

        .task-due-offset {
          width: 100%;
        }
      }

      .body {
        color: $ps-gray-500;
        font-size: 14px;
        font-style: italic;
        height: 19px;
        line-height: 19px;
        margin-top: 10px;
        width: 182px;
      }

      .content {
        flex-grow: 1;
        margin-top: 12px;
      }
    }

    .btn-delete {
      margin-left: $ps-base-unit;
    }
  }
}
