//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.steps-header {
  align-items: center;
  background-color: $ps-white;
  display: flex;
  margin: 8px -20px 0;
  padding: 8px 20px;
  position: sticky;
  top: -1px;
  width: calc(100% + 40px);
  z-index: 2;

  @media (min-width: $screen-sm-min) {
    background-color: transparent;
    transition: background-color 500ms linear;

    &.is-pinned {
      background-color: $ps-brand-50;
      transition: background-color 500ms linear;
    }
  }

  p {
    color: $ps-gray-500;
    padding: 0 13px;
  }

  textarea {
    @include form-control-focus();
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  textarea:hover {
    background-color: $ps-brand-100;
  }

  textarea:hover:not(:focus) {
    cursor: pointer;
  }

  textarea:focus {
    background-color: $ps-white;
  }

  ps-checklist-name-input {
    width: 100%;
  }
}

.steps-header > textarea {
  color: $ps-gray-700;
  font-size: $ps-font-size-lg;
  font-weight: $ps-font-style-medium;
  line-height: 30px;

  &[disabled]:hover {
    background-color: transparent;
    cursor: default;
  }
}
