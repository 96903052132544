//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.csv-export-modal {
  .export-options {
    &:first-child {
      margin-bottom: 16px;
    }

    label {
      border-radius: 4px;
      box-shadow: inset 0 0 0 1px $ps-gray-500;
      display: block;
      padding: 15px 15px 15px 44px;

      text-align: left;

      &:hover {
        background-color: $ps-gray-50;
      }
    }

    input[type='radio'] {
      left: 36px;
      position: absolute;
      top: 14px;
    }

    input[type='radio']:active ~ label {
      opacity: 1;
    }

    input[type='radio']:checked ~ label {
      background-color: rgba($ps-brand-500, 0.1);
      border: 0;
      box-shadow: inset 0 0 0 2px $ps-brand-500;
      opacity: 1;
    }

    p {
      margin-bottom: 0;
    }
  }

  .modal-footer button {
    width: 100px;
  }
}
