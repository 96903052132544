@mixin ps-alert-variant($background, $text-color, $close-color, $close-color-hover) {
  @include alert-variant($background, $background, $text-color);
  .close {
    color: $close-color;

    &:hover {
      color: $close-color-hover;
    }
  }
}
