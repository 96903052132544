//
// Buttons
// --------------------------------------------------

// Defaults
// --------------

.btn-default,
.btn-info,
.btn-outline-info,
.btn-transparent,
.btn-transparent-info {
  border-width: 1px;
  font-weight: 400;
}

.btn {
  *[class*='fa-'] + span,
  *[class*='far'] + span {
    margin-left: ps-size(1);
  }

  &-sm {
    *[class*='fa-'] + span,
    *[class*='far'] + span {
      margin-left: ps-size(0.5);
    }
  }
}

// Primary buttons
// ---------------

.btn-primary {
  @include button-primary($ps-brand-500, $ps-brand-600, $ps-brand-300);
}

// Outline buttons
// --------------

.btn-outline-primary {
  @include button-outline($ps-brand-500, $ps-brand-200);
}

.btn-outline-success {
  @include button-outline($brand-success, $brand-success-disabled);
}

.btn-outline-warning {
  @include button-outline($brand-warning, $brand-warning-disabled);
}

.btn-outline-info {
  @include button-outline($btn-info-color, $brand-info-disabled);
  background-color: $ps-white;
  border-color: $btn-info-border;
  color: $btn-info-color;

  &:hover {
    background-color: $brand-info-disabled;
  }
}

.btn-outline-danger {
  @include button-outline($brand-danger, $brand-danger-disabled);
}

// Transparent buttons
// --------------

.btn-transparent-primary {
  @include button-transparent($brand-primary, $brand-primary-disabled, $ps-white);
}

.btn-transparent-success {
  @include button-transparent($brand-success, $brand-success-disabled, $ps-white);
}

.btn-transparent-warning {
  @include button-transparent($brand-warning, $brand-warning-disabled, $ps-white);
}

.btn-transparent-info {
  @include button-transparent($brand-info, $brand-info-disabled, $btn-info-color);
  color: $btn-info-color;
  &:hover {
    background-color: $ps-gray-100;
  }

  &.focus,
  &:focus {
    background-color: $btn-info-bg;
    box-shadow: unset;
  }

  .far {
    color: $ps-gray-500;
  }
}

.btn-transparent-danger {
  @include button-transparent($brand-danger, $brand-danger-disabled, $ps-white);
}

// This isn't an official Boulevard style but we use it
// enough to warrant a common class
.btn-md {
  color: $ps-gray-600;
  font-size: $ps-font-size-md;
  padding: ps-size(0.5, 1);
}

// Responsive buttons
// --------------

.btn-stretch {
  width: 100%;
}

.btn-stretch-xs {
  width: 100%;
}

.btn-stretch-sm {
  width: 100%;
}

// Extra small devices (phones, 480px and up)
@media (min-width: $screen-sm-min) {
  .btn-stretch-xs {
    width: auto;
  }
}

// Small devices (tablets, 768px and up)
@media (min-width: $screen-md-min) {
  .btn-stretch-sm {
    width: auto;
  }
}
