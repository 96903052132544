//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.checklist-left-pane {
  .checklist-steps-scroller {
    height: 100%;

    .steps {
      overflow: initial;
    }

    &_content {
      overflow-y: initial;
    }
  }
}

.checklist-left-pane_breadcrumbs-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: ps-size(1);

  ps-editor-breadcrumb {
    overflow: hidden;
  }
}

.editor {
  .steps-container.ms-teams {
    margin-top: 0;
    min-height: 100vh;
    top: 4px; //progress bar height
  }
}

.back-to-inbox {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-left: 12px;
  padding-top: 20px;
  a {
    color: $ps-gray-500;
    font-size: 14px;
  }
}

.checklist-left-pane-steps-container {
  min-height: calc(100vh - #{$ps-navbar-offset});
}
