//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.pillSmall {
  border-radius: 15px;
  display: inline-block;
  font-size: ps-size(1.5);
  font-weight: bold;
  height: ps-size(3);
  line-height: ps-size(3);
  text-align: center;
  width: 87px;
}

.pillLarge {
  border-radius: 18px;
  font-size: ps-size(2);
  font-weight: $ps-font-style-medium;
  height: ps-size(4);
  line-height: ps-size(4);
  text-align: center;
  width: 103px;
}

.archived {
  &:global(.solid) {
    background-color: $ps-purple;
    color: $ps-white;
  }

  &:global(.light) {
    background-color: $ps-purple-200;
    color: $ps-gray-700;
  }

  &:global(.pastel) {
    background-color: $ps-purple-100;
    color: $ps-purple;
  }
}

.completed {
  &:global(.solid) {
    background-color: $ps-green;
    color: $ps-white;
  }

  &:global(.light) {
    background-color: $ps-green-200;
    color: $ps-gray-700;
  }

  &:global(.pastel) {
    background-color: $ps-green-100;
    color: $ps-green;
  }
}

.overdue {
  &:global(.solid) {
    background-color: $ps-red;
    color: $ps-white;
  }

  &:global(.light) {
    background-color: $ps-red-200;
    color: $ps-gray-700;
  }

  &:global(.pastel) {
    background-color: $ps-red-100;
    color: $ps-red;
  }
}

.dueSoon {
  &:global(.solid) {
    background-color: $ps-yellow;
    color: $ps-white;
  }

  &:global(.light) {
    background-color: $ps-yellow-200;
    color: $ps-gray-700;
  }

  &:global(.pastel) {
    background-color: $ps-yellow-100;
    color: $ps-yellow;
  }
}

.onTrack {
  &:global(.solid) {
    background-color: $ps-blue-500;
    color: $ps-white;
  }

  &:global(.light) {
    background-color: $ps-blue-200;
    color: $ps-gray-700;
  }

  &:global(.pastel) {
    background-color: $ps-blue-100;
    color: $ps-blue-500;
  }
}
