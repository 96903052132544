//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../styles/boulevard-theme/utilities/size';
@import '../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.approval-subject-task-widget-renderer {
  .control-label {
    color: var(--ps-colors-gray-400) !important;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 2px;
    word-wrap: break-word;
  }

  .value {
    color: var(--ps-colors-gray-600);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    word-wrap: break-word;
  }
}
