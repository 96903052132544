//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../styles/boulevard-theme/utilities/size';
@import '../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.inbox-item-modal {
  box-shadow: -4px 1px 9px 0 rgba(0, 0, 0, 0.1);

  .modal-header {
    border-bottom: 0;
    display: flex;

    justify-content: space-between;
    padding: ps-size(2, 2, 1);

    @media (min-width: 360px) {
      padding: ps-size(1);
    }
    @media (min-width: 480px) {
      padding: ps-size(2, 2, 1);
    }

    @media (min-width: 768px) {
      padding: ps-size(4, 4, 3);
    }

    .close {
      flex-grow: 0;
      flex-shrink: 0;
      margin-top: 2px;
    }

    // hide hr line
    &:after {
      height: 0;
    }

    .hr {
      border-top: 1px solid $ps-gray-100;
      margin-bottom: 5px;
      margin-top: 0;
    }
  }
  hr {
    border-top: 1px solid $ps-gray-100;
    margin-bottom: 0;
    margin-top: 0;
  }
  .title-container {
    display: inline-block;
    width: calc(100% - 30px);

    .title {
      a {
        color: $ps-gray-700;
        font-size: $ps-font-size-lg - 2;
        font-weight: $ps-font-style-medium;
        line-height: ps-size(3);
        text-decoration: none;

        &:hover {
          color: $ps-brand-500;
        }
      }

      span.masked {
        color: $ps-gray-700;
        text-decoration: none;
      }
    }

    .btn-inbox-item-navigate {
      color: $ps-gray-200;
      cursor: pointer;
      font-size: 14px;
      margin-right: 5px;
      padding: 0 10px;
      text-align: center;
      &:hover {
        color: $ps-gray-400;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .subtitle-container {
    display: block;
    font-size: 13px;
    font-weight: normal;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 2px;
    position: relative;

    a {
      color: $ps-gray-500;
      text-decoration: none;

      &:hover {
        color: $ps-brand-500;
      }
    }
  }

  .subtitle-column {
    display: inline-block;
    max-width: 48.5%;
    padding: 0 0 0 10px;
    position: relative;
  }

  .subtitle-column:first-child {
    padding: 0 10px 0 0;
  }

  .subtitle-separator::after {
    color: $ps-gray-500;
    content: '|';
    font-size: 12px;
    position: absolute;
    right: -4px;
    top: 0;
  }

  .truncate {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .modal-body {
    height: calc(100vh - 170px);
    overflow-y: auto;
    padding: 0;

    .checklist-details-controls {
      margin-top: ps-size(1);
    }

    .scrollable {
      padding: 15px;
    }

    .inbox-alerter {
      margin: 50px 15px 0;

      .alert {
        margin-bottom: 10px;
      }
    }

    .inbox-cell-1 {
      float: left;
      padding-left: 15px;
    }

    .inbox-cell-2 {
      float: left;
      padding-left: 5px;
    }

    .ps-snoozer {
      display: inline-block;
    }

    .widget-outer {
      border: 1px solid transparent;
      padding: 14px 0;
    }

    .widgets-task-status-toggle-btn-container {
      align-items: center;
      display: flex;
      justify-content: flex-end;

      .btn {
        margin-bottom: 4px;
      }

      .next-btn {
        margin-right: var(--ps-space-2);
      }
    }

    .doodads-inbox {
      margin: 10px auto;
    }
  }

  .modal-title {
    display: inline-block;
    width: calc(100% - $ps-navbar-offset);

    .title,
    .sub-title {
      display: block;
    }

    .title {
      font-size: 18px;
    }

    .sub-title {
      font-size: 12px;
    }

    .truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }

  //task list item
  .task-list-item {
    .step-show-hide {
      width: 18px;
    }

    .step-flares {
      right: 16px;
    }

    .step-number-container {
      background-color: transparent;
    }
  }

  .btn-inbox-navigate {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .due-date-picker-wrapper {
    display: none;
  }

  &.my-work {
    left: unset;
    top: unset;
    width: 100%;

    .ps-snoozer {
      display: none;
    }

    .due-date-picker-wrapper {
      display: block;
    }

    .modal-dialog {
      height: calc(100vh - var(--ps-navbar-offset));
      margin: 0;
    }

    // These styles resets angular-bootstrap modal animation.
    // We do this to be able to apply our own animation (drawer animation).
    &.fade,
    &.fade.in {
      opacity: 1;

      .modal-dialog {
        opacity: 1;
        transform: unset;
        transition: unset;
      }
    }

    &.ng-enter,
    &.ng-leave {
      animation-duration: 0.2s;
      animation-name: drawer-animation;
      animation-timing-function: ease-in;
    }

    &.ng-leave {
      animation-direction: reverse;
      animation-timing-function: ease-out;
    }

    .modal-content {
      border-bottom: 0;
      border-color: var(--ps-colors-gray-200);
      border-radius: 0;
      border-right: 0;
      border-top: 0;
      height: 100%;
      max-width: 100vw;
    }

    + .modal-backdrop.in {
      opacity: 0;
    }

    .checklist-task-assigner {
      align-items: flex-start;
    }

    .modal-inbox-medium {
      width: 772px;
    }
  }

  .btn-complete {
    font-size: 14px;
    margin-bottom: 16px;
  }
} // end .inbox-item-modal

@keyframes drawer-animation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

// Extra extra small devices (360px and up) (non-standard, normally use $screen-xs-min)//
@media (min-width: 360px) {
  .inbox-item-modal {
    .task-due-date .btn,
    .checklist-due-date .btn {
      font-size: 14px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

// Small devices (tablets, 768px and up)
@media (min-width: $screen-sm-min) {
  .inbox-item-modal {
    &.my-work {
      width: auto;
    }

    .subtitle-column {
      display: inline-block;
      max-width: 49.5%;
    }

    .modal-body {
      height: calc(100vh - 176px);
      min-height: 328px;
      padding: 0;

      .bottom-menu {
        .controls {
          margin-right: 10px;
        }
      }

      .controls {
        float: right;
        margin-right: 30px;
      }

      .scrollable {
        padding: ps-size(4);
      }

      .inbox-alerter {
        margin: 50px 30px 0;
      }
    }

    .modal-footer.approval {
      padding: 15px 45px;
    }

    .modal-dialog {
      margin: 30px auto;
    }

    .modal-content {
      border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .inbox-navigate {
      bottom: 0;
      color: $ps-gray-200;
      cursor: pointer;
      margin-top: 0;
      padding-top: 150px;
      position: fixed;
      text-align: center;
      top: 0;
      width: 70px;
    }

    .inbox-navigate-previous {
      left: -70px;

      &:hover,
      :active {
        color: $ps-white;
      }
    }

    .inbox-navigate-next {
      right: -70px;

      &:hover,
      :active {
        color: $ps-white;
      }
    }
  }
}
