//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../styles/boulevard-theme/utilities/size';
@import '../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.field-members {
  .list-title {
    clear: both;
    color: $ps-gray-400;
    display: block;
    font-size: $ps-font-size-xs;
    font-weight: 600;
    line-height: 16px;
    padding: 3px 12px;
    white-space: nowrap;
  }

  .ui-select-bootstrap {
    .ui-select-match {
      box-shadow: none;
      outline: none;
    }

    .ui-select-match-text {
      .group-name {
        display: block;
        margin-left: ps-size(1);
      }
    }

    .ui-select-choices {
      margin-top: ps-size(0.5);
      opacity: 1 !important;
      padding: ps-size(0.5);
    }

    .ui-select-footer {
      color: $ps-gray-400;
      font-size: $ps-font-size-xs;
      font-style: italic;
      padding: ps-size(1) ps-size(1.5);
    }

    .selected-group-container {
      align-items: center;
      display: flex;
    }

    .ui-select-choices-row-inner {
      border-radius: ps-size(0.5);

      .group-name,
      .user-name {
        padding-left: ps-size(1);
        width: auto;
      }
    }
  }

  label:empty::before {
    color: $ps-gray-400;
    content: '(' attr(data-placeholder) ')';
  }

  .required-span {
    color: $ps-red-500;
  }
}
