//
// Navbars
// --------------------------------------------------

.navbar {
  border: 0;
  border-bottom: 2px solid $ps-gray-200;
  text-align: center;

  // Override Bootstraps default mobile navbar stacking
  .navbar-nav > li,
  .navbar-nav {
    float: left !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;

    > li > a {
      padding-bottom: $navbar-padding-vertical;
      padding-top: $navbar-padding-vertical;
    }
  }

  .navbar-center {
    display: inline-block;
    float: none !important;
    vertical-align: top;
  }

  .navbar-right {
    float: right !important;
  }
}
