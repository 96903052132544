//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.blvd-select {
  .blvd-select__ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .blvd-select__control {
    &[aria-invalid='true'] {
      border-color: var(--ps-colors-red-500);
      box-shadow: 0 0 0 1px var(--ps-colors-red-500);
    }

    .blvd-select__dummy-input {
      background: 0;
      border: 0;
      color: transparent;
      font-size: inherit;
      grid-area: 1/1/2/3;
      opacity: 0;
      outline: 0;
      padding: 0;
      position: relative;
      transform: scale(0);
      width: 1px;
    }

    .blvd-select__indicator-separator {
      display: none;
    }

    .blvd-select__fixed-sized-value-container {
      .blvd-select__multi-value {
        flex-shrink: 0;
      }

      .blvd-select__multi-value__label {
        flex-wrap: nowrap;
        overflow: initial;
      }

      .blvd-select__overflowing-values-indicator {
        align-items: center;
        background-color: $ps-gray-100;
        color: $ps-gray-600;
        display: flex;
        font-size: $ps-primary-font-size;
        height: ps-size(3.5);
        margin: 3px 4px;
        padding: 0 ps-size(1);
      }
    }

    .blvd-select__single-value {
      align-items: center;
      color: $ps-gray-600;
      display: flex;
      flex: 1 1 0;
      grid-area: 1/1/2/3;
      line-height: var(--ps-lineHeights-base);
      overflow: hidden;
    }

    .blvd-select__single-value__icon {
      margin-right: ps-size(0.5);
    }

    .blvd-select__multi-value {
      .blvd-select__multi-value__remove {
        align-items: center;
        color: $ps-gray-600;
        display: flex;
        font-size: $ps-primary-font-size;
        padding: 2px ps-size(1) 0;
      }
    }
  }

  .blvd-select__menu {
    z-index: var(--ps-zIndices-dropdown);

    .blvd-select__menu__header {
      display: flex;
      padding: ps-size(1);

      .blvd-select__menu__header__search-input {
        border-radius: ps-size(0.5);
        color: $ps-gray-500;
        height: ps-size(5);
        line-height: 20px;
        padding: ps-size(0, 1.5, 0, 4);
        width: 100%;

        ::placeholder {
          color: $ps-gray-400;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .blvd-select__menu__header__search-icon {
        color: $ps-gray-500;
        left: ps-size(2.5);
        position: absolute;
        top: 20px;
      }
    }

    .blvd-select__option__wrapper {
      padding: 0;
      white-space: nowrap;
    }

    .blvd-select__group-heading {
      padding-left: var(--ps-sizes-2);
      padding-right: var(--ps-sizes-2);
    }

    .blvd-select__option {
      align-items: center;
      background-color: $ps-white;
      border-radius: 0;
      color: $ps-gray-600;
      display: flex;
      justify-content: space-between;
      line-height: var(--ps-lineHeights-base);
      padding-bottom: var(--ps-sizes-2);
      padding-left: var(--ps-sizes-2);
      padding-right: var(--ps-sizes-2);
      padding-top: var(--ps-sizes-2);

      @at-root #{&}--is-selected {
        background-color: var(--ps-colors-brand-100);
        color: var(--ps-colors-brand-500);
      }

      @at-root #{&}--is-focused {
        background-color: var(--ps-colors-brand-100);
        color: var(--ps-colors-brand-500);
      }

      .blvd-select__option__icon {
        margin-right: ps-size(0.5);
      }

      .blvd-select__option__label {
        max-width: calc(100%);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .blvd-select__menu-list {
      border-radius: 4px;
      padding: 0;
    }

    .blvd-select__menu-list:not(.blvd-select__menu-list--is-multi) .blvd-select__option .far.fa-check {
      display: none;
    }

    .blvd-select__menu-list--is-multi {
      .blvd-select__option {
        @at-root #{&}--is-selected {
          background-color: $ps-white;
          color: $ps-gray-600;
        }

        @at-root #{&}--is-focused {
          background-color: $ps-brand-100;
          color: $ps-gray-600;
        }
      }
    }

    .blvd-select__menu-list--overflow-hidden {
      overflow: hidden !important;
    }

    .blvd-select__menu__separator {
      margin: 0;
    }

    .blvd-select__menu-notice {
      font-size: 16px;
    }
  }
}
