//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.template-header {
  margin: 0;
  width: 100%;

  .flex {
    align-items: center;
    display: flex;
  }

  .flex-grow {
    flex-grow: 1;
  }

  .template-header-description {
    color: $ps-gray-500;
    display: inline-block;
    font-size: var(--ps-fontSizes-sm);
    line-height: var(--ps-lineHeights-base);
    margin-bottom: var(--ps-space-1);
    padding: 0 9px;
  }

  textarea {
    @include form-control-focus();

    &:nth-child(2) {
      color: $ps-gray-500;
    }

    background-color: transparent;
    border-color: transparent;
    box-shadow: none;

    &:hover {
      background-color: $ps-gray-50;
    }

    &:hover:not(:focus) {
      cursor: pointer;
    }

    &:focus {
      background-color: $ps-white;
    }
  }

  .template-header-public-btn {
    background-color: $ps-yellow-100;
    border-radius: 15px;
    color: $ps-yellow-600;
    font-weight: bold;
    padding: ps-size(0.5, 1);

    .far {
      color: $ps-yellow-600;
    }
  }
}
