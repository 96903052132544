//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.data-set-link {
  .data-set-link__items {
    list-style-type: none;
    padding-left: 0;
    position: relative;

    .data-set-link__item {
      background-color: var(--ps-colors-white);
      border: 1px solid var(--ps-colors-gray-200);
      border-radius: 4px;
      color: var(--ps-colors-gray-600);
      display: flex;
      font-size: var(--ps-fontSizes-md);
      margin-left: var(--ps-space-10);
      padding: 8px 12px;

      &:not(:last-of-type) {
        margin-bottom: var(--ps-space-3);
      }
    }

    .data-set-link__index {
      color: var(--ps-colors-gray-400);
      font-size: var(--ps-fontSizes-sm);
      left: var(--ps-space-4);
      position: absolute;
    }
  }

  .data-set-link__count {
    color: var(--ps-colors-gray-400);
    text-align: center;
  }

  .data-set-link__info-container {
    display: flex;
    flex-direction: row;
  }

  .data-set-link__info {
    display: flex;
    flex-direction: column;
  }

  .data-set-link__saved-view-name {
    z-index: 1;
  }

  .data-set-link__name {
    color: var(--ps-colors-gray-500);
    font-size: var(--ps-fontSizes-sm);
  }

  .data-set-link__icon-container {
    align-items: center;
    background-color: var(--ps-colors-white);
    border: 1px solid var(--ps-colors-gray-300);
    border-radius: 4px;
    display: flex;
    flex-basis: 40px;
    height: 40px;
    justify-content: center;
    margin-right: var(--ps-space-4);
    margin-top: 3px;
    min-width: 40px;
  }

  .data-set-link__container {
    background-color: var(--ps-colors-white);
    border: 1px solid var(--ps-colors-gray-300);
    border-radius: 16px;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: var(--ps-space-4);
    padding: 24px 20px 16px 0;
  }
}
