//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.numberInput {
  display: inherit;
  position: relative;
  width: 366px;

  input {
    border-radius: ps-size(0.5);
    font-size: ps-size(2);
    height: $ps-dashboard-input-height;
    line-height: ps-size(2.5);
    padding: 0 ps-size(2);
    width: 366px;

    &:focus {
      box-shadow: none;
    }
  }
}
