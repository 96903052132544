//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../../../../styles/boulevard-theme/utilities/size';
@import '../../../../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.checklistDashboardConditionalFilter {
  margin-bottom: ps-size(1);
  width: 100%;

  @media (min-width: $screen-sm-min) {
    width: auto;
  }

  button:global(#ChecklistDashboardConditionalFilterDropdown) {
    border: 1px solid $ps-gray-300;
    border-radius: 4px;
    height: $ps-dashboard-input-height;
    line-height: ps-size(3);
    padding: 0 ps-size(1.5);
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: 100%;

    @media (min-width: $screen-sm-min) {
      width: auto;
    }
  }
}

.editor {
  width: 100%;

  & > :global(.dropdown-menu) {
    padding: ps-size(3);
  }
}

.buttonActive {
  button:global(#ChecklistDashboardConditionalFilterDropdown) {
    background-color: $ps-brand-100;
    border-color: $ps-brand-500;
    color: $ps-brand-500;
    font-weight: 500;

    &:hover,
    &:focus {
      background-color: $ps-brand-500;
      color: $ps-brand-100;
    }
  }
}

.buttonInactive {
  button:global(#ChecklistDashboardConditionalFilterDropdown) {
    background-color: $ps-white;
    border-color: $ps-gray-300;
    color: $ps-gray-600;

    &:hover {
      background-color: $ps-gray-100;
    }
  }
}

.addFilter {
  color: $ps-brand-500;
  font-size: $ps-font-size-xs;
  line-height: ps-size(2);
  padding-top: ps-size(2);
}

.noFiltersAppliedLabel {
  color: $ps-gray-400;
  line-height: ps-size(3);
  margin: 0;
  white-space: nowrap;
}
