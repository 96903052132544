//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.template-view-bar {
  .template-view-bar__inner {
    align-items: center;
    background: var(--ps-colors-brand-50);
    border-width: 0;
    display: flex;
    min-height: 62px;
    padding-top: 8px;
    position: relative;
    width: 100%;
  }

  .import-container {
    align-items: center;
    background: var(--ps-colors-gray-100);
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    left: 0;
    padding-left: 24px;
    padding-right: 24px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .template-view-nav {
    border-bottom: 0;
    margin: 0;
  }

  // Responsive

  // Small devices (tablets, 768px and up)
  @media (min-width: $screen-sm-min) {
  }

  // Medium devices (desktops, 992px and up)
  @media (min-width: $screen-md-min) {
  }

  // Large devices (large desktops, 1280px and up)
  @media (min-width: $screen-lg-min) {
  }
}
