//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

// FIXME This CSS is also depended upon by the psChecklistLeftPane component
.editor {
  .steps-container {
    background-color: $ps-white;
    border-bottom-color: darken($panel-default-border, 20%);
    bottom: 0;
    left: 0;
    min-height: calc(100vh - #{$ps-navbar-offset});
    overflow: hidden;
    position: fixed;
    top: $ps-navbar-offset;
    width: 100%;
    z-index: 10;
  }

  &.viewable .steps-container {
    top: $ps-navbar-offset;
  }
  &.editable .steps-container {
    top: ($ps-navbar-offset + $ps-focus-bar-offset);
  }
  &.viewable .steps-container.steps-container--workflow-focus-bar,
  &.editable .steps-container.steps-container--workflow-focus-bar {
    min-height: calc(100vh - #{$ps-focus-bar-offset});
    top: calc(#{$ps-navbar-offset} + #{$ps-focus-bar-offset});
  }

  &.widgets-shown .steps-container {
    background-color: var(--ps-colors-gray-50);
    display: none;
    width: 100%;
  }

  .steps-top-bar {
    align-items: center;
    background-color: var(--ps-colors-gray-100);
    display: flex;
    height: 60px;
    justify-content: flex-end;
    padding-bottom: 4px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 4px;
    width: 100%;
  }
  .template-steps-scroller {
    height: calc(100%);
  }

  &.editable .template-steps-scroller {
    // Bottom Toolbar 54px
    height: calc(100% - 54px);
  }

  .message {
    @include gradient-striped(rgba(255, 255, 255, 0.8));

    background-color: $ps-gray-50;
    background-size: 1pc 1pc;
    margin: 0 auto;
    padding: 20px 32px;
    width: 550px;
  }

  &.checkable .message {
    padding-top: 25px;
  }

  .steps-header-public-btn {
    color: $ps-gray-300;
    padding: 5px 12px;
  }

  .steps-header-public-btn-text {
    display: none;
  }

  .steps-header-share-btn {
    cursor: help;
    font-size: 12px;
    margin-left: 15px;
  }

  .steps {
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
  }

  .steps-bottom-bar {
    width: 100%;
    z-index: 3;

    .btn:hover {
      background-color: $ps-brand-100;
    }
  }

  .steps-bottom-bar-inner {
    background-color: $ps-gray-50;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    margin: 10px auto;
    text-align: center;
    width: 100%;

    .btn-group {
      display: flex;
    }

    .btn {
      border-bottom: 0;
      border-radius: 0;
      border-top: 0;
      color: $ps-gray-600;
      flex: 1;
      font-size: $ps-font-size-xs;
      padding-left: ps-size(2);
      padding-right: ps-size(2);

      + ps-task-approval-button .btn {
        border-left-width: 1px;
      }

      &:hover {
        color: $ps-brand-400;
      }

      &:last-child {
        border-right-width: 0;
      }

      &:first-child {
        border-left-width: 0;
      }
    }
  }

  .steps-tip {
    color: $ps-gray-500;
    margin-bottom: 30px;
    padding: 10px 20px;
  }

  .steps-description {
    color: $ps-gray-500;
    margin: 10px 0 20px;

    textarea {
      @include form-control-focus();
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }

    textarea:hover {
      background-color: $ps-gray-50;
    }

    textarea:hover:not(:focus) {
      cursor: pointer;
    }

    textarea:focus {
      background-color: $ps-white;
    }
  }

  // Small devices (tablets, 768px and up)
  @media (min-width: $screen-sm-min) {
    &.widgets-shown .steps-container,
    .steps-container {
      background-color: unquote('rgba(from #{$ps-brand-50} r g b / 0.8)');
    }
  }

  // Medium devices (desktops, ≥768px)
  @media (min-width: $screen-md-min) {
    .steps {
      overflow-x: hidden;
      width: 400px;
    }

    &.widgets-shown .steps-container,
    .steps-container {
      display: block;
    }

    &.widgets-shown .steps-container,
    .steps-container,
    .steps-bottom-bar-inner {
      width: 400px;
    }
  }

  // Large devices (desktops, ≥992px)
  @media (min-width: $ps-screen-lg-min) {
    .steps {
      width: 400px;
    }

    &.widgets-shown .steps-container,
    .steps-container,
    .steps-bottom-bar-inner {
      width: 400px;
    }
  }

  @media (min-width: $ps-screen-xl-min) {
    .steps {
      width: 550px;
    }

    &.widgets-shown .steps-container,
    .steps-container,
    .steps-bottom-bar-inner {
      width: 550px;
    }
  }
  // 2XLarge devices (2xlarge desktops, ≥1536px)
  @media (min-width: $ps-screen-2xl-min) {
    .steps {
      width: 576px;
    }

    &.widgets-shown .steps-container,
    .steps-container,
    .steps-bottom-bar-inner {
      width: 576px;
    }
  }
  // 3XLarge devices (3xlarge desktops, ≥1920px)
  @media (min-width: $ps-screen-3xl-min) {
    .steps {
      width: 672px;
    }

    &.widgets-shown .steps-container,
    .steps-container,
    .steps-bottom-bar-inner {
      width: 672px;
    }
  }
}

.template--view .editor .steps,
.checklist .editor .steps {
  padding-bottom: 50px;
}
