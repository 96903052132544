.add-rules-modal {
  .upgrade-title {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .explanation-box {
    padding: 30px;

    p {
      margin-top: 10px;
    }
  }

  .upgrade-btn-box {
    padding-bottom: 30px;
  }
}
