// Font Awesome
$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';

@import '~@fortawesome/fontawesome-pro/scss/brands';
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/solid';

.fas-workflow:before {
  content: '\e000';
}
.far-workflow:before {
  content: '\e002';
}
.fak {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: 'Font Awesome Kit';
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

@font-face {
  font-family: 'Font Awesome Kit';
  font-style: normal;
  // sass-lint:disable no-url-protocols
  src: url('https://kit.fontawesome.com/de7300b024/83642242/kit-upload.woff2') format('woff2');
}
