//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../styles/boulevard-theme/utilities/size';
@import '../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.sandboxed-iframe {
  border: 1px $panel-default-border solid;
  border-radius: 4px;
  overflow: hidden;

  .domain-bar {
    background: $panel-default-heading-bg;
    border-bottom: 1px $panel-default-border solid;
    padding: 4px;
    text-align: center;
  }

  .domain {
    color: $panel-default-text;
    font-weight: bold;
  }
}
