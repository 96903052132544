//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../styles/boulevard-theme/utilities/size';
@import '../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../styles/boulevard-theme/boulevard-variables';
@import '../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.image-spinner-area {
  align-content: center;
  background: $ps-gray-50;
  border-radius: 4px;
  color: $ps-gray-500;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  height: 150px;
  justify-content: center;
  line-height: 150px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 200px;

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  .image-spinner-dark-bg::before {
    animation: spinner 0.6s linear infinite;
    border: 2px solid $ps-white;
    border-radius: 50%;
    border-top-color: $ps-gray-200;
    box-sizing: border-box;
    content: '';
    height: 20px;
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
    width: 20px;
  }
}
