//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../styles/boulevard-theme/utilities/size';
@import '../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../styles/boulevard-theme/boulevard-variables';
@import '../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.doodad {
  margin-bottom: 20px;
  padding: 0 0 0 40px;
  position: relative;
}

.commentAuthorAvatar {
  left: 0;
  position: absolute;
  top: 0;
}

.commentAuthor {
  font-weight: bold;
}

.commentContent {
  margin: 10px 0;
  word-wrap: break-word;

  p {
    margin-bottom: 10px;
  }

  blockquote {
    font-size: 14px;
    margin-bottom: 10px;

    p {
      margin-bottom: 0;
    }
  }
}

.embeddedVideo {
  margin-top: 10px;
}

.commentDate {
  color: $ps-gray-400;
  font-size: 11px;
}

.commentInteractionsContainer {
  display: inline;
}
