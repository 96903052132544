//
// Tables
// --------------------------------------------------

th {
  @extend .uppercase-capitalized;
}

table.table {
  tbody {
    tr {
      td {
        height: $ps-row-height;
        padding: 0 8px;

        &:first-child {
          padding-left: ps-size(0.5);
        }
        &:last-child {
          padding-right: ps-size(3);
        }
      }
    }
  }

  &.table-spaced {
    tbody > tr > td {
      &:first-child {
        padding-left: ps-size(2);
      }
    }
  }

  &.table-middle-aligned {
    tbody > tr > td {
      vertical-align: middle;
    }
  }

  &.table-hover > tbody > tr:hover > td {
    background-color: $ps-brand-500;
    color: $ps-white;
  }
}

// Sentences
// --------------
//
// Used in a lot of the activity lists

.sentences {
  .list-group-item {
    align-self: center;
    display: flex;
    padding-bottom: ps-size(1.5);
    padding-left: ps-size(8);
    padding-top: ps-size(1.5);

    .activity {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .sentence {
      line-height: initial;
      margin-bottom: ps-size(0.5);

      .subject {
        font-weight: bold;
      }

      .quote {
        font-size: 13px;
        margin: 10px 10px 12px;
      }

      .image-container {
        margin: 15px 20px;

        img {
          margin: 0 auto;
          max-height: 300px;
        }
      }
    }

    .sentence-context {
      color: $ps-gray-500;
      line-height: initial;
      margin-top: 0;
    }

    .avatar {
      left: ps-size(3);
      position: absolute;
      top: 18px;
    }
  }

  .sentence-context {
    color: $ps-gray-500;
    font-size: 12px;
    margin-top: 2px;

    a {
      color: inherit;
    }

    .dot {
      padding: 0 2px;
    }

    .date {
      color: $ps-gray-400;
      white-space: nowrap;
    }
  }
}
