//
// SCSS Module Imports
// --------------------------------------------------
// These are imported into every CSS/SCSS module

// Mixins
// --------------

@import '../../../../../styles/boulevard-theme/utilities/size';
@import '../../../../../styles/boulevard-theme/mixins/icons.mixin';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Variables
// --------------

@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';
@import '../../../../../styles/boulevard-theme/boulevard-variables';
@import '../../../../../styles/boulevard-theme/vendor/bootstrap/bootstrap-variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.webhook-row-container {
  padding: 5px 0 25px;

  .trigger-selection {
    > span > a {
      color: $ps-gray-600;
      display: block;
      overflow: hidden;
      padding: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      &.dropdown-option-heading {
        font-weight: bold;
        padding-left: 22px;
      }
    }

    > span > a:hover,
    > span > a:focus {
      background-color: $ps-gray-50;
      color: $ps-gray-700;
      text-decoration: none;
    }

    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      background-color: $ps-brand-500;
      color: $ps-white;
      outline: 0;
      text-decoration: none;
    }
  }

  .webhook-trigger-checkbox {
    align-items: center;
    background-color: $ps-white;
    border: 1px solid $ps-checkbox-border-color;
    border-radius: 4px;
    display: inline-flex;
    height: 2rem;
    justify-content: center;
    margin-right: ps-size(1);
    width: 2rem;

    &.checked {
      background-color: $ps-brand-500;
      border-color: $ps-brand-500;
    }

    .fa-check {
      color: $ps-white;
      font-size: 1.4rem;
    }
  }

  .solution-instance {
    color: $ps-gray-400;
    font-size: 11px;
    font-weight: bold;
    position: relative;
    top: ps-size(-1);
  }

  .webhook-triggers {
    margin-bottom: 15px;
    > div {
      margin-bottom: 10px;
    }
  }
}
